import React from "react";
import MainLayout from "../MainLayout";

export const UserManagement = () => {
  return (
<MainLayout>
        <div className="max-w-4xl mx-auto">
          <h1 className="text-3xl font-bold text-gray-800 mb-6">
            User Management
          </h1>
          {/* Add your user management content here */}
        </div>
        </MainLayout>
  );
};
