import React from "react";
import MainLayout from "../MainLayout";

export const ApplicationReview = () => {
  return (
<MainLayout>
        <div className="max-w-4xl mx-auto">
          <h1 className="text-3xl font-bold text-gray-800 mb-6">
            Application Review
          </h1>
          {/* Add your application review content here */}
        </div>
        </MainLayout>
  );
};
