// client/src/components/GateListForm.js
import React, { useState, useEffect } from "react";
import api from "../utils/api";
import { useAuth } from "../contexts/AuthContext";
import {
  Plus,
  Trash,
  Save,
  RefreshCw,
  Download,
  Upload,
  User,
  Users,
  CheckCircle,
  AlertCircle,
  Info,
} from "lucide-react";

/**
 * Form component for managing gatelists (participant check-in lists)
 */
const GateListForm = ({ applicationId, eventId, groupId, onComplete }) => {
  const [gateList, setGateList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [newName, setNewName] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newIsMinor, setNewIsMinor] = useState(false);
  const [error, setError] = useState(null);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [saveMessage, setSaveMessage] = useState("");
  const [gateListStatus, setGateListStatus] = useState("draft"); // draft, saved, submitted
  const { user } = useAuth();

  // Fetch existing gatelist and status
  useEffect(() => {
    const fetchGateList = async () => {
      try {
        setLoading(true);
        const response = await api.get(
          `/api/applications/${applicationId}/gatelist`
        );
        setGateList(response.data?.gateList || []);

        // Set the status if it exists in the response
        if (response.data?.status) {
          setGateListStatus(response.data.status);
        }

        setError(null);
      } catch (err) {
        console.error("Error fetching gatelist:", err);
        setError("Failed to load gatelist data");
      } finally {
        setLoading(false);
      }
    };

    if (applicationId) {
      fetchGateList();
    }
  }, [applicationId]);

  // Add a new entry to the gatelist
  const handleAddPerson = () => {
    if (!newName.trim()) return;

    const newPerson = {
      tempId: Date.now(), // Temporary ID for UI handling
      name: newName.trim(),
      email: newEmail.trim() || null,
      isMinor: newIsMinor ? "Yes" : "No",
      groupID: groupId,
      eventID: eventId,
    };

    setGateList([...gateList, newPerson]);
    setNewName("");
    setNewEmail("");
    setNewIsMinor(false);

    // Reset any previous success message
    setSaveSuccess(false);
    setSaveMessage("");
  };

  // Remove a person from the gatelist
  const handleRemovePerson = (index) => {
    const updatedList = [...gateList];
    updatedList.splice(index, 1);
    setGateList(updatedList);

    // Reset any previous success message
    setSaveSuccess(false);
    setSaveMessage("");
  };

  // Save the gatelist (without marking as complete)
  const handleSaveGateList = async () => {
    try {
      setSaving(true);
      setError(null);
      setSaveSuccess(false);
      setSaveMessage("");

      // API call to save the gatelist
      await api.post(`/api/applications/${applicationId}/gatelist`, {
        gateList,
        eventId,
        groupId,
        status: "saved" // Keeping as saved rather than submitted
      });

      // Update the local status
      setGateListStatus("saved");
      setSaveSuccess(true);
      setSaveMessage("Gate list saved successfully. You can continue to make changes.");

    } catch (err) {
      console.error("Error saving gatelist:", err);
      setError(
        "Failed to save gatelist: " +
        (err.response?.data?.error || "Unknown error")
      );
      setSaveSuccess(false);
    } finally {
      setSaving(false);
    }
  };

  // Submit the gatelist (mark as complete)
  const handleSubmitGateList = async () => {
    try {
      setSubmitting(true);
      setError(null);
      setSaveSuccess(false);
      setSaveMessage("");

      // API call to save and submit the gatelist
      await api.post(`/api/applications/${applicationId}/gatelist/submit`, {
        gateList,
        eventId,
        groupId,
        status: "submitted"
      });

      // Update the local status
      setGateListStatus("submitted");
      setSaveSuccess(true);
      setSaveMessage("Gate list has been submitted successfully!");

      // Notify parent component that submission is complete
      if (onComplete) {
        onComplete();
      }

    } catch (err) {
      console.error("Error submitting gatelist:", err);
      setError(
        "Failed to submit gatelist: " +
        (err.response?.data?.error || "Unknown error")
      );
      setSaveSuccess(false);
    } finally {
      setSubmitting(false);
    }
  };

  // Handle CSV upload
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const csv = event.target.result;
        const lines = csv.split("\n");
        const headers = lines[0].split(",");

        // Find column indices
        const nameIndex = headers.findIndex((h) =>
          h.toLowerCase().includes("name")
        );
        const emailIndex = headers.findIndex((h) =>
          h.toLowerCase().includes("email")
        );
        const minorIndex = headers.findIndex(
          (h) =>
            h.toLowerCase().includes("minor") ||
            h.toLowerCase().includes("age") ||
            h.toLowerCase().includes("child")
        );

        if (nameIndex === -1) {
          setError("CSV must contain a name column");
          return;
        }

        // Parse entries
        const newEntries = [];
        for (let i = 1; i < lines.length; i++) {
          if (!lines[i].trim()) continue;

          const values = lines[i].split(",");
          const name = values[nameIndex]?.trim();
          if (!name) continue;

          newEntries.push({
            tempId: Date.now() + i,
            name,
            email: emailIndex !== -1 ? values[emailIndex]?.trim() : null,
            isMinor:
              minorIndex !== -1 &&
                (values[minorIndex]?.toLowerCase().includes("y") ||
                  values[minorIndex]?.toLowerCase().includes("minor") ||
                  values[minorIndex]?.toLowerCase().includes("child"))
                ? "Yes"
                : "No",
            groupID: groupId,
            eventID: eventId,
          });
        }

        setGateList([...gateList, ...newEntries]);
        setError(null);
        setSaveSuccess(false);
        setSaveMessage("");
      } catch (e) {
        console.error("Error parsing CSV:", e);
        setError("Failed to parse CSV file");
      }
    };

    reader.readAsText(file);
  };

  // Download a CSV template
  const downloadTemplate = () => {
    const csvContent =
      "Name,Email,IsMinor\nJohn Doe,john@example.com,No\nJane Smith,jane@example.com,Yes";
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "gatelist_template.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Get the status display based on gateListStatus
  const getStatusDisplay = () => {
    switch (gateListStatus) {
      case "submitted":
        return (
          <div className="flex items-center bg-green-50 text-green-700 px-4 py-2 rounded-md">
            <CheckCircle className="w-5 h-5 mr-2" />
            <span>Gate list has been submitted</span>
          </div>
        );
      case "saved":
        return (
          <div className="flex items-center bg-blue-50 text-blue-700 px-4 py-2 rounded-md">
            <Info className="w-5 h-5 mr-2" />
            <span>Gate list saved as draft</span>
          </div>
        );
      default:
        return null;
    }
  };

  // Export current gatelist to CSV
  const exportCurrentList = () => {
    if (!gateList.length) return;

    // Create CSV content
    let csvContent = "Name,Email,IsMinor\n";
    gateList.forEach(person => {
      csvContent += `"${person.name}","${person.email || ""}","${person.isMinor}"\n`;
    });

    // Create and download the file
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `gatelist_${applicationId}_export.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="bg-white shadow rounded-lg overflow-hidden">
      <div className="px-6 py-4 border-b border-gray-200">
        <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-2">
          <div>
            <h2 className="text-lg font-medium text-gray-900">
              Gate List Submission
            </h2>
            <p className="mt-1 text-sm text-gray-500">
              Add all participants from your group who need access to the event
            </p>
          </div>
          {getStatusDisplay()}
        </div>
      </div>

      <div className="px-6 py-4">
        {error && (
          <div className="mb-4 bg-red-50 border-l-4 border-red-400 p-3 text-sm text-red-700">
            {error}
          </div>
        )}

        {saveSuccess && (
          <div className="mb-4 bg-green-50 border-l-4 border-green-400 p-3 text-sm text-green-700">
            {saveMessage}
          </div>
        )}

        {/* Add person form - disabled if already submitted */}
        <div className="mb-6 bg-gray-50 rounded-lg border border-gray-100 shadow-sm">
          <div className="p-4">
            <h3 className="text-sm font-medium text-gray-700 mb-3 flex items-center">
              <User className="w-4 h-4 mr-2 text-blue-500" />
              Add Person
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div>
                <label
                  htmlFor="newName"
                  className="block text-xs font-medium text-gray-500 mb-1"
                >
                  Name*
                </label>
                <input
                  type="text"
                  id="newName"
                  value={newName}
                  onChange={(e) => setNewName(e.target.value)}
                  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  placeholder="Full Name"
                  disabled={gateListStatus === "submitted"}
                />
              </div>
              <div>
                <label
                  htmlFor="newEmail"
                  className="block text-xs font-medium text-gray-500 mb-1"
                >
                  Email (Optional)
                </label>
                <input
                  type="email"
                  id="newEmail"
                  value={newEmail}
                  onChange={(e) => setNewEmail(e.target.value)}
                  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  placeholder="Email Address"
                  disabled={gateListStatus === "submitted"}
                />
              </div>
              <div className="flex items-end">
                <div className="mr-4">
                  <div className="flex items-center">
                    <input
                      id="newIsMinor"
                      name="newIsMinor"
                      type="checkbox"
                      checked={newIsMinor}
                      onChange={(e) => setNewIsMinor(e.target.checked)}
                      className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                      disabled={gateListStatus === "submitted"}
                    />
                    <label
                      htmlFor="newIsMinor"
                      className="ml-2 block text-sm text-gray-700"
                    >
                      Minor
                    </label>
                  </div>
                </div>
                <button
                  type="button"
                  onClick={handleAddPerson}
                  disabled={!newName.trim() || gateListStatus === "submitted"}
                  className={`inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white transition-colors duration-200 ${!newName.trim() || gateListStatus === "submitted"
                      ? "bg-blue-300 cursor-not-allowed"
                      : "bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    }`}
                >
                  <Plus className="w-4 h-4 mr-1" />
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* CSV import/export - disabled if already submitted */}
        <div className="mb-6 flex flex-wrap gap-3">
          <button
            type="button"
            onClick={downloadTemplate}
            className="inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
          >
            <Download className="w-4 h-4 mr-1 text-gray-500" />
            Download Template
          </button>

          {gateList.length > 0 && (
            <button
              type="button"
              onClick={exportCurrentList}
              className="inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              <Download className="w-4 h-4 mr-1 text-gray-500" />
              Export Current List
            </button>
          )}

          {gateListStatus !== "submitted" && (
            <label className="inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 cursor-pointer">
              <Upload className="w-4 h-4 mr-1 text-gray-500" />
              Import CSV
              <input
                type="file"
                accept=".csv"
                onChange={handleFileUpload}
                className="hidden"
                disabled={gateListStatus === "submitted"}
              />
            </label>
          )}
        </div>

        {/* Gatelist Table */}
        <div className="mt-4 overflow-x-auto border border-gray-200 rounded-lg shadow-sm">
          {/* Desktop view */}
          <table className="min-w-full divide-y divide-gray-200 hidden md:table">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Email
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Minor
                </th>
                {gateListStatus !== "submitted" && (
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                )}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {gateList.length === 0 ? (
                <tr>
                  <td
                    colSpan={gateListStatus === "submitted" ? "3" : "4"}
                    className="px-6 py-4 text-center text-gray-500"
                  >
                    <div className="flex flex-col items-center py-6">
                      <Users className="h-8 w-8 text-gray-300 mb-2" />
                      <p>No participants added yet</p>
                    </div>
                  </td>
                </tr>
              ) : (
                gateList.map((person, index) => (
                  <tr
                    key={person.gateID || person.tempId}
                    className="hover:bg-gray-50"
                  >
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {person.name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {person.email || "-"}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {person.isMinor}
                    </td>
                    {gateListStatus !== "submitted" && (
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <button
                          type="button"
                          onClick={() => handleRemovePerson(index)}
                          className="text-red-600 hover:text-red-900 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 rounded-full p-1"
                        >
                          <Trash className="w-4 h-4" />
                        </button>
                      </td>
                    )}
                  </tr>
                ))
              )}
            </tbody>
          </table>

          {/* Mobile view */}
          <div className="md:hidden divide-y divide-gray-200">
            {gateList.length === 0 ? (
              <div className="px-6 py-4 text-center text-gray-500">
                <div className="flex flex-col items-center py-6">
                  <Users className="h-8 w-8 text-gray-300 mb-2" />
                  <p>No participants added yet</p>
                </div>
              </div>
            ) : (
              gateList.map((person, index) => (
                <div
                  key={person.gateID || person.tempId}
                  className="px-4 py-3 hover:bg-gray-50"
                >
                  <div className="flex justify-between">
                    <div>
                      <p className="text-sm font-medium text-gray-900">
                        {person.name}
                      </p>
                      <p className="text-xs text-gray-500 mt-1">
                        {person.email ? person.email : "No email provided"}
                      </p>
                      <div className="mt-1 text-xs inline-flex items-center px-2 py-0.5 rounded-full bg-gray-100 text-gray-800">
                        {person.isMinor === "Yes" ? "Minor" : "Adult"}
                      </div>
                    </div>
                    {gateListStatus !== "submitted" && (
                      <button
                        type="button"
                        onClick={() => handleRemovePerson(index)}
                        className="text-red-600 hover:text-red-900 h-8 w-8 flex items-center justify-center rounded-full hover:bg-red-50"
                      >
                        <Trash className="w-4 h-4" />
                      </button>
                    )}
                  </div>
                </div>
              ))
            )}
          </div>
        </div>

        {/* Action buttons */}
        <div className="mt-6 flex flex-col sm:flex-row justify-end gap-3">
          {gateListStatus !== "submitted" && (
            <>
              <button
                type="button"
                onClick={handleSaveGateList}
                disabled={saving || gateList.length === 0}
                className={`inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white transition-colors duration-200 ${saving || gateList.length === 0
                    ? "opacity-60 cursor-not-allowed"
                    : "hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                  }`}
              >
                {saving ? (
                  <>
                    <RefreshCw className="animate-spin mr-2 h-4 w-4" />
                    Saving...
                  </>
                ) : (
                  <>
                    <Save className="mr-2 h-4 w-4" />
                    Save Draft
                  </>
                )}
              </button>

              <button
                type="button"
                onClick={handleSubmitGateList}
                disabled={submitting || gateList.length === 0}
                className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white transition-colors duration-200 ${submitting || gateList.length === 0
                    ? "bg-blue-300 cursor-not-allowed"
                    : "bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  }`}
              >
                {submitting ? (
                  <>
                    <RefreshCw className="animate-spin mr-2 h-4 w-4" />
                    Submitting...
                  </>
                ) : (
                  <>
                    <CheckCircle className="mr-2 h-4 w-4" />
                    Submit Final Gate List
                  </>
                )}
              </button>
            </>
          )}

          {/* If already submitted, show a button to return to application */}
          {gateListStatus === "submitted" && (
            <button
              type="button"
              onClick={onComplete}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Return to Application
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default GateListForm;