import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyD0EWaGaVT0XOxw3arJL9Gn2utm8jlc9Ko",
  authDomain: "renregister-de0e7.firebaseapp.com",
  projectId: "renregister-de0e7",
  storageBucket: "renregister-de0e7.appspot.com",
  messagingSenderId: "846815600893",
  appId: "1:846815600893:web:580f88992c11aa8f715e94",
  measurementId: "G-9J5RSDFS12",
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);

export { app, auth, analytics };
