// src/components/UpcomingEvents.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api from "../utils/api";
import { useAuth } from "../contexts/AuthContext";
import { format } from "date-fns";
import {
  Calendar,
  Eye,
  Filter,
  FileText,
  Clock,
  MapPin,
  Search,
  ChevronRight,
  Store,
  Theater,
  Castle,
} from "lucide-react";
import { useGroup } from "../contexts/GroupContext";
import {
  Card,
  CardHeader,
  CardContent,
  CardFooter,
  EventCard,
} from "./ui/Card";
import { Button } from "./ui/Button";
import { Alert } from "./ui/Alert";
import { Input } from "./ui/Input";

const UpcomingEvents = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showPastEvents, setShowPastEvents] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const { user } = useAuth();
  const { activeGroup } = useGroup();
  const navigate = useNavigate();

  // Fetch upcoming events
  useEffect(() => {
    const fetchEvents = async () => {
      setLoading(true);
      try {
        const response = await api.get("/api/events/upcoming");
        setEvents(response.data);
        setError(null);
      } catch (err) {
        console.error("Error fetching events:", err);
        setError("Failed to load events");
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, [showPastEvents]);

  const handleViewEvent = (eventId) => {
    navigate(`/events/view/${eventId}`);
  };

  const handleApplyForEvent = (eventId) => {
    if (!activeGroup) {
      alert("Please select a group before applying for an event");
      return;
    }
    navigate(`/applications/create/${eventId}`);
  };

  // Check if the current group can apply for the event
  const canApplyForEvent = (event) => {
    if (!activeGroup) return false;

    const today = new Date();
    const cutoffDate = new Date(
      activeGroup.class === "vendor" ? event.vendor_cutoff : event.app_cutoff
    );

    // Check if event is in the future and cutoff date hasn't passed
    const eventDate = new Date(event.event_startdate);

    // Handle different application types based on group class
    if (activeGroup.class === "vendor" && event.vendorAppType === "closed") {
      return false;
    }
    if (activeGroup.class === "guild" && event.appType === "closed") {
      return false;
    }
    if (activeGroup.class === "act" && event.actAppType === "closed") {
      return false;
    }

    return eventDate > today && today <= cutoffDate;
  };

  // Filter events based on search term and the showPastEvents toggle
  const filteredEvents = events.filter((event) => {
    const eventDate = new Date(event.event_startdate);
    const today = new Date();
    const dateFilter = showPastEvents ? true : eventDate >= today;

    const searchFilter =
      searchTerm === "" ||
      event.eventName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      event.eventLocation?.toLowerCase().includes(searchTerm.toLowerCase());

    return dateFilter && searchFilter;
  });

  // Format date function
  const formatDate = (dateString) => {
    return format(new Date(dateString), "MMM d, yyyy");
  };

  return (
    <div className="max-w-6xl mx-auto px-4 py-8">
      {/* Header Section */}
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 mb-6">
        <div>
          <h1 className="text-2xl sm:text-3xl font-bold text-primary-800">
            Upcoming Events
          </h1>
          {activeGroup && (
            <p className="text-gray-600 mt-1">
              Viewing events as:{" "}
              <span className="font-medium">{activeGroup.group_name}</span>
            </p>
          )}
        </div>

        <div className="flex flex-col sm:flex-row gap-3 w-full md:w-auto">
          <div className="relative">
            <Input
              placeholder="Search events..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              leadingIcon={Search}
              fullWidth={false}
              className="w-full sm:w-64"
            />
          </div>

          <Button
            variant="outline"
            onClick={() => setShowPastEvents(!showPastEvents)}
            icon={Filter}
          >
            {showPastEvents ? "Hide Past Events" : "Show All Events"}
          </Button>
        </div>
      </div>

      {/* Group Selection Alert (if no group selected) */}
      {!activeGroup && (
        <Alert
          variant="info"
          title="No Group Selected"
          message="Select a group from the sidebar to see available application options."
          className="mb-6"
        />
      )}

      {/* Event Listing */}
      {loading ? (
        <div className="flex justify-center py-12">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary-600"></div>
        </div>
      ) : error ? (
        <Alert
          variant="error"
          title="Error Loading Events"
          message={error}
          className="mb-6"
        />
      ) : filteredEvents.length === 0 ? (
        <Card>
          <CardContent>
            <div className="text-center py-12">
              <Calendar className="h-12 w-12 mx-auto text-gray-300 mb-4" />
              <h3 className="text-lg font-medium text-gray-800 mb-2">
                No Events Found
              </h3>
              <p className="text-gray-500 max-w-md mx-auto">
                {showPastEvents
                  ? "There are no events matching your search criteria."
                  : "There are no upcoming events at this time. Check back later or try showing past events."}
              </p>
            </div>
          </CardContent>
        </Card>
      ) : (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {filteredEvents.map((event) => (
            <EventCard
              key={event.eventID}
              event={event}
              onView={() => handleViewEvent(event.eventID)}
              onApply={() => handleApplyForEvent(event.eventID)}
              showApplyButton={activeGroup && canApplyForEvent(event)}
              className="hover:shadow-lg transition-all duration-200"
            />
          ))}
        </div>
      )}

      {/* Application Types Legend */}
      {!loading && !error && filteredEvents.length > 0 && (
        <div className="mt-8 bg-gray-50 p-4 rounded-lg border border-gray-200">
          <h3 className="text-sm font-medium text-gray-700 mb-2">
            Application Types:
          </h3>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
            <div className="flex items-center">
              <Store className="w-5 h-5 text-green-500 mr-2" />
              <span className="text-sm text-gray-600">Vendor Applications</span>
            </div>
            <div className="flex items-center">
              <Theater className="w-5 h-5 text-green-500 mr-2" />
              <span className="text-sm text-gray-600">
                Performer Applications
              </span>
            </div>
            <div className="flex items-center">
              <Castle className="w-5 h-5 text-green-500 mr-2" />
              <span className="text-sm text-gray-600">Guild Applications</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UpcomingEvents;
