import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { format } from "date-fns";
import MainLayout from "./MainLayout";
import api from "../utils/api";
import {
  Calendar,
  Clock,
  MapPin,
  Globe,
  Mail,
  Phone,
  User,
  FileText,
  Edit,
  ArrowLeft,
  Clipboard,
  CheckCircle,
  XCircle,
} from "lucide-react";

const EventDetails = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState("details");

  // Load event data
  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const response = await api.get(`/api/events/${eventId}`);
        setEvent(response.data);
        setError(null);
      } catch (err) {
        console.error("Error fetching event:", err);
        setError("Failed to load event data");
      } finally {
        setLoading(false);
      }
    };

    fetchEvent();
  }, [eventId]);

  // Format date function
  const formatDate = (dateString) => {
    try {
      return format(new Date(dateString), "MMMM d, yyyy h:mm a");
    } catch (e) {
      return "Invalid date";
    }
  };

  if (loading) {
    return (
      <main className="flex-1 p-4 sm:p-6 md:p-8">
        <div className="max-w-4xl mx-auto flex justify-center items-center h-full">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
        </div>
      </main>
    );
  }

  if (error) {
    return (
      <main className="flex-1 p-4 sm:p-6 md:p-8">
        <div className="max-w-6xl mx-auto">
          <div className="bg-red-50 border-l-4 border-red-500 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg
                  className="h-5 w-5 text-red-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div className="ml-3">
                <p className="text-sm text-red-700">{error}</p>
              </div>
            </div>
          </div>
          <button
            onClick={() => navigate("/my-events")}
            className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
          >
            <ArrowLeft className="w-5 h-5 mr-2" />
            Back to My Events
          </button>

          {/* Footer */}
          <footer className="mt-8 text-center text-gray-500 text-sm">
            Copyright by Renaissance Productions 2006-{new Date().getFullYear()}
          </footer>
        </div>
      </main>
    );
  }

  if (!event) {
    return (
      <main className="flex-1 p-4 sm:p-6 md:p-8">
        <div className="max-w-6xl mx-auto">
          <div className="bg-yellow-50 border-l-4 border-yellow-500 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg
                  className="h-5 w-5 text-yellow-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div className="ml-3">
                <p className="text-sm text-yellow-700">Event not found</p>
              </div>
            </div>
          </div>
          <button
            onClick={() => navigate("/my-events")}
            className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
          >
            <ArrowLeft className="w-5 h-5 mr-2" />
            Back to My Events
          </button>
          {/* Footer */}
          <footer className="mt-8 text-center text-gray-500 text-sm">
            Copyright by Renaissance Productions 2006-{new Date().getFullYear()}
          </footer>
        </div>
      </main>
    );
  }

  return (
    <main className="flex-1 p-4 sm:p-6 md:p-8">
      <div className="max-w-6xl mx-auto">
        {/* Header */}
        <div className="bg-white shadow rounded-lg mb-6">
          <div className="px-6 py-5">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <button
                  onClick={() => navigate("/my-events")}
                  className="mr-4 p-2 rounded-full hover:bg-gray-100"
                >
                  <ArrowLeft className="w-5 h-5" />
                </button>
                <div>
                  <h1 className="text-2xl font-bold text-gray-900">
                    {event.eventName}
                  </h1>
                  <p className="text-sm text-gray-500">
                    Event Code: {event.event}
                  </p>
                </div>
              </div>
              <div>
                <button
                  onClick={() => navigate(`/events/edit/${eventId}`)}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
                >
                  <Edit className="w-4 h-4 mr-2" />
                  Edit Event
                </button>
              </div>
            </div>

            {/* Status Badge */}
            <div className="mt-2">
              <span
                className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                  event.isCancelled === 1
                    ? "bg-red-100 text-red-800"
                    : "bg-green-100 text-green-800"
                }`}
              >
                {event.isCancelled === 1 ? "Cancelled" : "Active"}
              </span>

              {!event.isVisible && (
                <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                  Hidden
                </span>
              )}
            </div>
          </div>

          {/* Tabs */}
          <div className="border-t border-gray-200">
            <nav className="flex -mb-px">
              <button
                onClick={() => setActiveTab("details")}
                className={`${
                  activeTab === "details"
                    ? "border-blue-500 text-blue-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                } whitespace-nowrap py-4 px-6 border-b-2 font-medium text-sm`}
              >
                Details
              </button>
              <button
                onClick={() => setActiveTab("rules")}
                className={`${
                  activeTab === "rules"
                    ? "border-blue-500 text-blue-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                } whitespace-nowrap py-4 px-6 border-b-2 font-medium text-sm`}
              >
                Rules
              </button>
              <button
                onClick={() => setActiveTab("vendor-info")}
                className={`${
                  activeTab === "vendor-info"
                    ? "border-blue-500 text-blue-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                } whitespace-nowrap py-4 px-6 border-b-2 font-medium text-sm`}
              >
                Vendor Info
              </button>
              <button
                onClick={() => setActiveTab("applications")}
                className={`${
                  activeTab === "applications"
                    ? "border-blue-500 text-blue-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                } whitespace-nowrap py-4 px-6 border-b-2 font-medium text-sm`}
              >
                Applications
              </button>
            </nav>
          </div>
        </div>

        {/* Content based on active tab */}
        {activeTab === "details" && (
          <div className="bg-white shadow rounded-lg overflow-hidden">
            <div className="px-6 py-5 border-b border-gray-200">
              <h2 className="text-lg font-medium text-gray-900">
                Event Details
              </h2>
            </div>

            <div className="px-6 py-5">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Event Type & Location */}
                <div>
                  <h3 className="text-sm font-medium text-gray-500">
                    Event Type
                  </h3>
                  <p className="mt-1 text-sm text-gray-900">
                    {event.eType === "Other" ? event.eTypeOther : event.eType}
                  </p>
                </div>

                <div>
                  <h3 className="text-sm font-medium text-gray-500">
                    Location
                  </h3>
                  <div className="mt-1 flex items-center text-sm text-gray-900">
                    <MapPin className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" />
                    {event.eventLocation || "Not specified"}
                    {event.zip && ` - ${event.zip}`}
                  </div>
                </div>

                {/* Dates */}
                <div>
                  <h3 className="text-sm font-medium text-gray-500">
                    Event Date
                  </h3>
                  <div className="mt-1 flex items-center text-sm text-gray-900">
                    <Calendar className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" />
                    {event.event_dates || formatDate(event.event_startdate)}
                  </div>
                </div>

                <div>
                  <h3 className="text-sm font-medium text-gray-500">
                    Start Date & Time
                  </h3>
                  <div className="mt-1 flex items-center text-sm text-gray-900">
                    <Clock className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" />
                    {formatDate(event.event_startdate)}
                  </div>
                </div>

                {/* Application Dates */}
                <div>
                  <h3 className="text-sm font-medium text-gray-500">
                    General Application Cutoff
                  </h3>
                  <div className="mt-1 flex items-center text-sm text-gray-900">
                    <Calendar className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" />
                    {formatDate(event.app_cutoff)}
                  </div>
                </div>

                <div>
                  <h3 className="text-sm font-medium text-gray-500">
                    Vendor Application Cutoff
                  </h3>
                  <div className="mt-1 flex items-center text-sm text-gray-900">
                    <Calendar className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" />
                    {formatDate(event.vendor_cutoff)}
                  </div>
                </div>

                <div>
                  <h3 className="text-sm font-medium text-gray-500">
                    Gate List Cutoff
                  </h3>
                  <div className="mt-1 flex items-center text-sm text-gray-900">
                    <Calendar className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" />
                    {formatDate(event.gate_cutoff)}
                  </div>
                </div>

                {/* Online Presence */}
                <div>
                  <h3 className="text-sm font-medium text-gray-500">Website</h3>
                  <div className="mt-1 flex items-center text-sm text-gray-900">
                    <Globe className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" />
                    {event.url ? (
                      <a
                        href={event.url}
                        target="_blank"
                        rel="noreferrer"
                        className="text-blue-600 hover:underline"
                      >
                        {event.url}
                      </a>
                    ) : (
                      "Not specified"
                    )}
                  </div>
                </div>

                {event.myspace && (
                  <div>
                    <h3 className="text-sm font-medium text-gray-500">
                      Social Media
                    </h3>
                    <div className="mt-1 flex items-center text-sm text-gray-900">
                      <Globe className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" />
                      <a
                        href={event.myspace}
                        target="_blank"
                        rel="noreferrer"
                        className="text-blue-600 hover:underline"
                      >
                        {event.myspace}
                      </a>
                    </div>
                  </div>
                )}
              </div>

              {/* Contact Information */}
              <div className="mt-8">
                <h3 className="text-base font-medium text-gray-900">
                  Contact Information
                </h3>
                <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <h4 className="text-sm font-medium text-gray-500">
                      Contact Name
                    </h4>
                    <div className="mt-1 flex items-center text-sm text-gray-900">
                      <User className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" />
                      {event.pri_contact || "Not specified"}
                    </div>
                  </div>

                  <div>
                    <h4 className="text-sm font-medium text-gray-500">Email</h4>
                    <div className="mt-1 flex items-center text-sm text-gray-900">
                      <Mail className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" />
                      {event.pri_email ? (
                        <a
                          href={`mailto:${event.pri_email}`}
                          className="text-blue-600 hover:underline"
                        >
                          {event.pri_email}
                        </a>
                      ) : (
                        "Not specified"
                      )}
                    </div>
                  </div>

                  {event.pri_phone && (
                    <div>
                      <h4 className="text-sm font-medium text-gray-500">
                        Phone
                      </h4>
                      <div className="mt-1 flex items-center text-sm text-gray-900">
                        <Phone className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" />
                        <a
                          href={`tel:${event.pri_phone.replace(/\D/g, "")}`}
                          className="text-blue-600 hover:underline"
                        >
                          {event.pri_phone}
                        </a>
                      </div>
                    </div>
                  )}

                  {event.pri_fax && (
                    <div>
                      <h4 className="text-sm font-medium text-gray-500">Fax</h4>
                      <div className="mt-1 flex items-center text-sm text-gray-900">
                        <Phone className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" />
                        {event.pri_fax}
                      </div>
                    </div>
                  )}
                </div>

                {event.pri_address && (
                  <div className="mt-4">
                    <h4 className="text-sm font-medium text-gray-500">
                      Address
                    </h4>
                    <div className="mt-1 text-sm text-gray-900">
                      <pre className="whitespace-pre-wrap font-sans">
                        {event.pri_address}
                      </pre>
                    </div>
                  </div>
                )}

                {event.CheckAddress && (
                  <div className="mt-4">
                    <h4 className="text-sm font-medium text-gray-500">
                      Check Payment Address
                    </h4>
                    <div className="mt-1 text-sm text-gray-900">
                      <pre className="whitespace-pre-wrap font-sans">
                        {event.CheckAddress}
                      </pre>
                    </div>
                  </div>
                )}
              </div>

              {/* Payment Settings */}
              <div className="mt-8">
                <h3 className="text-base font-medium text-gray-900">
                  Payment Settings
                </h3>
                <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <h4 className="text-sm font-medium text-gray-500">
                      PayPal Email
                    </h4>
                    <div className="mt-1 text-sm text-gray-900">
                      {event.paypal_email ? (
                        <a
                          href={`mailto:${event.paypal_email}`}
                          className="text-blue-600 hover:underline"
                        >
                          {event.paypal_email}
                        </a>
                      ) : (
                        "Not specified"
                      )}
                    </div>
                  </div>

                  <div>
                    <h4 className="text-sm font-medium text-gray-500">
                      Allow PayPal
                    </h4>
                    <div className="mt-1 flex items-center text-sm text-gray-900">
                      {event.allowPaypal ? (
                        <CheckCircle className="h-5 w-5 text-green-500" />
                      ) : (
                        <XCircle className="h-5 w-5 text-red-500" />
                      )}
                      <span className="ml-2">
                        {event.allowPaypal ? "Yes" : "No"}
                      </span>
                    </div>
                  </div>

                  <div>
                    <h4 className="text-sm font-medium text-gray-500">
                      Allow Check Payments
                    </h4>
                    <div className="mt-1 flex items-center text-sm text-gray-900">
                      {event.allowCheck ? (
                        <CheckCircle className="h-5 w-5 text-green-500" />
                      ) : (
                        <XCircle className="h-5 w-5 text-red-500" />
                      )}
                      <span className="ml-2">
                        {event.allowCheck ? "Yes" : "No"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Description */}
              {event.notes && (
                <div className="mt-8">
                  <h3 className="text-base font-medium text-gray-900">
                    Event Description
                  </h3>
                  <div className="mt-2 prose prose-blue max-w-none">
                    <div dangerouslySetInnerHTML={{ __html: event.notes }} />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {activeTab === "rules" && (
          <div className="bg-white shadow rounded-lg overflow-hidden">
            <div className="px-6 py-5 border-b border-gray-200">
              <h2 className="text-lg font-medium text-gray-900">
                Rules and Guidelines
              </h2>
            </div>

            <div className="px-6 py-5">
              {event.rules ? (
                <div className="prose prose-blue max-w-none">
                  <div dangerouslySetInnerHTML={{ __html: event.rules }} />
                </div>
              ) : (
                <p className="text-gray-500 italic">
                  No rules or guidelines specified.
                </p>
              )}
            </div>
          </div>
        )}

        {activeTab === "vendor-info" && (
          <div className="bg-white shadow rounded-lg overflow-hidden">
            <div className="px-6 py-5 border-b border-gray-200">
              <h2 className="text-lg font-medium text-gray-900">
                Vendor Information
              </h2>
            </div>

            <div className="px-6 py-5">
              {event.v_notes ? (
                <div className="prose prose-blue max-w-none">
                  <div dangerouslySetInnerHTML={{ __html: event.v_notes }} />
                </div>
              ) : (
                <p className="text-gray-500 italic">
                  No vendor information specified.
                </p>
              )}
            </div>
          </div>
        )}

        {activeTab === "applications" && (
          <div className="bg-white shadow rounded-lg overflow-hidden">
            <div className="px-6 py-5 border-b border-gray-200">
              <h2 className="text-lg font-medium text-gray-900">
                Application Settings
              </h2>
            </div>

            <div className="px-6 py-5">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div>
                  <h3 className="text-sm font-medium text-gray-500">
                    Guild Applications
                  </h3>
                  <p className="mt-1 text-sm text-gray-900">
                    {event.appType || "Not specified"}
                  </p>
                </div>

                <div>
                  <h3 className="text-sm font-medium text-gray-500">
                    Vendor Applications
                  </h3>
                  <p className="mt-1 text-sm text-gray-900">
                    {event.vendorAppType || "Not specified"}
                  </p>
                </div>

                <div>
                  <h3 className="text-sm font-medium text-gray-500">
                    Act Applications
                  </h3>
                  <p className="mt-1 text-sm text-gray-900">
                    {event.actAppType || "Not specified"}
                  </p>
                </div>

                <div>
                  <h3 className="text-sm font-medium text-gray-500">
                    Gate Max
                  </h3>
                  <p className="mt-1 text-sm text-gray-900">
                    {event.gateMax || "Not specified"}
                  </p>
                </div>

                <div>
                  <h3 className="text-sm font-medium text-gray-500">
                    Include Volunteers
                  </h3>
                  <div className="mt-1 flex items-center text-sm text-gray-900">
                    {event.includeVolunteers ? (
                      <CheckCircle className="h-5 w-5 text-green-500" />
                    ) : (
                      <XCircle className="h-5 w-5 text-red-500" />
                    )}
                    <span className="ml-2">
                      {event.includeVolunteers ? "Yes" : "No"}
                    </span>
                  </div>
                </div>

                <div>
                  <h3 className="text-sm font-medium text-gray-500">
                    Include Independents
                  </h3>
                  <div className="mt-1 flex items-center text-sm text-gray-900">
                    {event.includeIndependants ? (
                      <CheckCircle className="h-5 w-5 text-green-500" />
                    ) : (
                      <XCircle className="h-5 w-5 text-red-500" />
                    )}
                    <span className="ml-2">
                      {event.includeIndependants ? "Yes" : "No"}
                    </span>
                  </div>
                </div>
              </div>

              {event.gatelistdisclaim && (
                <div className="mt-6">
                  <h3 className="text-sm font-medium text-gray-500">
                    Gate List Disclaimer
                  </h3>
                  <div className="mt-2 p-4 bg-gray-50 rounded-md">
                    <pre className="whitespace-pre-wrap font-sans text-sm text-gray-700">
                      {event.gatelistdisclaim}
                    </pre>
                  </div>
                </div>
              )}

              <div className="mt-8">
                <div className="flex items-center justify-between">
                  <h3 className="text-base font-medium text-gray-900">
                    Applications
                  </h3>
                  <button
                    onClick={() => navigate(`/events/${eventId}/applications`)}
                    className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
                  >
                    <Clipboard className="w-4 h-4 mr-1" />
                    Manage Applications
                  </button>
                </div>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    View and manage applications for this event in the
                    Applications section.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* Footer */}
        <footer className="mt-8 text-center text-gray-500 text-sm">
          Copyright by Renaissance Productions 2006-{new Date().getFullYear()}
        </footer>
      </div>
    </main>
  );
};

export default EventDetails;
