// src/components/RedirectHandler.js
import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useGroup } from "../contexts/GroupContext";

/**
 * Component to handle redirects based on the GroupContext
 * Place this inside your Router but outside your Routes
 */
const RedirectHandler = () => {
  const { redirectNeeded, loading } = useGroup();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Only redirect when not loading and redirectNeeded is set
    if (!loading && redirectNeeded && location.pathname !== redirectNeeded) {
      console.log(
        `RedirectHandler: Redirecting to ${redirectNeeded} from ${location.pathname}`
      );

      // Use a small timeout to ensure other state updates have completed
      setTimeout(() => {
        navigate(redirectNeeded, { replace: true });
      }, 100);
    }
  }, [redirectNeeded, loading, navigate, location.pathname]);

  // This component doesn't render anything
  return null;
};

export default RedirectHandler;