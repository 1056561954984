import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useGroup } from "../../contexts/GroupContext";
import MainLayout from "../MainLayout";
import api from "../../utils/api";
import { useAuth } from "../../contexts/AuthContext";
import { Save, ArrowLeft, Calendar } from "lucide-react";
import { format } from "date-fns";

const EventForm = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const location = useLocation();
  const { activeGroup } = useGroup();

  // Check if we're in duplicate mode based on the URL
  const isDuplicating = location.pathname.includes("/duplicate/");
  const isEditing = !isDuplicating && !!eventId;

  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [userGroups, setUserGroups] = useState([]);

  // Form state
  const [formData, setFormData] = useState({
    event: "",
    eventName: "",
    pri_email: "",
    app_cutoff: formatDateForInput(addDays(new Date(), 30)),
    gate_cutoff: formatDateForInput(addDays(new Date(), 7)),
    event_startdate: formatDateForInput(addDays(new Date(), 60)),
    event_dates: "",
    url: "",
    notes: "",
    rules: "",
    appType: "Open",
    vendorAppType: "Open",
    actAppType: "Open",
    vendor_cutoff: formatDateForInput(addDays(new Date(), 30)),
    pri_contact: "",
    pri_address: "",
    pri_phone: "",
    pri_fax: "",
    eType: "Renaissance Faire",
    eTypeOther: "",
    zip: "",
    isCancelled: false,
    needsContract: "No",
    ContractFile: "",
    ContractURL: "",
    gateMax: 40,
    isVisible: true,
    groupid: "",
    bNeedsContract: false,
    hide: false,
    v_notes: "",
    paypal_email: "",
    yahoogroup: "",
    myspace: "",
    code: "",
    gatelistdisclaim: "",
    eventLocation: "",
    CheckAddress: "",
    allowPaypal: true,
    allowCheck: false,
    includeVolunteers: true,
    includeIndependants: true,
  });

  // Helper function to add days to a date
  function addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  // Format date for input fields
  function formatDateForInput(date) {
    return format(date, "yyyy-MM-dd HH:mm:ss");
  }

  useEffect(() => {
    if ((isEditing || isDuplicating) && eventId) {
      const fetchEvent = async () => {
        setLoading(true);
        try {
          const response = await api.get(`/api/events/${eventId}`);

          // Format the data from the API
          let formattedData = {
            ...response.data,
            app_cutoff: response.data.app_cutoff
              ? response.data.app_cutoff.slice(0, 19).replace("T", " ")
              : "",
            gate_cutoff: response.data.gate_cutoff
              ? response.data.gate_cutoff.slice(0, 19).replace("T", " ")
              : "",
            event_startdate: response.data.event_startdate
              ? response.data.event_startdate.slice(0, 19).replace("T", " ")
              : "",
            vendor_cutoff: response.data.vendor_cutoff
              ? response.data.vendor_cutoff.slice(0, 19).replace("T", " ")
              : "",
          };

          // If duplicating, modify some fields to indicate it's a new event
          if (isDuplicating) {
            // Remove the eventID so a new one is created
            delete formattedData.eventID;

            // Use the currently selected group's ID instead of the original
            if (activeGroup) {
              formattedData.groupid = activeGroup.groupID;
            }

            // Modify the event code and name to indicate it's a copy
            formattedData.event = `${formattedData.event}-COPY`;
            formattedData.eventName = `${formattedData.eventName} (Copy)`;

            // Adjust dates for the copied event - move forward by one year
            const moveForwardOneYear = (dateString) => {
              if (!dateString) return "";
              const date = new Date(dateString);
              date.setFullYear(date.getFullYear() + 1);
              return format(date, "yyyy-MM-dd HH:mm:ss");
            };

            formattedData.event_startdate = moveForwardOneYear(
              response.data.event_startdate
            );
            formattedData.app_cutoff = moveForwardOneYear(
              response.data.app_cutoff
            );
            formattedData.gate_cutoff = moveForwardOneYear(
              response.data.gate_cutoff
            );
            formattedData.vendor_cutoff = moveForwardOneYear(
              response.data.vendor_cutoff
            );
          }

          setFormData(formattedData);
          setError(null);
        } catch (err) {
          console.error("Error fetching event:", err);
          setError("Failed to load event data");
        } finally {
          setLoading(false);
        }
      };

      fetchEvent();
    } else if (!isEditing && !eventId && activeGroup) {
      // For brand new events, set the active group ID
      setFormData((prev) => ({
        ...prev,
        groupid: activeGroup.groupID,
      }));
    }
  }, [isEditing, isDuplicating, eventId, activeGroup]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    setError(null);

    try {
      // Ensure the current active group is used for duplicated events
      const dataToSubmit = {
        ...formData,
        // Convert boolean values to 0/1 integers for MySQL BIT fields
        isCancelled: formData.isCancelled ? 1 : 0,
        isVisible: formData.isVisible ? 1 : 0,
        bNeedsContract: formData.bNeedsContract ? 1 : 0,
        hide: formData.hide ? 1 : 0,
        allowPaypal: formData.allowPaypal ? 1 : 0,
        allowCheck: formData.allowCheck ? 1 : 0,
        includeVolunteers: formData.includeVolunteers ? 1 : 0,
        includeIndependants: formData.includeIndependants ? 1 : 0,
        groupid: isDuplicating ? activeGroup?.groupID : formData.groupid,
      };

      if (isEditing && !isDuplicating) {
        await api.put(`/api/events/${eventId}`, dataToSubmit);
      } else {
        // Both new and duplicated events use POST
        await api.post("/api/events", dataToSubmit);
      }
      navigate("/my-events");
    } catch (err) {
      console.error("Error saving event:", err);
      setError(err.response?.data?.error || "Failed to save event");
      window.scrollTo(0, 0);
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return (
      <MainLayout>
          <div className="max-w-4xl mx-auto flex justify-center items-center h-full">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
          </div>
          </MainLayout>
    );
  }

  return (
    <main className="flex-1 p-4 sm:p-6 md:p-8">
      <div className="max-w-6xl mx-auto">
        <div className="flex justify-between items-center mb-6">
          <div className="flex items-center">
            <button
              onClick={() => navigate("/my-events")}
              className="mr-4 p-2 rounded-full hover:bg-gray-200"
            >
              <ArrowLeft className="w-5 h-5" />
            </button>
            <h1 className="text-2xl font-bold text-gray-900">
              {isEditing
                ? "Edit Event"
                : isDuplicating
                ? "Duplicate Event"
                : "Create New Event"}
            </h1>
          </div>
        </div>

        {error && (
          <div className="mb-6 bg-red-50 border-l-4 border-red-500 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg
                  className="h-5 w-5 text-red-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div className="ml-3">
                <p className="text-sm text-red-700">{error}</p>
              </div>
            </div>
          </div>
        )}

        <form
          onSubmit={handleSubmit}
          className="bg-white shadow-sm rounded-lg p-6 space-y-6"
        >
          {/* Basic Info Section */}
          {(isEditing || isDuplicating || formData.groupid) && (
            <div className="mb-4 p-3 bg-blue-50 border-l-4 border-blue-500 text-blue-700">
              {isDuplicating ? (
                <p>
                  Duplicating event for group:{" "}
                  <strong>{activeGroup?.group_name || "Unknown"}</strong>
                </p>
              ) : isEditing ? (
                <p>
                  Editing event for group:{" "}
                  <strong>{formData.group_name || "Unknown"}</strong>
                </p>
              ) : (
                <p>
                  Creating new event for group:{" "}
                  <strong>{activeGroup?.group_name || "Unknown"}</strong>
                </p>
              )}
            </div>
          )}
          <div>
            <h2 className="text-lg font-medium text-gray-900 mb-4">
              Basic Information
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Event Code
                </label>
                <input
                  type="text"
                  name="event"
                  value={formData.event}
                  onChange={handleInputChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  placeholder="FOLS2023"
                  required
                />
                <p className="mt-1 text-sm text-gray-500">
                  Short code for the event (e.g., FOLS2023)
                </p>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Event Name
                </label>
                <input
                  type="text"
                  name="eventName"
                  value={formData.eventName}
                  onChange={handleInputChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  placeholder="Folsom Renaissance Faire 2023"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Event Type
                </label>
                <select
                  name="eType"
                  value={formData.eType}
                  onChange={handleInputChange}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                >
                  <option value="Renaissance Faire">Renaissance Faire</option>
                  <option value="Celtic Festival">Celtic Festival</option>
                  <option value="Pirate Festival">Pirate Festival</option>
                  <option value="Medieval Fair">Medieval Fair</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              {formData.eType === "Other" && (
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Other Event Type
                  </label>
                  <input
                    type="text"
                    name="eTypeOther"
                    value={formData.eTypeOther}
                    onChange={handleInputChange}
                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    placeholder="Specify event type"
                  />
                </div>
              )}

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Event Dates (Text)
                </label>
                <input
                  type="text"
                  name="event_dates"
                  value={formData.event_dates}
                  onChange={handleInputChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  placeholder="e.g., September 23 & 24 2023"
                />
                <p className="mt-1 text-sm text-gray-500">
                  How dates should appear in promotions
                </p>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Event Location
                </label>
                <input
                  type="text"
                  name="eventLocation"
                  value={formData.eventLocation}
                  onChange={handleInputChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  placeholder="e.g., Folsom Lion's Park, Folsom CA"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  ZIP Code
                </label>
                <input
                  type="text"
                  name="zip"
                  value={formData.zip}
                  onChange={handleInputChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  placeholder="e.g., 95630"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Website URL
                </label>
                <input
                  type="url"
                  name="url"
                  value={formData.url}
                  onChange={handleInputChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  placeholder="http://www.example.com"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  PayPal Email
                </label>
                <input
                  type="email"
                  name="paypal_email"
                  value={formData.paypal_email}
                  onChange={handleInputChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  placeholder="finance@example.com"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Social Media URL
                </label>
                <input
                  type="url"
                  name="myspace"
                  value={formData.myspace}
                  onChange={handleInputChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  placeholder="http://www.facebook.com/yourevent"
                />
                <p className="mt-1 text-sm text-gray-500">
                  Facebook, Twitter, or other social media URL
                </p>
              </div>
            </div>
          </div>

          {/* Dates Section */}
          <div>
            <h2 className="text-lg font-medium text-gray-900 mb-4">
              Event Dates
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Event Start Date
                </label>
                <input
                  type="datetime-local"
                  name="event_startdate"
                  value={formData.event_startdate.slice(0, 16)}
                  onChange={handleInputChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Application Cutoff Date
                </label>
                <input
                  type="datetime-local"
                  name="app_cutoff"
                  value={formData.app_cutoff.slice(0, 16)}
                  onChange={handleInputChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  required
                />
                <p className="mt-1 text-sm text-gray-500">
                  When general applications close
                </p>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Vendor Application Cutoff Date
                </label>
                <input
                  type="datetime-local"
                  name="vendor_cutoff"
                  value={formData.vendor_cutoff.slice(0, 16)}
                  onChange={handleInputChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  required
                />
                <p className="mt-1 text-sm text-gray-500">
                  When vendor applications close
                </p>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Gate List Cutoff Date
                </label>
                <input
                  type="datetime-local"
                  name="gate_cutoff"
                  value={formData.gate_cutoff.slice(0, 16)}
                  onChange={handleInputChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  required
                />
                <p className="mt-1 text-sm text-gray-500">
                  When gate lists close
                </p>
              </div>
            </div>
          </div>

          {/* Application Settings */}
          <div>
            <h2 className="text-lg font-medium text-gray-900 mb-4">
              Application Settings
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Guild Application Type
                </label>
                <select
                  name="appType"
                  value={formData.appType}
                  onChange={handleInputChange}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                >
                  <option value="Open">Open</option>
                  <option value="Closed">Closed</option>
                  <option value="Invitation">Invitation Only</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Vendor Application Type
                </label>
                <select
                  name="vendorAppType"
                  value={formData.vendorAppType}
                  onChange={handleInputChange}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                >
                  <option value="Open">Open</option>
                  <option value="Closed">Closed</option>
                  <option value="Invitation">Invitation Only</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Act Application Type
                </label>
                <select
                  name="actAppType"
                  value={formData.actAppType}
                  onChange={handleInputChange}
                  className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                >
                  <option value="Open">Open</option>
                  <option value="Closed">Closed</option>
                  <option value="Invitation">Invitation Only</option>
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1"></label>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Gate Max
                </label>
                <input
                  type="number"
                  name="gateMax"
                  value={formData.gateMax}
                  onChange={handleInputChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  min="0"
                />
                <p className="mt-1 text-sm text-gray-500">
                  Maximum number of people per group on gate list
                </p>
              </div>
            </div>

            <div className="mt-4 grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  name="allowPaypal"
                  checked={formData.allowPaypal}
                  onChange={handleInputChange}
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                />
                <label className="ml-2 block text-sm text-gray-700">
                  Allow PayPal payments
                </label>
              </div>

              <div className="flex items-center">
                <input
                  type="checkbox"
                  name="allowCheck"
                  checked={formData.allowCheck}
                  onChange={handleInputChange}
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                />
                <label className="ml-2 block text-sm text-gray-700">
                  Allow check payments
                </label>
              </div>

              <div className="flex items-center">
                <input
                  type="checkbox"
                  name="includeVolunteers"
                  checked={formData.includeVolunteers}
                  onChange={handleInputChange}
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                />
                <label className="ml-2 block text-sm text-gray-700">
                  Include volunteers
                </label>
              </div>

              <div className="flex items-center">
                <input
                  type="checkbox"
                  name="includeIndependants"
                  checked={formData.includeIndependants}
                  onChange={handleInputChange}
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                />
                <label className="ml-2 block text-sm text-gray-700">
                  Include independents
                </label>
              </div>

              <div className="flex items-center">
                <input
                  type="checkbox"
                  name="isVisible"
                  checked={formData.isVisible}
                  onChange={handleInputChange}
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                />
                <label className="ml-2 block text-sm text-gray-700">
                  Visible to public
                </label>
              </div>

              <div className="flex items-center">
                <input
                  type="checkbox"
                  name="isCancelled"
                  checked={formData.isCancelled}
                  onChange={handleInputChange}
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                />
                <label className="ml-2 block text-sm text-gray-700">
                  Is Cancelled
                </label>
              </div>
            </div>
          </div>

          {/* Contact Information Section */}
          <div>
            <h2 className="text-lg font-medium text-gray-900 mb-4">
              Contact Information
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Primary Contact Name
                </label>
                <input
                  type="text"
                  name="pri_contact"
                  value={formData.pri_contact}
                  onChange={handleInputChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  placeholder="John Doe"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Primary Contact Email
                </label>
                <input
                  type="email"
                  name="pri_email"
                  value={formData.pri_email}
                  onChange={handleInputChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  placeholder="johndoe@example.com"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Primary Contact Phone
                </label>
                <input
                  type="tel"
                  name="pri_phone"
                  value={formData.pri_phone}
                  onChange={handleInputChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  placeholder="555-555-5555"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Primary Contact Address
                </label>
                <input
                  type="text"
                  name="pri_address"
                  value={formData.pri_address}
                  onChange={handleInputChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  placeholder="123 Main St"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Primary Contact Fax
                </label>
                <input
                  type="tel"
                  name="pri_fax"
                  value={formData.pri_fax}
                  onChange={handleInputChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  placeholder="555-555-5555"
                />
              </div>
            </div>
          </div>

          {/* Descriptions and Rules */}
          <div>
            <h2 className="text-lg font-medium text-gray-900 mb-4">
              Descriptions and Rules
            </h2>
            <div className="grid grid-cols-1 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Event Notes
                </label>
                <textarea
                  name="notes"
                  value={formData.notes}
                  onChange={handleInputChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  rows="4"
                  placeholder="Any special notes about the event"
                ></textarea>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Event Rules
                </label>
                <textarea
                  name="rules"
                  value={formData.rules}
                  onChange={handleInputChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  rows="4"
                  placeholder="Any special rules for the event"
                ></textarea>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Vendor Notes
                </label>
                <textarea
                  name="v_notes"
                  value={formData.v_notes}
                  onChange={handleInputChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  rows="4"
                  placeholder="Any vendor special notes"
                ></textarea>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Gate List Disclaimer
                </label>
                <textarea
                  name="gatelistdisclaim"
                  value={formData.gatelistdisclaim}
                  onChange={handleInputChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  rows="4"
                  placeholder="Disclaimer for Gate Lists"
                ></textarea>
              </div>
            </div>
          </div>

          {/* Form Buttons */}
          <div className="pt-6 flex justify-end">
            <button
              type="button"
              onClick={() => navigate("/my-events")}
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={submitting}
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-gray-400"
            >
              <Save className="w-4 h-4 mr-2" />
              {submitting ? "Saving..." : "Save"}
            </button>
          </div>
        </form>

        {/* Footer */}
        <footer className="mt-8 text-center text-gray-500 text-sm">
          Copyright by Renaissance Productions 2006-{new Date().getFullYear()}
        </footer>
      </div>
    </main>
  );
};

export default EventForm;
