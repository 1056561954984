import React, { useState } from "react";
import { sendEmailVerification } from "firebase/auth";
import { auth } from "../config/firebase";
import { useAuth } from "../contexts/AuthContext";

const EmailVerificationStatus = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  // Only show for email/password users, not OAuth users
  const isEmailPasswordUser = user?.providerData?.some(
    (provider) => provider.providerId === "password"
  );

  // Don't show anything if not an email/password user or if already verified
  if (!isEmailPasswordUser || user?.emailVerified) {
    return null;
  }

  const handleResendVerification = async () => {
    setLoading(true);
    setMessage("");
    setError("");

    try {
      await sendEmailVerification(auth.currentUser);
      setMessage("Verification email sent! Please check your inbox.");
    } catch (err) {
      console.error("Error sending verification email:", err);
      setError("Failed to send verification email. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <svg
            className="h-5 w-5 text-yellow-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div className="ml-3">
          <p className="text-sm text-yellow-700">
            Your email address is not verified. Please check your inbox for a
            verification link.
          </p>
          {message && (
            <div className="mt-2 text-sm text-green-600">{message}</div>
          )}
          {error && <div className="mt-2 text-sm text-red-600">{error}</div>}
          <div className="mt-4">
            <button
              type="button"
              onClick={handleResendVerification}
              disabled={loading}
              className={`text-sm font-medium ${
                loading
                  ? "text-yellow-400 cursor-not-allowed"
                  : "text-yellow-700 hover:text-yellow-600"
              }`}
            >
              {loading ? "Sending..." : "Resend verification email"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerificationStatus;
