// src/components/ProtectedRoute.js
import { useAuth } from "../contexts/AuthContext";
import React from "react";
import { Navigate } from "react-router-dom";
import { useGroup } from "../contexts/GroupContext";

const ProtectedRoute = ({ children, requireEmailVerification = true }) => {
  const { user, loading: authLoading } = useAuth();
  const { loading: groupLoading } = useGroup();

  // Show loading state while authentication is checking
  if (authLoading || groupLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  // Redirect to login if user is not authenticated
  if (!user) {
    return <Navigate to="/login" />;
  }

  // Check if email verification is required for this route
  // Only apply to email/password users, not OAuth users
  const isEmailPasswordUser = user?.providerData?.some(
    (provider) => provider.providerId === "password"
  );

  if (requireEmailVerification && isEmailPasswordUser && !user.emailVerified) {
    // Redirect to the verification page
    return <Navigate to="/verify-email" />;
  }

  // We don't check for profile or groups here anymore - that's handled by GroupContext
  return children;
};

export default ProtectedRoute;
