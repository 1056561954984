// client/src/components/ProfileSetup.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import {
  createUserProfile,
  fetchUserGroups,
  checkUserProfile,
} from "../utils/api";
import { AlertTriangle } from "lucide-react";
import MainLayout from "./MainLayout";

const ProfileSetup = () => {
  const { user } = useAuth();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [checkingProfile, setCheckingProfile] = useState(true);
  const navigate = useNavigate();

  // Check if user already has a profile
  useEffect(() => {
    const checkExistingProfile = async () => {
      if (user) {
        try {
          const existingProfile = await checkUserProfile();
          if (existingProfile) {
            // User already has a profile, redirect to profile settings
            navigate("/my-profile");
            return;
          }
        } catch (error) {
          console.error("Error checking profile:", error);
          // If there's an error that's not a 404, we'll still show the form
        }
      }
      setCheckingProfile(false);
    };

    checkExistingProfile();
  }, [user, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    // Basic validation
    if (!firstName.trim() || !lastName.trim()) {
      setError("First and last name are required");
      setLoading(false);
      return;
    }

    if (!phone.trim()) {
      setError("Phone number is required");
      setLoading(false);
      return;
    }

    try {
      const profileData = {
        firstName,
        lastName,
        phone,
        email: user.email,
        firebaseUserId: user.uid,
      };

      // Create the user profile
      await createUserProfile(profileData);

      // Now that the profile is created, check if the user has any groups
      try {
        const groups = await fetchUserGroups();

        if (groups.length === 0) {
          // No groups - redirect to My Groups page to create first group
          navigate("/my-groups", { state: { showCreateGroup: true } });
        } else if (groups.length === 1) {
          // Exactly one group - auto-select it and go to dashboard
          localStorage.setItem("activeGroupId", groups[0].groupID.toString());
          navigate("/dashboard");
        } else {
          // Multiple groups - go to My Groups page to select one
          navigate("/my-groups");
        }
      } catch (groupErr) {
        console.error("Error checking groups:", groupErr);
        // Default to My Groups page if we can't determine groups
        navigate("/my-groups");
      }
    } catch (err) {
      console.error("Profile creation failed:", err);
      setError(err.response?.data?.error || "Failed to create profile");
      setLoading(false);
    }
  };

  // Show loading spinner while checking if user already has a profile
  if (checkingProfile) {
    return (
      <main className="flex-1 p-8">
        <div className="flex justify-center items-center h-full">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
        </div>

        {/* Footer */}
        <footer className="mt-8 text-center text-gray-500 text-sm">
          Copyright by Renaissance Productions 2006-{new Date().getFullYear()}
        </footer>
      </main>
    );
  }

  return (
    <main className="flex-1 p-4 sm:p-6 md:p-8">
      <div className="max-w-6xl mx-auto">
        <h1 className="text-2xl font-bold text-gray-900 mb-6">
          Complete Your Profile
        </h1>

        {error && (
          <div className="mb-6 bg-red-50 border-l-4 border-red-400 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <AlertTriangle className="h-5 w-5 text-red-400" />
              </div>
              <div className="ml-3">
                <p className="text-sm text-red-700">{error}</p>
              </div>
            </div>
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label
                htmlFor="firstName"
                className="block text-sm font-medium text-gray-700"
              >
                First Name
              </label>
              <input
                id="firstName"
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                required
              />
            </div>

            <div>
              <label
                htmlFor="lastName"
                className="block text-sm font-medium text-gray-700"
              >
                Last Name
              </label>
              <input
                id="lastName"
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                required
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="phone"
              className="block text-sm font-medium text-gray-700"
            >
              Phone Number
            </label>
            <input
              id="phone"
              type="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              required
            />
          </div>

          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <input
              id="email"
              type="email"
              value={user?.email || ""}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 bg-gray-50"
              disabled
            />
          </div>

          <div className="pt-4">
            <button
              type="submit"
              disabled={loading}
              className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
                loading
                  ? "bg-blue-300 cursor-not-allowed"
                  : "bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              }`}
            >
              {loading ? "Creating Profile..." : "Create Profile"}
            </button>
          </div>
        </form>
      </div>

      {/* Footer */}
      <footer className="mt-8 text-center text-gray-500 text-sm">
        Copyright by Renaissance Productions 2006-{new Date().getFullYear()}
      </footer>
    </main>
  );
};

export default ProfileSetup;
