import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { format } from "date-fns";
import MainLayout from "../components/MainLayout";
import api from "../utils/api";
import { useGroup } from "../contexts/GroupContext";
import {
  Calendar,
  CheckCircle,
  ChevronRight,
  ArrowLeft,
  FileText,
  AlertTriangle,
  Loader,
  Save,
  Send,
  XCircle,
  DollarSign,
  CreditCard,
} from "lucide-react";

const ApplicationForm = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const { activeGroup } = useGroup();
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    eventId: eventId,
    groupId: activeGroup?.groupId,
    spaceNeeded: false,
    frontage: "",
    depth: "",
    paymentMethod: "paypal",
    needsWater: false,
    needsPower: false,
    specialRequests: "",
    agreements: {
      rulesAgreement: false,
      liabilityAgreement: false,
    },
    answers: {},
  });

  // Load event data and application questions
  useEffect(() => {
    const fetchEventData = async () => {
      setLoading(true);
      try {
        const [eventResponse, questionsResponse] = await Promise.all([
          api.get(`/api/events/${eventId}`),
          api.get(`/api/events/${eventId}/questions`),
        ]);

        setEvent(eventResponse.data);
        setQuestions(questionsResponse.data || []);

        // Initialize answers object
        if (questionsResponse.data && questionsResponse.data.length > 0) {
          const initialAnswers = {};
          questionsResponse.data.forEach((q) => {
            initialAnswers[q.kickerId] = "";
          });
          setFormData((prev) => ({
            ...prev,
            answers: initialAnswers,
          }));
        }

        setError(null);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to load event data or application questions");
      } finally {
        setLoading(false);
      }
    };

    if (activeGroup) {
      fetchEventData();
    } else {
      setError("Please select a group before applying");
      setLoading(false);
    }
  }, [eventId, activeGroup]);

  // Check if the current group can apply for the event
  const canApplyForEvent = () => {
    if (!activeGroup || !event) return false;

    const today = new Date();
    const cutoffDate = new Date(
      activeGroup.class === "vendor" ? event.vendor_cutoff : event.app_cutoff
    );

    // Check if event is in the future and cutoff date hasn't passed
    const eventDate = new Date(event.event_startdate);

    // Handle different application types based on group class
    if (activeGroup.class === "vendor" && event.vendorAppType === "closed") {
      return false;
    }
    if (activeGroup.class === "guild" && event.appType === "closed") {
      return false;
    }
    if (activeGroup.class === "act" && event.actAppType === "closed") {
      return false;
    }

    return eventDate > today && today <= cutoffDate;
  };

  // Format date function
  const formatDate = (dateString) => {
    try {
      return format(new Date(dateString), "MMMM d, yyyy");
    } catch (e) {
      return "Invalid date";
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name.startsWith("question_")) {
      const questionId = name.split("_")[1];
      setFormData((prev) => ({
        ...prev,
        answers: {
          ...prev.answers,
          [questionId]: value,
        },
      }));
    } else if (name.startsWith("agreement_")) {
      const agreementKey = name.split("_")[1];
      setFormData((prev) => ({
        ...prev,
        agreements: {
          ...prev.agreements,
          [agreementKey]: checked,
        },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };

  const validateCurrentStep = () => {
    if (currentStep === 1) {
      return true; // Basic information step
    } else if (currentStep === 2) {
      // Validate custom questions
      const requiredQuestions = questions.filter((q) => q.mandatory === 1);
      const unansweredQuestions = requiredQuestions.filter(
        (q) =>
          !formData.answers[q.kickerId] ||
          formData.answers[q.kickerId].trim() === ""
      );

      if (unansweredQuestions.length > 0) {
        setError(
          `Please answer all required questions. Missing: ${unansweredQuestions
            .map((q) => q.question)
            .join(", ")}`
        );
        return false;
      }
      return true;
    } else if (currentStep === 3) {
      // Validate agreements
      if (
        !formData.agreements.rulesAgreement ||
        !formData.agreements.liabilityAgreement
      ) {
        setError("You must agree to all terms to proceed");
        return false;
      }
      return true;
    }
    return true;
  };

  const handleNextStep = () => {
    if (validateCurrentStep()) {
      setError(null);
      setCurrentStep((prev) => prev + 1);
    }
  };

  const handlePrevStep = () => {
    setError(null);
    setCurrentStep((prev) => Math.max(1, prev - 1));
  };

  const handleSubmit = async () => {
    if (!validateCurrentStep()) {
      return;
    }

    setSubmitting(true);
    setError(null);

    try {
      // Prepare application data
      const applicationData = {
        eventId: parseInt(eventId, 10),
        groupId: activeGroup.groupID,
        answers: Object.entries(formData.answers).map(
          ([questionId, answer]) => ({
            questionId: parseInt(questionId, 10),
            answer,
          })
        ),
        spaceNeeded: formData.spaceNeeded,
        frontage: formData.frontage,
        depth: formData.depth,
        needsWater: formData.needsWater,
        needsPower: formData.needsPower,
        specialRequests: formData.specialRequests,
        paymentMethod: formData.paymentMethod,
        agreedToRules: formData.agreements.rulesAgreement,
        agreedToLiability: formData.agreements.liabilityAgreement,
      };

      // Submit application
      const response = await api.post("/api/applications", applicationData);

      // Navigate to confirmation page
      navigate(`/applications/confirmation/${response.data.applicationId}`);
    } catch (err) {
      console.error("Error submitting application:", err);
      setError(
        err.response?.data?.error ||
          "Failed to submit application. Please try again."
      );
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return (
      <main className="flex-1 p-4 sm:p-6 md:p-8">
        <div className="max-w-6xl mx-auto">
          <div className="max-w-4xl mx-auto flex justify-center items-center h-full">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
          </div>
          {/* Footer */}
          <div className="text-center text-gray-500 text-sm mt-12">
            Copyright by Renaissance Productions 2006-{new Date().getFullYear()}
          </div>
        </div>
      </main>
    );
  }

  if (error && !activeGroup) {
    return (
      <main className="flex-1 p-4 sm:p-6 md:p-8">
        <div className="max-w-6xl mx-auto">
          <div className="bg-red-50 border-l-4 border-red-500 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <XCircle className="h-5 w-5 text-red-400" />
              </div>
              <div className="ml-3">
                <p className="text-sm text-red-700">{error}</p>
              </div>
            </div>
          </div>
          <button
            onClick={() => navigate("/events")}
            className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
          >
            <ArrowLeft className="w-5 h-5 mr-2" />
            Back to Events
          </button>
        </div>
        {/* Footer */}
        <div className="text-center text-gray-500 text-sm mt-12">
          Copyright by Renaissance Productions 2006-{new Date().getFullYear()}
        </div>
      </main>
    );
  }

  if (!event) {
    return (
      <main className="flex-1 p-4 sm:p-6 md:p-8">
        <div className="max-w-6xl mx-auto">
          <div className="bg-yellow-50 border-l-4 border-yellow-500 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <AlertTriangle className="h-5 w-5 text-yellow-400" />
              </div>
              <div className="ml-3">
                <p className="text-sm text-yellow-700">Event not found</p>
              </div>
            </div>
          </div>
          <button
            onClick={() => navigate("/events")}
            className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
          >
            <ArrowLeft className="w-5 h-5 mr-2" />
            Back to Events
          </button>
        </div>
        {/* Footer */}
        <div className="text-center text-gray-500 text-sm mt-12">
          Copyright by Renaissance Productions 2006-{new Date().getFullYear()}
        </div>
      </main>
    );
  }

  if (!canApplyForEvent()) {
    return (
      <main className="flex-1 p-4 sm:p-6 md:p-8">
        <div className="max-w-6xl mx-auto">
          <div className="bg-red-50 border-l-4 border-red-500 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <XCircle className="h-5 w-5 text-red-400" />
              </div>
              <div className="ml-3">
                <p className="text-sm text-red-700">
                  Applications are currently closed for this event. The
                  application deadline has passed or the event is not accepting
                  applications for {activeGroup.class}s.
                </p>
              </div>
            </div>
          </div>
          <button
            onClick={() => navigate(`/events/view/${eventId}`)}
            className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
          >
            <ArrowLeft className="w-5 h-5 mr-2" />
            Back to Event Details
          </button>
        </div>
        {/* Footer */}
        <div className="text-center text-gray-500 text-sm mt-12">
          Copyright by Renaissance Productions 2006-{new Date().getFullYear()}
        </div>
      </main>
    );
  }

  return (
    <main className="flex-1 p-4 sm:p-6 md:p-8">
      <div className="max-w-6xl mx-auto">
        <div className="max-w-4xl mx-auto">
          {/* Header */}
          <div className="bg-white shadow rounded-lg mb-6">
            <div className="px-6 py-5">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <button
                    onClick={() => navigate(`/events/view/${eventId}`)}
                    className="mr-4 p-2 rounded-full hover:bg-gray-100"
                  >
                    <ArrowLeft className="w-5 h-5" />
                  </button>
                  <div>
                    <h1 className="text-2xl font-bold text-gray-900">
                      Apply for: {event.eventName}
                    </h1>
                    <p className="text-sm text-gray-500">
                      Event Date: {formatDate(event.event_startdate)}
                    </p>
                  </div>
                </div>
              </div>

              {/* Status Badge */}
              <div className="mt-2 flex items-center space-x-2">
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                  Applying as: {activeGroup.group_name} ({activeGroup.class})
                </span>
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                  <Calendar className="mr-1 h-3 w-3" />
                  Deadline:{" "}
                  {formatDate(
                    activeGroup.class === "vendor"
                      ? event.vendor_cutoff
                      : event.app_cutoff
                  )}
                </span>
              </div>
            </div>

            {/* Progress Steps */}
            <div className="border-t border-gray-200">
              <div className="px-6 py-4">
                <nav className="flex items-center">
                  <ol className="flex items-center w-full">
                    <li
                      className={`flex items-center ${
                        currentStep > 1
                          ? "text-blue-600"
                          : "text-blue-600 font-medium"
                      }`}
                    >
                      <span
                        className={`flex items-center justify-center h-6 w-6 rounded-full ${
                          currentStep === 1
                            ? "bg-blue-600 text-white"
                            : currentStep > 1
                            ? "bg-blue-600 text-white"
                            : "border border-gray-300"
                        }`}
                      >
                        {currentStep > 1 ? (
                          <CheckCircle className="h-4 w-4" />
                        ) : (
                          1
                        )}
                      </span>
                      <span className="ml-2">Basic Info</span>
                    </li>
                    <li
                      className={`flex items-center ml-6 lg:ml-12 ${
                        currentStep > 2
                          ? "text-blue-600"
                          : currentStep === 2
                          ? "text-blue-600 font-medium"
                          : "text-gray-500"
                      }`}
                    >
                      <ChevronRight className="h-4 w-4 mr-2 text-gray-400" />
                      <span
                        className={`flex items-center justify-center h-6 w-6 rounded-full ${
                          currentStep === 2
                            ? "bg-blue-600 text-white"
                            : currentStep > 2
                            ? "bg-blue-600 text-white"
                            : "border border-gray-300"
                        }`}
                      >
                        {currentStep > 2 ? (
                          <CheckCircle className="h-4 w-4" />
                        ) : (
                          2
                        )}
                      </span>
                      <span className="ml-2">Questionnaire</span>
                    </li>
                    <li
                      className={`flex items-center ml-6 lg:ml-12 ${
                        currentStep === 3
                          ? "text-blue-600 font-medium"
                          : "text-gray-500"
                      }`}
                    >
                      <ChevronRight className="h-4 w-4 mr-2 text-gray-400" />
                      <span
                        className={`flex items-center justify-center h-6 w-6 rounded-full ${
                          currentStep === 3
                            ? "bg-blue-600 text-white"
                            : "border border-gray-300"
                        }`}
                      >
                        {currentStep > 3 ? (
                          <CheckCircle className="h-4 w-4" />
                        ) : (
                          3
                        )}
                      </span>
                      <span className="ml-2">Review & Submit</span>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>

          {/* Error Alert */}
          {error && (
            <div className="mb-6 bg-red-50 border-l-4 border-red-500 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <XCircle className="h-5 w-5 text-red-400" />
                </div>
                <div className="ml-3">
                  <p className="text-sm text-red-700">{error}</p>
                </div>
              </div>
            </div>
          )}

          {/* Form Content based on current step */}
          <div className="bg-white shadow rounded-lg overflow-hidden">
            {/* Step 1: Basic Information */}
            {currentStep === 1 && (
              <div>
                <div className="px-6 py-5 border-b border-gray-200">
                  <h2 className="text-lg font-medium text-gray-900">
                    Basic Information
                  </h2>
                </div>

                <div className="px-6 py-5">
                  <p className="mb-6 text-gray-700">
                    Please provide basic information about your participation at{" "}
                    {event.eventName}.
                  </p>

                  <div className="space-y-6">
                    {/* Space Requirements - for vendors and guilds */}
                    {(activeGroup.class === "vendor" ||
                      activeGroup.class === "guild") && (
                      <div>
                        <h3 className="text-base font-medium text-gray-900 mb-4">
                          Space Requirements
                        </h3>

                        <div className="mb-4">
                          <div className="flex items-center">
                            <input
                              id="spaceNeeded"
                              name="spaceNeeded"
                              type="checkbox"
                              checked={formData.spaceNeeded}
                              onChange={handleInputChange}
                              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                            />
                            <label
                              htmlFor="spaceNeeded"
                              className="ml-2 block text-sm text-gray-900"
                            >
                              I need dedicated space at this event
                            </label>
                          </div>
                        </div>

                        {formData.spaceNeeded && (
                          <div className="ml-6 mt-4 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-2">
                            <div>
                              <label
                                htmlFor="frontage"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Frontage (feet)
                              </label>
                              <div className="mt-1">
                                <input
                                  type="number"
                                  name="frontage"
                                  id="frontage"
                                  value={formData.frontage}
                                  onChange={handleInputChange}
                                  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                  placeholder="10"
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="depth"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Depth (feet)
                              </label>
                              <div className="mt-1">
                                <input
                                  type="number"
                                  name="depth"
                                  id="depth"
                                  value={formData.depth}
                                  onChange={handleInputChange}
                                  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                  placeholder="10"
                                />
                              </div>
                            </div>
                          </div>
                        )}

                        {/* Vendor-specific utilities */}
                        {activeGroup.class === "vendor" && (
                          <div className="mt-6 ml-6">
                            <h4 className="text-sm font-medium text-gray-700 mb-3">
                              Utilities Needed:
                            </h4>
                            <div className="space-y-2">
                              <div className="flex items-center">
                                <input
                                  id="needsWater"
                                  name="needsWater"
                                  type="checkbox"
                                  checked={formData.needsWater}
                                  onChange={handleInputChange}
                                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                />
                                <label
                                  htmlFor="needsWater"
                                  className="ml-2 block text-sm text-gray-700"
                                >
                                  Water access
                                </label>
                              </div>
                              <div className="flex items-center">
                                <input
                                  id="needsPower"
                                  name="needsPower"
                                  type="checkbox"
                                  checked={formData.needsPower}
                                  onChange={handleInputChange}
                                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                />
                                <label
                                  htmlFor="needsPower"
                                  className="ml-2 block text-sm text-gray-700"
                                >
                                  Electrical power
                                </label>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    {/* Special requests - for all groups */}
                    <div>
                      <label
                        htmlFor="specialRequests"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Special Requests or Notes
                      </label>
                      <div className="mt-1">
                        <textarea
                          id="specialRequests"
                          name="specialRequests"
                          rows={4}
                          value={formData.specialRequests}
                          onChange={handleInputChange}
                          className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          placeholder="Any additional information or special requests..."
                        />
                      </div>
                    </div>

                    {/* Payment Methods - if applicable */}
                    {(activeGroup.class === "vendor" ||
                      (event.vendorAppType !== "free" &&
                        event.appType !== "free")) && (
                      <div>
                        {/* 
                        <h3 className="text-base font-medium text-gray-900 mb-4">
                          Payment Method
                        </h3>

                       <div className="space-y-4">
                          {event.allowPaypal && (
                            <div className="flex items-center">
                              <input
                                id="paymentMethod-paypal"
                                name="paymentMethod"
                                type="radio"
                                value="paypal"
                                checked={formData.paymentMethod === "paypal"}
                                onChange={handleInputChange}
                                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
                              />
                              <label
                                htmlFor="paymentMethod-paypal"
                                className="ml-3 block text-sm font-medium text-gray-700"
                              >
                                <div className="flex items-center">
                                  <CreditCard className="h-5 w-5 text-gray-400 mr-2" />
                                  PayPal / Credit Card
                                </div>
                              </label>
                            </div>
                          )}

                          {event.allowCheck && (
                            <div className="flex items-center">
                              <input
                                id="paymentMethod-check"
                                name="paymentMethod"
                                type="radio"
                                value="check"
                                checked={formData.paymentMethod === "check"}
                                onChange={handleInputChange}
                                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
                              />
                              <label
                                htmlFor="paymentMethod-check"
                                className="ml-3 block text-sm font-medium text-gray-700"
                              >
                                <div className="flex items-center">
                                  <DollarSign className="h-5 w-5 text-gray-400 mr-2" />
                                  Check / Money Order
                                </div>
                              </label>
                            </div>
                          )}
                        </div> */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {/* Step 2: Event Questions */}
            {currentStep === 2 && (
              <div>
                <div className="px-6 py-5 border-b border-gray-200">
                  <h2 className="text-lg font-medium text-gray-900">
                    Event Questions
                  </h2>
                </div>

                <div className="px-6 py-5">
                  <p className="mb-6 text-gray-700">
                    Please answer the following questions from the event
                    organizer.
                  </p>

                  {questions.length === 0 ? (
                    <div className="bg-gray-50 p-4 rounded-md">
                      <p className="text-gray-500 italic">
                        There are no additional questions for this event.
                      </p>
                    </div>
                  ) : (
                    <div className="space-y-6">
                      {questions.map((question) => (
                        <div key={question.kickerId}>
                          <label
                            htmlFor={`question_${question.kickerId}`}
                            className="block text-sm font-medium text-gray-700"
                          >
                            {question.question}
                            {question.mandatory === 1 && (
                              <span className="text-red-500 ml-1">*</span>
                            )}
                          </label>

                          <div className="mt-1">
                            {question.questiontype === "textarea" ? (
                              <textarea
                                id={`question_${question.kickerId}`}
                                name={`question_${question.kickerId}`}
                                rows={4}
                                value={
                                  formData.answers[question.kickerId] || ""
                                }
                                onChange={handleInputChange}
                                className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                required={question.mandatory === 1}
                              />
                            ) : question.questiontype === "select" ? (
                              <select
                                id={`question_${question.kickerId}`}
                                name={`question_${question.kickerId}`}
                                value={
                                  formData.answers[question.kickerId] || ""
                                }
                                onChange={handleInputChange}
                                className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                required={question.mandatory === 1}
                              >
                                <option value="">Select an option</option>
                                {question.options &&
                                  question.options.map((option, idx) => (
                                    <option key={idx} value={option}>
                                      {option}
                                    </option>
                                  ))}
                              </select>
                            ) : (
                              <input
                                type="text"
                                id={`question_${question.kickerId}`}
                                name={`question_${question.kickerId}`}
                                value={
                                  formData.answers[question.kickerId] || ""
                                }
                                onChange={handleInputChange}
                                className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                required={question.mandatory === 1}
                              />
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            )}

            {/* Step 3: Review & Submit */}
            {currentStep === 3 && (
              <div>
                <div className="px-6 py-5 border-b border-gray-200">
                  <h2 className="text-lg font-medium text-gray-900">
                    Review & Submit
                  </h2>
                </div>

                <div className="px-6 py-5">
                  <p className="mb-6 text-gray-700">
                    Please review your application before submitting.
                  </p>

                  <div className="space-y-6">
                    <div className="bg-gray-50 p-4 rounded-md">
                      <h3 className="text-base font-medium text-gray-900 mb-2">
                        Application Summary
                      </h3>

                      <div className="space-y-3 text-sm">
                        <div>
                          <span className="font-medium">Event: </span>
                          <span>{event.eventName}</span>
                        </div>
                        <div>
                          <span className="font-medium">Date: </span>
                          <span>{formatDate(event.event_startdate)}</span>
                        </div>
                        <div>
                          <span className="font-medium">Group: </span>
                          <span>
                            {activeGroup.group_name} ({activeGroup.class})
                          </span>
                        </div>

                        {formData.spaceNeeded && (
                          <div>
                            <span className="font-medium">
                              Space Requested:{" "}
                            </span>
                            <span>
                              {formData.frontage}' × {formData.depth}'
                            </span>
                          </div>
                        )}

                        {formData.needsWater && (
                          <div>
                            <span className="font-medium">Water Access: </span>
                            <span>Requested</span>
                          </div>
                        )}

                        {formData.needsPower && (
                          <div>
                            <span className="font-medium">
                              Electrical Power:{" "}
                            </span>
                            <span>Requested</span>
                          </div>
                        )}

                        {formData.specialRequests && (
                          <div>
                            <span className="font-medium">
                              Special Requests:{" "}
                            </span>
                            <span>{formData.specialRequests}</span>
                          </div>
                        )}

                        {(activeGroup.class === "vendor" ||
                          (event.vendorAppType !== "free" &&
                            event.appType !== "free")) && (
                          <div>
                            <span className="font-medium">
                              Payment Method:{" "}
                            </span>
                            <span>
                              {formData.paymentMethod === "paypal"
                                ? "PayPal / Credit Card"
                                : "Check / Money Order"}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>

                    {/* Event Rules and Liability */}
                    <div className="space-y-4">
                      <h3 className="text-base font-medium text-gray-900">
                        Terms and Agreements
                      </h3>

                      <div className="p-4 border border-gray-200 rounded-md bg-gray-50 max-h-48 overflow-y-auto">
                        <h4 className="font-medium mb-2">Event Rules</h4>
                        {event.rules ? (
                          <div
                            className="prose prose-sm max-w-none text-gray-700"
                            dangerouslySetInnerHTML={{ __html: event.rules }}
                          />
                        ) : (
                          <p className="text-gray-500 italic">
                            No specific rules provided for this event.
                          </p>
                        )}
                      </div>

                      <div className="flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            id="agreement_rulesAgreement"
                            name="agreement_rulesAgreement"
                            type="checkbox"
                            checked={formData.agreements.rulesAgreement}
                            onChange={handleInputChange}
                            className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="agreement_rulesAgreement"
                            className="font-medium text-gray-700"
                          >
                            I agree to follow all event rules and guidelines
                          </label>
                        </div>
                      </div>

                      <div className="flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            id="agreement_liabilityAgreement"
                            name="agreement_liabilityAgreement"
                            type="checkbox"
                            checked={formData.agreements.liabilityAgreement}
                            onChange={handleInputChange}
                            className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="agreement_liabilityAgreement"
                            className="font-medium text-gray-700"
                          >
                            I understand and accept liability for my
                            participation in this event
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* Form Navigation */}
            <div className="px-6 py-5 bg-gray-50 flex items-center justify-between">
              {currentStep > 1 ? (
                <button
                  type="button"
                  onClick={handlePrevStep}
                  className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <ArrowLeft className="h-4 w-4 mr-2" />
                  Previous
                </button>
              ) : (
                <button
                  type="button"
                  onClick={() => navigate(`/events/view/${eventId}`)}
                  className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  <ArrowLeft className="h-4 w-4 mr-2" />
                  Cancel
                </button>
              )}

              {currentStep < 3 ? (
                <button
                  type="button"
                  onClick={handleNextStep}
                  className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                  Next
                  <ChevronRight className="h-4 w-4 ml-2" />
                </button>
              ) : (
                <button
                  type="button"
                  onClick={handleSubmit}
                  disabled={submitting}
                  className={`inline-flex items-center px-6 py-3 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
                    submitting
                      ? "bg-blue-400 cursor-not-allowed"
                      : "bg-blue-600 hover:bg-blue-700"
                  }`}
                >
                  {submitting ? (
                    <>
                      <Loader className="animate-spin h-4 w-4 mr-2" />
                      Submitting...
                    </>
                  ) : (
                    <>
                      <Send className="h-4 w-4 mr-2" />
                      Submit Application
                    </>
                  )}
                </button>
              )}
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className="text-center text-gray-500 text-sm mt-12">
          Copyright by Renaissance Productions 2006-{new Date().getFullYear()}
        </div>
      </div>
    </main>
  );
};

export default ApplicationForm;
