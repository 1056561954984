// src/components/user/CreateGroup.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../utils/api";
import { useAuth } from "../../contexts/AuthContext";
import { useGroup } from "../../contexts/GroupContext";
import { Users, ArrowLeft, AlertTriangle } from "lucide-react";
import { Card, CardHeader, CardContent, CardFooter } from "../ui/Card";
import { Button } from "../ui/Button";
import { Alert } from "../ui/Alert";
import GroupForm from "./GroupForm"; // We'll create this component next

const CreateGroup = () => {
  const { user } = useAuth();
  const { refreshGroups, selectGroup } = useGroup();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate();

  const handleCancel = () => {
    navigate("/my-groups");
  };

  const handleSaveGroup = async (formData) => {
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const response = await api.post(`/api/groups`, formData);

      // Refresh the groups list
      await refreshGroups();

      // Auto-select the newly created group
      if (response.data && response.data.groupID) {
        selectGroup(response.data);
      }

      setSuccess("Group created successfully!");

      // Navigate to dashboard after a brief delay to show success message
      setTimeout(() => {
        navigate("/dashboard");
      }, 1500);
    } catch (err) {
      console.error("Error creating group:", err);
      setError(err.response?.data?.error || "Failed to create group");
    } finally {
      setLoading(false);
    }
  };

  return (
    <main className="max-w-4xl mx-auto p-4 sm:p-6 md:p-8">
      <Card>
        <CardHeader
          title="Create New Group"
          subtitle="Set up your group to participate in events"
          icon={Users}
        />

        {error && (
          <Alert
            variant="error"
            title="Error"
            message={error}
            className="mx-6 mb-4"
          />
        )}

        {success && (
          <Alert
            variant="success"
            title="Success"
            message={success}
            className="mx-6 mb-4"
          />
        )}

        <CardContent>
          <GroupForm
            onSave={handleSaveGroup}
            isEdit={false}
            loading={loading}
          />
        </CardContent>

        <CardFooter className="flex justify-between">
          <Button
            variant="outline"
            onClick={handleCancel}
            icon={ArrowLeft}
            disabled={loading}
          >
            Back to My Groups
          </Button>
        </CardFooter>
      </Card>
    </main>
  );
};

export default CreateGroup;
