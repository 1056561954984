// src/components/Sidebar.js
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { useGroup } from "../contexts/GroupContext";
import { Transition } from "@headlessui/react";
import {
  ChevronDown,
  ChevronUp,
  User,
  Users,
  Calendar,
  Store,
  Theater,
  Castle,
  Menu,
  X,
  LogOut,
  Home,
  Settings,
  FileText,
  Shield,
} from "lucide-react";

const Sidebar = () => {
  const { user, logout } = useAuth();
  const { userGroups, activeGroup, selectGroup } = useGroup();
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [showGroupDropdown, setShowGroupDropdown] = useState(false);
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Close mobile sidebar when route changes
  useEffect(() => {
    setIsMobileSidebarOpen(false);
  }, [location.pathname]);

  // Close mobile sidebar on larger screens
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setIsMobileSidebarOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Mock admin check - replace with your actual admin check logic
  const isAdmin = user?.email?.endsWith("@renregister.com");

  const handleLogoutClick = () => setShowLogoutConfirm(true);
  const handleLogoutCancel = () => setShowLogoutConfirm(false);
  const handleLogoutConfirm = async () => {
    setIsLoggingOut(true);
    try {
      await logout();
      navigate("/login");
    } catch (error) {
      console.error("Failed to log out:", error);
    } finally {
      setIsLoggingOut(false);
      setShowLogoutConfirm(false);
    }
  };

  // Handle group selection
  const handleGroupSelect = (group) => {
    selectGroup(group);
    setShowGroupDropdown(false);
  };

  // Clear active group
  const handleClearActiveGroup = () => {
    selectGroup(null);
    setShowGroupDropdown(false);
  };

  // Check if a link is active
  const isLinkActive = (path) => {
    if (path === "/") return location.pathname === "/";
    return location.pathname.startsWith(path);
  };

  // Sidebar link component for consistency
  const SidebarLink = ({ to, icon: Icon, children, isActive }) => (
    <Link
      to={to}
      className={`flex items-center px-4 py-2.5 text-sm rounded-md transition-colors duration-150 ${
        isActive
          ? "bg-primary-100 text-primary-800"
          : "text-gray-700 hover:bg-gray-100"
      }`}
    >
      {Icon && (
        <Icon
          className={`w-5 h-5 mr-2 ${
            isActive ? "text-primary-600" : "text-gray-500"
          }`}
        />
      )}
      <span>{children}</span>
    </Link>
  );

  // Mobile Sidebar Toggle Button (fixed to bottom right on mobile)
  const MobileToggle = () => (
    <button
      onClick={() => setIsMobileSidebarOpen(!isMobileSidebarOpen)}
      className="fixed md:hidden z-50 bottom-4 right-4 p-3 rounded-full bg-primary-600 text-white shadow-lg focus:outline-none"
      aria-label="Toggle sidebar"
    >
      {isMobileSidebarOpen ? (
        <X className="w-5 h-5" />
      ) : (
        <Menu className="w-5 h-5" />
      )}
    </button>
  );

  // Main sidebar content that will be used in both mobile and desktop versions
  const SidebarContent = () => (
    <>
      {/* Group Selector Bar */}
      {user && (
        <div className="p-4 border-b border-gray-200">
          <div
            className="relative"
            onMouseLeave={() => setShowGroupDropdown(false)}
          >
            <button
              className="w-full flex items-center justify-between p-2 bg-gray-50 hover:bg-gray-100 rounded border text-left"
              onClick={() => setShowGroupDropdown(!showGroupDropdown)}
            >
              <div className="flex items-center">
                {activeGroup ? (
                  <>
                    <div className="h-6 w-6 rounded-full bg-primary-500 flex items-center justify-center text-white text-xs mr-2">
                      {activeGroup.class.charAt(0).toUpperCase()}
                    </div>
                    <span className="truncate max-w-[160px]">
                      {activeGroup.group_name}
                    </span>
                  </>
                ) : (
                  <span className="text-gray-500">Select a group...</span>
                )}
              </div>
              {showGroupDropdown ? (
                <ChevronUp size={16} />
              ) : (
                <ChevronDown size={16} />
              )}
            </button>

            {/* Dropdown */}
            <Transition
              show={showGroupDropdown}
              enter="transition-opacity duration-100"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute left-0 right-0 mt-1 bg-white border rounded-lg shadow-lg z-10 max-h-60 overflow-y-auto">
                {/* Personal option */}
                <button
                  className={`w-full flex items-center p-2 text-left hover:bg-gray-50 ${
                    !activeGroup ? "bg-primary-50 text-primary-700" : ""
                  }`}
                  onClick={handleClearActiveGroup}
                >
                  <User size={16} className="mr-2" />
                  <span>Personal Account</span>
                </button>

                {/* Divider */}
                <div className="border-t border-gray-200 my-1"></div>

                {/* Group options */}
                <div className="py-1">
                  {userGroups.length > 0 ? (
                    userGroups.map((group) => (
                      <button
                        key={group.groupID}
                        className={`w-full flex items-center p-2 text-left hover:bg-gray-50 ${
                          activeGroup?.groupID === group.groupID
                            ? "bg-primary-50 text-primary-700"
                            : ""
                        }`}
                        onClick={() => handleGroupSelect(group)}
                      >
                        <div
                          className={`h-6 w-6 rounded-full flex items-center justify-center text-white text-xs mr-2 ${
                            group.class === "guild"
                              ? "bg-secondary-500"
                              : group.class === "vendor"
                              ? "bg-accent-500"
                              : group.class === "act"
                              ? "bg-renaissance-burgundy"
                              : "bg-renaissance-navy"
                          }`}
                        >
                          {group.class.charAt(0).toUpperCase()}
                        </div>
                        <div className="truncate max-w-[160px]">
                          <div className="text-sm font-medium">
                            {group.group_name}
                          </div>
                          <div className="text-xs text-gray-500 capitalize">
                            {group.class}
                          </div>
                        </div>
                      </button>
                    ))
                  ) : (
                    <div className="p-2 text-sm text-gray-500">
                      No groups available
                    </div>
                  )}
                </div>

                {/* Go to My Groups link */}
                <div className="border-t border-gray-200 my-1"></div>
                <Link
                  to="/my-groups"
                  className="block w-full p-2 text-left text-primary-600 hover:bg-primary-50 text-sm"
                  onClick={() => setShowGroupDropdown(false)}
                >
                  <div className="flex items-center">
                    <Users size={16} className="mr-2" />
                    Manage My Groups
                  </div>
                </Link>
              </div>
            </Transition>
          </div>
          {activeGroup && (
            <div className="mt-2 text-xs text-gray-500 italic px-2">
              Acting as {activeGroup.group_name}
            </div>
          )}
        </div>
      )}

      <div className="px-4 py-5 space-y-2 flex-grow overflow-y-auto">
        {/* Public Navigation */}
        <div className="space-y-1">
          <SidebarLink to="/" icon={Home} isActive={isLinkActive("/")}>
            Home
          </SidebarLink>
        </div>

        {/* Authenticated User Navigation */}
        {user && (
          <div className="pt-4 border-t border-gray-200 space-y-1">
            <h3 className="px-4 py-2 text-xs font-semibold text-gray-500 uppercase tracking-wider">
              User Menu
            </h3>

            <SidebarLink
              to="/dashboard"
              icon={Home}
              isActive={isLinkActive("/dashboard")}
            >
              Dashboard
            </SidebarLink>

            <SidebarLink
              to="/my-groups"
              icon={Users}
              isActive={isLinkActive("/my-groups")}
            >
              My Groups
            </SidebarLink>

            {/* Show "Events" link if active group is not a faire organizer */}
            {activeGroup && activeGroup.class !== "faire" && (
              <SidebarLink
                to="/events"
                icon={Calendar}
                isActive={isLinkActive("/events")}
              >
                Events
              </SidebarLink>
            )}

            {/* Show "My Events" link if active group is a faire organizer */}
            {activeGroup && activeGroup.class === "faire" && (
              <>
                <SidebarLink
                  to="/my-events"
                  icon={Calendar}
                  isActive={isLinkActive("/my-events")}
                >
                  My Events
                </SidebarLink>
                <SidebarLink
                  to="/guilds"
                  icon={Castle}
                  isActive={isLinkActive("/guilds")}
                >
                  Guilds
                </SidebarLink>
                <SidebarLink
                  to="/stage-acts"
                  icon={Theater}
                  isActive={isLinkActive("/stage-acts")}
                >
                  Stage Acts
                </SidebarLink>
                <SidebarLink
                  to="/vendors"
                  icon={Store}
                  isActive={isLinkActive("/vendors")}
                >
                  Vendors
                </SidebarLink>
              </>
            )}

            {activeGroup && activeGroup.class !== "faire" && (
              <SidebarLink
                to="/my-applications"
                icon={FileText}
                isActive={isLinkActive("/my-applications")}
              >
                My Applications
              </SidebarLink>
            )}

            <SidebarLink
              to="/my-profile"
              icon={Settings}
              isActive={isLinkActive("/my-profile")}
            >
              Profile Settings
            </SidebarLink>
          </div>
        )}

        {/* Admin Navigation */}
        {isAdmin && (
          <div className="pt-4 border-t border-gray-200 space-y-1">
            <h3 className="px-4 py-2 text-xs font-semibold text-gray-500 uppercase tracking-wider">
              Admin
            </h3>
            <SidebarLink
              to="/admin/users"
              icon={Shield}
              isActive={isLinkActive("/admin/users")}
            >
              User Management
            </SidebarLink>
            <SidebarLink
              to="/admin/events"
              icon={Calendar}
              isActive={isLinkActive("/admin/events")}
            >
              Event Management
            </SidebarLink>
            <SidebarLink
              to="/admin/applications"
              icon={FileText}
              isActive={isLinkActive("/admin/applications")}
            >
              Application Review
            </SidebarLink>
            <SidebarLink
              to="/admin/settings"
              icon={Settings}
              isActive={isLinkActive("/admin/settings")}
            >
              Site Settings
            </SidebarLink>
          </div>
        )}

        {/* Non-authenticated User Links */}
        {!user && (
          <div className="pt-4 space-y-1">
            <SidebarLink
              to="/create-account"
              isActive={isLinkActive("/create-account")}
            >
              Create Account
            </SidebarLink>
            <SidebarLink to="/login" isActive={isLinkActive("/login")}>
              Log In
            </SidebarLink>
          </div>
        )}
      </div>

      {/* User Info and Logout */}
      {user && (
        <div className="px-4 py-4 border-t border-gray-200">
          <div className="px-4 py-2 text-sm text-gray-500">{user.email}</div>
          <button
            onClick={handleLogoutClick}
            disabled={isLoggingOut}
            className={`w-full px-4 py-2 text-left rounded flex items-center transition-colors duration-150 ${
              isLoggingOut
                ? "text-gray-400 bg-gray-50 cursor-not-allowed"
                : "text-red-600 hover:bg-red-50"
            }`}
          >
            {isLoggingOut ? (
              <>
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-red-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                Logging out...
              </>
            ) : (
              <>
                <LogOut className="w-4 h-4 mr-2" />
                Log Out
              </>
            )}
          </button>
        </div>
      )}
    </>
  );

  return (
    <>
      {/* Mobile Toggle Button */}
      <MobileToggle />

      {/* Desktop Sidebar */}
      <nav className="hidden md:flex w-64 bg-white shadow-lg flex-col h-screen sticky top-0">
        <SidebarContent />
      </nav>

      {/* Mobile Sidebar Overlay */}
      <Transition
        show={isMobileSidebarOpen}
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="md:hidden"
      >
        <div
          className="fixed inset-0 bg-gray-600 bg-opacity-75 z-40"
          onClick={() => setIsMobileSidebarOpen(false)}
        ></div>
      </Transition>

      {/* Mobile Sidebar */}
      <Transition
        show={isMobileSidebarOpen}
        enter="transition-transform duration-300"
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leave="transition-transform duration-200"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
        className="md:hidden"
      >
        <nav className="fixed z-50 w-64 h-full bg-white shadow-lg flex flex-col">
          <SidebarContent />
        </nav>
      </Transition>

      {/* Logout Confirmation Modal with Transition */}
      <Transition
        show={showLogoutConfirm}
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
          <Transition.Child
            enter="transition-all duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition-all duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="bg-white rounded-lg p-6 max-w-sm mx-4 shadow-xl">
              <h3 className="text-lg font-medium text-gray-900 mb-4">
                Confirm Logout
              </h3>
              <p className="text-gray-500 mb-6">
                Are you sure you want to log out?
              </p>
              <div className="flex justify-end space-x-3">
                <button
                  onClick={handleLogoutCancel}
                  className="px-4 py-2 text-gray-700 border border-gray-300 rounded hover:bg-gray-50 transition-colors duration-150"
                >
                  Cancel
                </button>
                <button
                  onClick={handleLogoutConfirm}
                  disabled={isLoggingOut}
                  className={`px-4 py-2 text-white rounded transition-colors duration-150 ${
                    isLoggingOut
                      ? "bg-red-400 cursor-not-allowed"
                      : "bg-red-600 hover:bg-red-700"
                  }`}
                >
                  {isLoggingOut ? "Logging out..." : "Log Out"}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Transition>
    </>
  );
};

export default Sidebar;
