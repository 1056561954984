// client/src/components/ApplicationStatusBadge.js
import React from "react";
import { getStatusColor, getStatusIcon } from "../utils/applicationStatusUtils";
import * as Icons from "lucide-react";

/**
 * A reusable badge component to display application status
 */
const ApplicationStatusBadge = ({ status, className = "", iconSize = 4 }) => {
  if (!status) return null;

  // Determine which icon to use
  const IconComponent = Icons[getStatusIcon(status)];
  const statusColor = getStatusColor(status);

  return (
    <span
      className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${statusColor} ${className}`}
    >
      {IconComponent && (
        <IconComponent className={`w-${iconSize} h-${iconSize} mr-1`} />
      )}
      {status}
    </span>
  );
};

export default ApplicationStatusBadge;
