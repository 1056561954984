import React from "react";

const AuthErrorMessage = ({ message, type = "error", showIcon = true }) => {
  if (!message) return null;

  let bgColor, borderColor, textColor, icon;

  switch (type) {
    case "warning":
      bgColor = "bg-yellow-50";
      borderColor = "border-yellow-400";
      textColor = "text-yellow-700";
      icon = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 text-yellow-400"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
            clipRule="evenodd"
          />
        </svg>
      );
      break;
    case "info":
      bgColor = "bg-blue-50";
      borderColor = "border-blue-400";
      textColor = "text-blue-700";
      icon = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 text-blue-400"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
            clipRule="evenodd"
          />
        </svg>
      );
      break;
    case "success":
      bgColor = "bg-green-50";
      borderColor = "border-green-400";
      textColor = "text-green-700";
      icon = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 text-green-400"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
            clipRule="evenodd"
          />
        </svg>
      );
      break;
    case "error":
    default:
      bgColor = "bg-red-50";
      borderColor = "border-red-400";
      textColor = "text-red-700";
      icon = (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 text-red-400"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
            clipRule="evenodd"
          />
        </svg>
      );
  }

  // Check if message includes a provider name and make it more user-friendly
  let formattedMessage = message;
  if (message && message.includes("google.com")) {
    formattedMessage = message.replace("google.com", "Google");
  }

  // Check if the message suggests using Google sign-in
  const hasGoogleSignInCall = formattedMessage
    .toLowerCase()
    .includes("sign in with google");

  return (
    <div className={`${bgColor} border-l-4 ${borderColor} p-4 mb-4`}>
      <div className="flex">
        {showIcon && <div className="flex-shrink-0">{icon}</div>}
        <div className="ml-3">
          <p className={`${textColor} ${hasGoogleSignInCall ? "mb-3" : ""}`}>
            {formattedMessage}
          </p>

          {/* If the message suggests using Google sign-in, add a helpful button */}
          {hasGoogleSignInCall && (
            <div className="mt-2">
              <button
                type="button"
                onClick={() =>
                  document
                    .querySelector('[data-testid="google-signin-button"]')
                    ?.click()
                }
                className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <img
                  className="h-4 w-4 mr-2"
                  src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                  alt="Google logo"
                />
                Sign in with Google
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AuthErrorMessage;
