import React from "react";

export const Input = React.forwardRef(
  (
    {
      label,
      id,
      error,
      helperText,
      className = "",
      fullWidth = true,
      leadingIcon: LeadingIcon,
      trailingIcon: TrailingIcon,
      type = "text",
      ...props
    },
    ref
  ) => {
    const uniqueId = id || `input-${Math.random().toString(36).substr(2, 9)}`;

    return (
      <div className={`${fullWidth ? "w-full" : ""} ${className}`}>
        {label && (
          <label
            htmlFor={uniqueId}
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            {label}
          </label>
        )}
        <div className="relative rounded-md shadow-sm">
          {LeadingIcon && (
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <LeadingIcon className="h-5 w-5 text-gray-400" />
            </div>
          )}
          <input
            ref={ref}
            type={type}
            id={uniqueId}
            className={`
              block rounded-md focus:outline-none sm:text-sm
              ${fullWidth ? "w-full" : ""}
              ${LeadingIcon ? "pl-10" : ""}
              ${TrailingIcon ? "pr-10" : ""}
              ${
                error
                  ? "border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500"
                  : "border-gray-300 focus:ring-primary-500 focus:border-primary-500"
              }
            `}
            aria-invalid={error ? "true" : "false"}
            {...props}
          />
          {TrailingIcon && (
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <TrailingIcon className="h-5 w-5 text-gray-400" />
            </div>
          )}
        </div>
        {(error || helperText) && (
          <p
            className={`mt-1 text-sm ${
              error ? "text-red-600" : "text-gray-500"
            }`}
          >
            {error || helperText}
          </p>
        )}
      </div>
    );
  }
);
