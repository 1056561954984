// src/contexts/GroupContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import api from "../utils/api";
import { useAuth } from "./AuthContext";
import { getAuth } from "firebase/auth";

const GroupContext = createContext({});

export const useGroup = () => useContext(GroupContext);

// User onboarding status enum
const USER_STATUS = {
  LOADING: "loading", // Initial loading state
  NEEDS_PROFILE: "profile", // User needs to create a profile
  NEEDS_GROUP: "group", // User needs to create/select a group
  MULTIPLE_GROUPS: "groups", // User has multiple groups and needs to select one
  COMPLETE: "complete", // User has profile and active group
};

export const GroupProvider = ({ children }) => {
  const [userGroups, setUserGroups] = useState([]);
  const [activeGroup, setActiveGroup] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const [userStatus, setUserStatus] = useState(USER_STATUS.LOADING);
  const [redirectNeeded, setRedirectNeeded] = useState(null);
  const { user } = useAuth();

  // This effect runs once when the component mounts or when user changes
  // It orchestrates the sequential checks needed for user onboarding
  useEffect(() => {
    const checkUserStatus = async () => {
      if (!user) {
        // User not logged in, reset everything
        setUserGroups([]);
        setActiveGroup(null);
        setUserProfile(null);
        setUserStatus(USER_STATUS.LOADING);
        setLoading(false);
        return;
      }

      // Start loading process
      setLoading(true);
      setUserStatus(USER_STATUS.LOADING);

      try {
        // Step 1: Check if user has a profile
        console.log("Checking user profile...");
        const profileResponse = await api.get("/api/profiles/me");
        setUserProfile(profileResponse.data);

        // Step 2: Only if they have a profile, check their groups
        console.log("Fetching user groups...");
        const groupsResponse = await api.get("/api/my-groups");
        const groups = groupsResponse.data;
        setUserGroups(groups);

        // Step 3: Determine user status based on profile and groups
        if (!profileResponse.data) {
          console.log("User needs to create a profile");
          setUserStatus(USER_STATUS.NEEDS_PROFILE);
        } else if (groups.length === 0) {
          console.log("User needs to create a group");
          setUserStatus(USER_STATUS.NEEDS_GROUP);
        } else if (groups.length === 1) {
          console.log("User has exactly one group, auto-selecting");
          // Auto-select the only group
          setActiveGroup(groups[0]);
          localStorage.setItem("activeGroupId", groups[0].groupID.toString());
          setUserStatus(USER_STATUS.COMPLETE);
        } else {
          console.log("User has multiple groups");
          // Check if there's a saved active group
          const savedGroupId = localStorage.getItem("activeGroupId");
          if (savedGroupId) {
            const savedGroup = groups.find(
              (group) => group.groupID.toString() === savedGroupId
            );
            if (savedGroup) {
              setActiveGroup(savedGroup);
              setUserStatus(USER_STATUS.COMPLETE);
            } else {
              // Saved group not found among user's groups
              setUserStatus(USER_STATUS.MULTIPLE_GROUPS);
            }
          } else {
            // No active group selected
            setUserStatus(USER_STATUS.MULTIPLE_GROUPS);
          }
        }
      } catch (err) {
        console.error("Error checking user status:", err);

        // Check specific error types to determine status
        if (err.response && err.response.status === 404) {
          // 404 on profile endpoint means profile doesn't exist
          setUserStatus(USER_STATUS.NEEDS_PROFILE);
        } else {
          setError("Failed to load profile or groups data");
          // Keep previous status or set to loading
          setUserStatus(USER_STATUS.LOADING);
        }
      } finally {
        setLoading(false);
      }
    };

    checkUserStatus();
  }, [user]);

  // This effect determines where the user should be redirected based on their status
  useEffect(() => {
    if (loading) return;

    // Skip redirect logic if we're on certain URLs
    const currentPath = window.location.pathname;
    const bypassPaths = [
      "/login",
      "/create-account",
      "/verify-email",
      "/forgot-password",
    ];

    if (bypassPaths.some((path) => currentPath.startsWith(path))) {
      setRedirectNeeded(null);
      return;
    }

    // Set redirects based on user status
    switch (userStatus) {
      case USER_STATUS.NEEDS_PROFILE:
        // Allow profile creation page but nowhere else
        if (currentPath !== "/profile-setup") {
          console.log("Redirecting to profile setup");
          setRedirectNeeded("/profile-setup");
        } else {
          setRedirectNeeded(null);
        }
        break;

      case USER_STATUS.NEEDS_GROUP:
        // Allow group creation page but nowhere else
        if (currentPath !== "/create-group") {
          console.log("Redirecting to create group");
          setRedirectNeeded("/create-group");
        } else {
          setRedirectNeeded(null);
        }
        break;

      case USER_STATUS.MULTIPLE_GROUPS:
        // Allow group selection page but nowhere else until a group is selected
        if (currentPath !== "/my-groups") {
          console.log("Redirecting to group selection");
          setRedirectNeeded("/my-groups");
        } else {
          setRedirectNeeded(null);
        }
        break;

      case USER_STATUS.COMPLETE:
      case USER_STATUS.LOADING:
      default:
        // No redirection needed
        setRedirectNeeded(null);
        break;
    }
  }, [userStatus, loading]);

  // Select a group to act as
  const selectGroup = (group) => {
    setActiveGroup(group);
    if (group) {
      localStorage.setItem("activeGroupId", group.groupID.toString());
      setUserStatus(USER_STATUS.COMPLETE);
    } else {
      localStorage.removeItem("activeGroupId");
      if (userGroups.length > 1) {
        setUserStatus(USER_STATUS.MULTIPLE_GROUPS);
      } else if (userGroups.length === 1) {
        // If they only have one group but deselected it, auto-select it again
        setActiveGroup(userGroups[0]);
        localStorage.setItem("activeGroupId", userGroups[0].groupID.toString());
        setUserStatus(USER_STATUS.COMPLETE);
      } else {
        setUserStatus(USER_STATUS.NEEDS_GROUP);
      }
    }
  };

  // Clear active group
  const clearActiveGroup = () => {
    selectGroup(null);
  };

  // Refresh user groups
  const refreshGroups = async () => {
    try {
      setLoading(true);
      const response = await api.get("/api/my-groups");
      setUserGroups(response.data);

      // If active group exists, update it with fresh data
      if (activeGroup) {
        const updatedActiveGroup = response.data.find(
          (group) => group.groupID === activeGroup.groupID
        );
        setActiveGroup(updatedActiveGroup || null);
      }

      // Update user status based on new groups data
      if (response.data.length === 0) {
        setUserStatus(USER_STATUS.NEEDS_GROUP);
      } else if (response.data.length === 1 && !activeGroup) {
        // Auto-select the only group
        setActiveGroup(response.data[0]);
        localStorage.setItem(
          "activeGroupId",
          response.data[0].groupID.toString()
        );
        setUserStatus(USER_STATUS.COMPLETE);
      } else if (response.data.length > 1 && !activeGroup) {
        setUserStatus(USER_STATUS.MULTIPLE_GROUPS);
      } else {
        setUserStatus(USER_STATUS.COMPLETE);
      }

      setLoading(false);
    } catch (err) {
      console.error("Failed to refresh groups:", err);
      setError("Failed to refresh your groups");
      setLoading(false);
    }
  };

  // Refresh the user's token
  const refreshUserToken = async () => {
    try {
      const auth = getAuth();
      if (auth.currentUser) {
        return await auth.currentUser.getIdToken(true);
      }
      return null;
    } catch (error) {
      console.error("Token refresh error:", error);
      throw error;
    }
  };

  const value = {
    userGroups,
    activeGroup,
    loading,
    error,
    selectGroup,
    clearActiveGroup,
    refreshGroups,
    userProfile,
    userStatus,
    redirectNeeded,
  };

  return (
    <GroupContext.Provider value={value}>{children}</GroupContext.Provider>
  );
};

export default GroupProvider;
