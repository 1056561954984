// src/components/MainLayout.js
import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";

/**
 * Main layout component that provides consistent structure for all pages
 * Includes responsive header and sidebar navigation
 */
const MainLayout = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      {/* Header is visible on all pages */}
      <Header />

      <div className="flex flex-1">
        {/* Sidebar is handled responsively within the component */}
        <Sidebar />

        {/* Main Content Area */}
        <main className="flex-1 w-full p-4 sm:p-6 md:p-8 overflow-x-hidden">
          {children}
        </main>
      </div>
    </div>
  );
};

export default MainLayout;
