// src/App.js
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { AuthProvider, useAuth } from "./contexts/AuthContext";
import { GroupProvider } from "./contexts/GroupContext";
import ProtectedRoute from "./components/ProtectedRoute";
import RedirectHandler from "./components/RedirectHandler"; // Import the new component
import LandingPage from "./components/LandingPage";
import Login from "./components/Login";
import CreateAccount from "./components/CreateAccount";
import PasswordReset from "./components/PasswordReset";
import VerifyEmailPage from "./components/VerifyEmailPage";
import Dashboard from "./components/Dashboard";
import MyApplications from "./components/user/MyApplications";
import MyEvents from "./components/user/MyEvents";
import { ProfileSettings } from "./components/user/ProfileSettings";
import { UserManagement } from "./components/admin/UserManagement";
import { EventManagement } from "./components/admin/EventManagement";
import { ApplicationReview } from "./components/admin/ApplicationReview";
import Groups from "./components/Groups";
import MyGroups from "./components/user/MyGroups";
import CreateGroup from "./components/user/CreateGroup"; // Import the new CreateGroup component
import EventDetails from "./components/EventDetails";
import EventForm from "./components/user/EventForm";
import UpcomingEvents from "./components/UpcomingEvents";
import EventView from "./components/EventView";
import ApplicationForm from "./components/ApplicationForm";
import ApplicationConfirmation from "./components/ApplicationConfirmation";
import ApplicationView from "./components/ApplicationView";
import ApplicationPage from "./components/ApplicationPage";
import EventApplications from "./components/EventApplications";
import EventApplicationDetail from "./components/EventApplicationDetail";
import ApplicationMessageInterface from "./components/ApplicationMessageInterface";
import ApplicationGateList from "./components/ApplicationGateList";
import PaymentPage from "./components/payment/PaymentPage";
import PaymentComplete from "./components/payment/PaymentComplete";
import ProfileSetup from "./components/ProfileSetup";
import MainLayout from "./components/MainLayout";
import Header from "./components/Header";
import GroupProfile from "./components/GroupProfile";

// AdminRoute component to protect admin-only routes
const AdminRoute = ({ children }) => {
  const { user } = useAuth();
  const isAdmin = user?.email?.endsWith("@renregister.com");

  if (!isAdmin) {
    return <Navigate to="/dashboard" />;
  }

  return children;
};

// Public page wrapper - only includes header, no sidebar
const PublicLayout = ({ children }) => (
  <div className="flex flex-col min-h-screen bg-gray-100">
    <Header />
    <div className="flex-1">{children}</div>
  </div>
);

// Auth page wrapper - clean layout for login/signup pages
const AuthLayout = ({ children }) => (
  <div className="min-h-screen bg-gray-100">{children}</div>
);

function App() {
  return (
    <AuthProvider>
      <GroupProvider>
        <Router>
          <RedirectHandler />
          <Routes>
            {/* Auth Routes with clean layout */}
            <Route
              path="/login"
              element={
                <AuthLayout>
                  <Login />
                </AuthLayout>
              }
            />
            <Route
              path="/create-account"
              element={
                <AuthLayout>
                  <CreateAccount />
                </AuthLayout>
              }
            />
            <Route
              path="/verify-email"
              element={
                <AuthLayout>
                  <VerifyEmailPage />
                </AuthLayout>
              }
            />
            <Route
              path="/forgot-password"
              element={
                <AuthLayout>
                  <PasswordReset />
                </AuthLayout>
              }
            />

            {/* Public Routes with Header only */}
            <Route
              path="/guilds"
              element={
                <ProtectedRoute
                  requireProfile={false}
                  requireEmailVerification={true}>
                  <Groups type="guild" />
                </ProtectedRoute>
              }
            />
            <Route
              path="/vendors"
              element={
                <ProtectedRoute
                  requireProfile={false}
                  requireEmailVerification={true}>
                  <Groups type="vendor" />
                </ProtectedRoute>
              }
            />
            <Route
              path="/stage-acts"
              element={
                <ProtectedRoute
                  requireProfile={false}
                  requireEmailVerification={true}>
                  <Groups type="act" />
                </ProtectedRoute>
              }
            />
            <Route
              path="/"
              element={
                <PublicLayout>
                  <LandingPage />
                </PublicLayout>
              }
            />

            <Route
              path="/profile-setup"
              element={
                <ProtectedRoute
                  requireProfile={false}
                  requireEmailVerification={true}
                >
                  <MainLayout>
                    <ProfileSetup />
                  </MainLayout>
                </ProtectedRoute>
              }
            />

            {/* Protected User Routes with full layout */}
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute requireEmailVerification={true}>
                  <MainLayout>
                    <Dashboard />
                  </MainLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/groups/view/:groupId"
              element={
                <ProtectedRoute requireEmailVerification={true}>
                  <MainLayout>
                    <GroupProfile />
                  </MainLayout>
                </ProtectedRoute>
              }
            />

            <Route
              path="/my-groups"
              element={
                <ProtectedRoute requireEmailVerification={true}>
                  <MainLayout>
                    <MyGroups />
                  </MainLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/create-group"
              element={
                <ProtectedRoute requireEmailVerification={true}>
                  <MainLayout>
                    <CreateGroup />
                  </MainLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/my-applications"
              element={
                <ProtectedRoute requireEmailVerification={true}>
                  <MainLayout>
                    <MyApplications />
                  </MainLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/applications/view/:applicationId"
              element={
                <ProtectedRoute requireEmailVerification={true}>
                  <MainLayout>
                    <ApplicationView />
                  </MainLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/applications/gatelist/:applicationId"
              element={
                <ProtectedRoute requireEmailVerification={true}>
                  <MainLayout>
                    <ApplicationPage />
                  </MainLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/my-events"
              element={
                <ProtectedRoute requireEmailVerification={true}>
                  <MainLayout>
                    <MyEvents />
                  </MainLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/my-events/create"
              element={
                <ProtectedRoute requireEmailVerification={true}>
                  <MainLayout>
                    <EventForm mode="create" />
                  </MainLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/my-events/edit/:eventId"
              element={
                <ProtectedRoute requireEmailVerification={true}>
                  <MainLayout>
                    <EventForm mode="edit" />
                  </MainLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/my-events/view/:eventId"
              element={
                <ProtectedRoute requireEmailVerification={true}>
                  <MainLayout>
                    <EventDetails />
                  </MainLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/my-events/duplicate/:eventId"
              element={
                <ProtectedRoute requireEmailVerification={true}>
                  <MainLayout>
                    <EventForm mode="duplicate" />
                  </MainLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/my-profile"
              element={
                <ProtectedRoute requireEmailVerification={true}>
                  <MainLayout>
                    <ProfileSettings />
                  </MainLayout>
                </ProtectedRoute>
              }
            />

            {/* Protected Admin Routes */}
            <Route
              path="/admin/users"
              element={
                <ProtectedRoute requireEmailVerification={true}>
                  <AdminRoute>
                    <MainLayout>
                      <UserManagement />
                    </MainLayout>
                  </AdminRoute>
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/events"
              element={
                <ProtectedRoute requireEmailVerification={true}>
                  <AdminRoute>
                    <MainLayout>
                      <EventManagement />
                    </MainLayout>
                  </AdminRoute>
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/applications"
              element={
                <ProtectedRoute requireEmailVerification={true}>
                  <AdminRoute>
                    <MainLayout>
                      <ApplicationReview />
                    </MainLayout>
                  </AdminRoute>
                </ProtectedRoute>
              }
            />
            <Route
              path="/events"
              element={
                <ProtectedRoute requireEmailVerification={true}>
                  <MainLayout>
                    <UpcomingEvents />
                  </MainLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/events/view/:eventId"
              element={
                <ProtectedRoute requireEmailVerification={true}>
                  <MainLayout>
                    <EventView />
                  </MainLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/events/:eventId/applications"
              element={
                <ProtectedRoute requireEmailVerification={true}>
                  <MainLayout>
                    <EventApplications />
                  </MainLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/events/:eventId/applications/:applicationId"
              element={
                <ProtectedRoute requireEmailVerification={true}>
                  <MainLayout>
                    <EventApplicationDetail />
                  </MainLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/applications/create/:eventId"
              element={
                <ProtectedRoute requireEmailVerification={true}>
                  <MainLayout>
                    <ApplicationForm />
                  </MainLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/applications/confirmation/:applicationId"
              element={
                <ProtectedRoute requireEmailVerification={true}>
                  <MainLayout>
                    <ApplicationConfirmation />
                  </MainLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/applications/payment/:applicationId"
              element={
                <ProtectedRoute requireEmailVerification={true}>
                  <MainLayout>
                    <PaymentPage />
                  </MainLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/applications/payment-complete/:applicationId"
              element={
                <ProtectedRoute requireEmailVerification={true}>
                  <MainLayout>
                    <PaymentComplete />
                  </MainLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/events/:eventId/messages/:applicationId"
              element={
                <ProtectedRoute requireEmailVerification={true}>
                  <MainLayout>
                    <ApplicationMessageInterface />
                  </MainLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/events/:eventId/gatelist/:applicationId"
              element={
                <ProtectedRoute requireEmailVerification={true}>
                  <MainLayout>
                    <ApplicationGateList />
                  </MainLayout>
                </ProtectedRoute>
              }
            />

            {/* Catch all route - redirect to home */}
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="*" element={<Navigate to="/dashboard" />} />
          </Routes>
        </Router>
      </GroupProvider>
    </AuthProvider>
  );
}

export default App;
