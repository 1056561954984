// src/components/user/GroupForm.js
import React, { useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import api from "../../utils/api";
import { Input } from "../ui/Input";
import { Button } from "../ui/Button";
import {
  Users,
  Store,
  Theater,
  Castle,
  Globe,
  Mail,
  Phone,
  FileText,
  Tent,
  Save,
  MapPin,
} from "lucide-react";

const GroupForm = ({ group = {}, onSave, isEdit = false, loading = false }) => {
  const { user } = useAuth();
  const [userProfile, setUserProfile] = useState(null);
  const [loadingProfile, setLoadingProfile] = useState(false);

  const [formData, setFormData] = useState({
    class: "guild",
    group_name: "",
    group_website: "",
    description: "",
    contact_name: "",
    email: "",
    contact_phone: "",
    zip: "",
    v_needWater: false,
    v_needPower: false,
    v_notes: "",
    v_ca_resaleNumber: "",
    howmany: "",
    stage_req: "No",
    stage_time: "",
    stage_no: "",
    camp_req: "No",
    frontage: "",
    depth: "",
    haybale_req: "No",
    haybale_no: "",
  });

  // Fetch user profile to auto-fill contact information
  useEffect(() => {
    const fetchUserProfile = async () => {
      if (!isEdit) {
        try {
          setLoadingProfile(true);
          const response = await api.get("/api/profiles/me");
          setUserProfile(response.data);

          // Auto-populate contact fields from user profile
          setFormData((prev) => ({
            ...prev,
            contact_name: response.data.displayName || response.data.name || "",
            email: response.data.email || user?.email || "",
            contact_phone: response.data.phone || "",
            zip: response.data.zip || "",
          }));
        } catch (err) {
          console.error("Error fetching user profile:", err);
        } finally {
          setLoadingProfile(false);
        }
      }
    };

    fetchUserProfile();
  }, [isEdit, user]);

  // Load initial data if editing
  useEffect(() => {
    if (isEdit && group) {
      setFormData({
        ...group,
        // Convert string "true"/"false" to boolean if needed
        v_needWater:
          typeof group.v_needWater === "string"
            ? group.v_needWater === "true" || group.v_needWater === "1"
            : Boolean(group.v_needWater),
        v_needPower:
          typeof group.v_needPower === "string"
            ? group.v_needPower === "true" || group.v_needPower === "1"
            : Boolean(group.v_needPower),
      });
    }
  }, [group, isEdit]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Convert boolean values to bit for database
    const dataToSubmit = {
      ...formData,
      v_needWater: formData.v_needWater ? 1 : 0,
      v_needPower: formData.v_needPower ? 1 : 0,
    };

    onSave(dataToSubmit);
  };

  const groupTypeOptions = [
    { value: "guild", label: "Guild/Troupe", icon: Castle },
    { value: "vendor", label: "Vendor/Merchant", icon: Store },
    { value: "act", label: "Stage Act/Performer", icon: Theater },
    { value: "clan", label: "Modern Scottish/Irish Clan", icon: Users },
    { value: "faire", label: "Event Production Group", icon: Tent },
  ];

  // Determine if this is for a vendor
  const isVendor = formData.class === "vendor";
  // Determine if this is for a performer/act
  const isPerformer = formData.class === "act";
  // Determine if this is for a faire producer
  const isFaire = formData.class === "faire";

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {/* Basic Information Section */}
      <div className="bg-gray-50 p-4 rounded-lg border border-gray-200 mb-6">
        <h3 className="text-lg font-medium text-gray-800 mb-4">
          Basic Information
        </h3>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Group Type */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Group Type*
            </label>
            <select
              name="class"
              value={formData.class}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-md focus:ring-primary-500 focus:border-primary-500"
              required
            >
              {groupTypeOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          {/* Group Name */}
          <Input
            label="Group Name*"
            id="group_name"
            name="group_name"
            value={formData.group_name}
            onChange={handleChange}
            required
            placeholder="Enter your group's name"
          />
        </div>

        <div className="mt-4">
          {/* Description */}
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Public Description*
          </label>
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            rows={4}
            className="w-full px-3 py-2 border rounded-md focus:ring-primary-500 focus:border-primary-500"
            required
            placeholder="Describe your group as you would want it to appear in an event program"
          ></textarea>
          <p className="text-sm text-gray-500 mt-1">
            This description will be used in event programs and promotional
            materials.
          </p>
        </div>

        {/* Website */}
        <div className="mt-4">
          <Input
            label="Website*"
            id="group_website"
            name="group_website"
            type="url"
            value={formData.group_website}
            onChange={handleChange}
            placeholder="https://example.com"
            leadingIcon={Globe}
            helperText="Enter your group's website or social media page"
            required
          />
        </div>
      </div>

      {/* Contact Information Section */}
      <div className="bg-gray-50 p-4 rounded-lg border border-gray-200 mb-6">
        <h3 className="text-lg font-medium text-gray-800 mb-4">
          Contact Information
        </h3>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Contact Name */}
          <Input
            label="Contact Name*"
            id="contact_name"
            name="contact_name"
            value={formData.contact_name}
            onChange={handleChange}
            required
            placeholder="Primary contact person"
          />

          {/* Email */}
          <Input
            label="Email*"
            id="email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            required
            leadingIcon={Mail}
            placeholder="contact@example.com"
          />

          {/* Phone */}
          <Input
            label="Phone Number"
            id="contact_phone"
            name="contact_phone"
            value={formData.contact_phone}
            onChange={handleChange}
            leadingIcon={Phone}
            placeholder="(555) 555-5555"
          />

          {/* ZIP Code */}
          <Input
            label="ZIP Code (Home Area)*"
            id="zip"
            name="zip"
            value={formData.zip}
            onChange={handleChange}
            leadingIcon={MapPin}
            placeholder="Where your group is based"
            required
          />
        </div>
      </div>

      {/* Vendor-specific fields */}
      {isVendor && (
        <div className="bg-gray-50 p-4 rounded-lg border border-gray-200 mb-6">
          <h3 className="text-lg font-medium text-gray-800 mb-4">
            Vendor Information
          </h3>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* CA Resale Number */}
            <Input
              label="CA Resale Number*"
              id="v_ca_resaleNumber"
              name="v_ca_resaleNumber"
              value={formData.v_ca_resaleNumber}
              onChange={handleChange}
              required={isVendor}
              placeholder="Enter your CA resale number"
            />

            {/* Number of Staff */}
            <Input
              label="Number of Staff*"
              id="howmany"
              name="howmany"
              type="number"
              value={formData.howmany}
              onChange={handleChange}
              required={isVendor}
              placeholder="How many people will usually attend"
              helperText="This helps with gate list planning"
            />
          </div>
        </div>
      )}

      {/* Performer-specific fields */}
      {isPerformer && (
        <div className="bg-gray-50 p-4 rounded-lg border border-gray-200 mb-6">
          <h3 className="text-lg font-medium text-gray-800 mb-4">
            Performance Information
          </h3>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
            {/* Number of Performers */}
            <Input
              label="Number of Performers*"
              id="howmany"
              name="howmany"
              type="number"
              value={formData.howmany}
              onChange={handleChange}
              required={isPerformer}
              placeholder="Total number of performers"
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Do you need stage time?*
            </label>
            <div className="flex space-x-4">
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  name="stage_req"
                  value="Yes"
                  checked={formData.stage_req === "Yes"}
                  onChange={handleChange}
                  className="mr-2 focus:ring-primary-500 text-primary-600"
                />
                Yes
              </label>
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  name="stage_req"
                  value="No"
                  checked={formData.stage_req === "No"}
                  onChange={handleChange}
                  className="mr-2 focus:ring-primary-500 text-primary-600"
                />
                No
              </label>
            </div>
          </div>

          {formData.stage_req === "Yes" && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Stage Time */}
              <Input
                label="Minutes Per Performance*"
                id="stage_time"
                name="stage_time"
                type="number"
                value={formData.stage_time}
                onChange={handleChange}
                required={formData.stage_req === "Yes"}
                placeholder="Length of each performance in minutes"
              />

              {/* Number of Performances */}
              <Input
                label="Performances Per Day"
                id="stage_no"
                name="stage_no"
                type="number"
                value={formData.stage_no}
                onChange={handleChange}
                placeholder="How many shows per day"
              />
            </div>
          )}
        </div>
      )}

      {/* Camp Requirements - for all groups except faire producers */}
      {!isFaire && (
        <div className="bg-gray-50 p-4 rounded-lg border border-gray-200 mb-6">
          <h3 className="text-lg font-medium text-gray-800 mb-4">
            Site Requirements
          </h3>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-3">
                Utilities Needed
              </label>
              <div className="space-y-2">
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    name="v_needWater"
                    checked={formData.v_needWater}
                    onChange={handleChange}
                    className="mr-2 h-4 w-4 focus:ring-primary-500 text-primary-600 border-gray-300 rounded"
                  />
                  Water Access Required
                </label>
                <div className="block">
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      name="v_needPower"
                      checked={formData.v_needPower}
                      onChange={handleChange}
                      className="mr-2 h-4 w-4 focus:ring-primary-500 text-primary-600 border-gray-300 rounded"
                    />
                    Electricity Required
                  </label>
                </div>
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Do you need a designated camp space?*
              </label>
              <div className="flex space-x-4">
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    name="camp_req"
                    value="Yes"
                    checked={formData.camp_req === "Yes"}
                    onChange={handleChange}
                    className="mr-2 focus:ring-primary-500 text-primary-600"
                  />
                  Yes
                </label>
                <label className="inline-flex items-center">
                  <input
                    type="radio"
                    name="camp_req"
                    value="No"
                    checked={formData.camp_req === "No"}
                    onChange={handleChange}
                    className="mr-2 focus:ring-primary-500 text-primary-600"
                  />
                  No
                </label>
              </div>
            </div>
          </div>

          {/* Space dimensions (conditional) */}
          {formData.camp_req === "Yes" && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-4">
              <Input
                label="Frontage (feet)*"
                id="frontage"
                name="frontage"
                type="number"
                value={formData.frontage}
                onChange={handleChange}
                required={formData.camp_req === "Yes"}
                placeholder="Width of space needed"
                helperText="Width across the front of your space"
              />
              <Input
                label="Depth (feet)*"
                id="depth"
                name="depth"
                type="number"
                value={formData.depth}
                onChange={handleChange}
                required={formData.camp_req === "Yes"}
                placeholder="Depth of space needed"
                helperText="Distance from front to back"
              />
            </div>
          )}

          {/* Hay Bale Requirements */}
          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Do you need hay bales?
            </label>
            <div className="flex space-x-4">
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  name="haybale_req"
                  value="Yes"
                  checked={formData.haybale_req === "Yes"}
                  onChange={handleChange}
                  className="mr-2 focus:ring-primary-500 text-primary-600"
                />
                Yes
              </label>
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  name="haybale_req"
                  value="No"
                  checked={formData.haybale_req === "No"}
                  onChange={handleChange}
                  className="mr-2 focus:ring-primary-500 text-primary-600"
                />
                No
              </label>
            </div>
          </div>

          {formData.haybale_req === "Yes" && (
            <div className="mt-4">
              <Input
                label="Number of Hay Bales*"
                id="haybale_no"
                name="haybale_no"
                type="number"
                value={formData.haybale_no}
                onChange={handleChange}
                required={formData.haybale_req === "Yes"}
                placeholder="How many hay bales needed"
              />
            </div>
          )}

          {/* Additional Notes */}
          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Additional Notes for Event Producers
            </label>
            <textarea
              name="v_notes"
              value={formData.v_notes}
              onChange={handleChange}
              rows={3}
              className="w-full px-3 py-2 border rounded-md focus:ring-primary-500 focus:border-primary-500"
              placeholder="Add any other information that event producers should know about your needs"
            ></textarea>
          </div>
        </div>
      )}

      {/* Submit Button */}
      <div className="flex justify-end pt-4">
        <Button
          type="submit"
          variant="primary"
          icon={Save}
          loading={loading}
          disabled={loading}
        >
          {isEdit ? "Update Group" : "Create Group"}
        </Button>
      </div>
    </form>
  );
};

export default GroupForm;
