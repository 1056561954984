// src/components/user/MyGroups.js
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import api from "../../utils/api";
import { useAuth } from "../../contexts/AuthContext";
import { useGroup } from "../../contexts/GroupContext";
import {
  Eye,
  Edit,
  Trash2,
  Plus,
  Users,
  Store,
  Theater,
  Castle,
  X,
} from "lucide-react";
import { Card, CardHeader, CardContent, CardFooter } from "../ui/Card";
import { Button } from "../ui/Button";
import { Alert } from "../ui/Alert";
import GroupForm from "./GroupForm";

const MyGroups = () => {
  const { user } = useAuth();
  const { userGroups, activeGroup, selectGroup, refreshGroups } = useGroup();
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const handleCreateClick = () => {
    navigate("/create-group");
  };

  const handleEditClick = (group) => {
    setSelectedGroup(group);
    setShowEditModal(true);
  };

  const handleDeleteClick = (group) => {
    setSelectedGroup(group);
    setShowDeleteConfirm(true);
  };

  const handleDeleteConfirm = async () => {
    setDeleteLoading(true);
    setError(null);

    try {
      await api.delete(`/api/my-groups/${selectedGroup.groupID}`);

      // If we're deleting the active group, clear it
      if (activeGroup && activeGroup.groupID === selectedGroup.groupID) {
        selectGroup(null);
      }

      // Refresh the groups list
      await refreshGroups();

      setShowDeleteConfirm(false);
    } catch (err) {
      console.error("Error deleting group:", err);
      setError(err.response?.data?.error || "Failed to delete group");
    } finally {
      setDeleteLoading(false);
    }
  };

  const handleCloseModal = () => {
    setShowEditModal(false);
    setSelectedGroup(null);
  };

  const handleSaveGroup = async (formData) => {
    try {
      await api.put(`/api/groups/${selectedGroup.groupID}`, formData);

      // Refresh groups
      await refreshGroups();

      // Close modal
      handleCloseModal();
    } catch (err) {
      console.error("Error updating group:", err);
      setError(err.response?.data?.error || "Failed to update group");
    }
  };

  const getGroupTypeIcon = (groupClass) => {
    switch (groupClass) {
      case "guild":
        return <Castle className="h-6 w-6 text-secondary-500" />;
      case "vendor":
        return <Store className="h-6 w-6 text-accent-500" />;
      case "act":
        return <Theater className="h-6 w-6 text-renaissance-burgundy" />;
      case "faire":
        return <Users className="h-6 w-6 text-renaissance-navy" />;
      default:
        return <Users className="h-6 w-6 text-gray-500" />;
    }
  };

  const getGroupTypeLabel = (groupClass) => {
    switch (groupClass) {
      case "guild":
        return "Guild/Troupe";
      case "vendor":
        return "Vendor/Merchant";
      case "act":
        return "Stage Act/Performer";
      case "faire":
        return "Event Producer";
      default:
        return groupClass;
    }
  };

  return (
    <main className="max-w-6xl mx-auto p-4 sm:p-6 md:p-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-900">My Groups</h1>
        <Button variant="primary" onClick={handleCreateClick} icon={Plus}>
          Add New Group
        </Button>
      </div>

      {error && (
        <Alert variant="error" title="Error" message={error} className="mb-6" />
      )}

      <Card className="mb-8">
        <CardHeader
          title="Your Groups"
          subtitle={`You have ${userGroups.length} group${
            userGroups.length !== 1 ? "s" : ""
          }`}
          icon={Users}
        />
        <CardContent>
          {userGroups.length === 0 ? (
            <div className="p-8 text-center">
              <Users className="mx-auto h-12 w-12 text-gray-400" />
              <h3 className="mt-2 text-lg font-medium text-gray-900">
                No groups yet
              </h3>
              <p className="mt-1 text-gray-500">
                Get started by creating your first group.
              </p>
              <div className="mt-6">
                <Button
                  variant="primary"
                  onClick={handleCreateClick}
                  icon={Plus}
                >
                  Create New Group
                </Button>
              </div>
            </div>
          ) : (
            <div className="overflow-hidden">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Group
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Type
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {userGroups.map((group) => (
                    <tr
                      key={group.groupID}
                      className={
                        activeGroup?.groupID === group.groupID
                          ? "bg-primary-50"
                          : ""
                      }
                    >
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="flex-shrink-0">
                            {getGroupTypeIcon(group.class)}
                          </div>
                          <div className="ml-4">
                            <div className="text-sm font-medium text-gray-900">
                              {group.group_name}
                            </div>
                            <div className="text-sm text-gray-500">
                              {group.contact_name}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {getGroupTypeLabel(group.class)}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span
                          className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                            group.validated === "p"
                              ? "bg-yellow-100 text-yellow-800"
                              : group.validated === "y"
                              ? "bg-green-100 text-green-800"
                              : "bg-red-100 text-red-800"
                          }`}
                        >
                          {group.validated === "p"
                            ? "Pending"
                            : group.validated === "y"
                            ? "Approved"
                            : "Rejected"}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                        <div className="flex space-x-2">
                          <Button
                            onClick={() => selectGroup(group)}
                            variant={
                              activeGroup?.groupID === group.groupID
                                ? "primary"
                                : "outline"
                            }
                            size="xs"
                            icon={Eye}
                          >
                            {activeGroup?.groupID === group.groupID
                              ? "Active"
                              : "Use"}
                          </Button>
                          <Button
                            onClick={() => handleEditClick(group)}
                            variant="outline"
                            size="xs"
                            icon={Edit}
                          >
                            Edit
                          </Button>
                          <Button
                            onClick={() => handleDeleteClick(group)}
                            variant="danger"
                            size="xs"
                            icon={Trash2}
                          >
                            Delete
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </CardContent>
      </Card>

      {/* Edit Group Modal */}
      {showEditModal && selectedGroup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg max-w-4xl w-full max-h-[90vh] overflow-y-auto p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-bold">Edit Group</h2>
              <button
                onClick={handleCloseModal}
                className="text-gray-500 hover:text-gray-700"
              >
                <X className="h-6 w-6" />
              </button>
            </div>

            {error && (
              <Alert
                variant="error"
                title="Error"
                message={error}
                className="mb-4"
              />
            )}

            <GroupForm
              group={selectedGroup}
              onSave={handleSaveGroup}
              isEdit={true}
            />
          </div>
        </div>
      )}

      {/* Delete Confirmation Modal */}
      {showDeleteConfirm && selectedGroup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg max-w-md w-full p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-4">
              Delete Group
            </h3>
            <p className="text-gray-500 mb-6">
              Are you sure you want to delete "{selectedGroup.group_name}"? This
              action cannot be undone.
            </p>
            <div className="flex justify-end space-x-3">
              <Button
                variant="outline"
                onClick={() => setShowDeleteConfirm(false)}
                disabled={deleteLoading}
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                onClick={handleDeleteConfirm}
                loading={deleteLoading}
                disabled={deleteLoading}
              >
                Delete
              </Button>
            </div>
          </div>
        </div>
      )}
    </main>
  );
};

export default MyGroups;
