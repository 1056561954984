// client/src/components/ApplicationView.js
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MainLayout from "./MainLayout";
import api from "../utils/api";
import { format } from "date-fns";
import { useAuth } from "../contexts/AuthContext";
import ApplicationStatusBadge from "./ApplicationStatusBadge";
import ApplicationMessages from "./ApplicationMessages";
import GateListForm from "./GateListForm";
import {
  APPLICATION_STATUS,
  getStatusDescription,
  canCancelApplication,
  needsPayment,
  willBePaid,
  needsGateList,
  needsTermsAcceptance,
} from "../utils/applicationStatusUtils";
import {
  Calendar,
  ArrowLeft,
  FileText,
  AlertTriangle,
  CheckCircle,
  XCircle,
  DollarSign,
  MapPin,
  Download,
  Printer,
  ExternalLink,
  CreditCard,
  Users,
  ThumbsUp,
  ThumbsDown,
} from "lucide-react";

const ApplicationView = () => {
  const { applicationId } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [application, setApplication] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [payment, setPayment] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState("details");
  const [acceptingTerms, setAcceptingTerms] = useState(false);
  const [counterOffer, setCounterOffer] = useState("");

  useEffect(() => {
    const fetchApplication = async () => {
      try {
        const response = await api.get(`/api/applications/${applicationId}`);
        setApplication(response.data.application);
        setAnswers(response.data.answers || []);
        setPayment(response.data.payment);
        setError(null);
      } catch (err) {
        console.error("Error fetching application:", err);
        setError("Failed to load application data");
      } finally {
        setLoading(false);
      }
    };

    fetchApplication();
  }, [applicationId]);

  const handleViewEvent = () => {
    if (application) {
      navigate(`/events/view/${application.eventID}`);
    }
  };

  const handlePayNow = () => {
    navigate(`/applications/payment/${applicationId}`);
  };

  const handleCancelApplication = async () => {
    if (!window.confirm("Are you sure you want to cancel this application?")) {
      return;
    }

    try {
      await api.delete(`/api/applications/${applicationId}`);

      // Update the local state
      setApplication({
        ...application,
        status: APPLICATION_STATUS.REMOVED_SELF,
      });

      setActiveTab("details");
    } catch (err) {
      console.error("Error cancelling application:", err);
      alert(
        "Failed to cancel application. " + (err.response?.data?.error || "")
      );
    }
  };

  const handleAcceptTerms = async () => {
    try {
      await api.post(`/api/applications/${applicationId}/accept-terms`);

      // Refresh the application data
      const response = await api.get(`/api/applications/${applicationId}`);
      setApplication(response.data.application);
      setAcceptingTerms(false);
      setActiveTab("details");
    } catch (err) {
      console.error("Error accepting terms:", err);
      alert("Failed to accept terms. " + (err.response?.data?.error || ""));
    }
  };

  const handleCounterOffer = async () => {
    if (!counterOffer.trim()) {
      alert("Please enter a counter offer amount");
      return;
    }

    try {
      await api.post(`/api/applications/${applicationId}/counter-offer`, {
        amount: parseFloat(counterOffer),
      });

      // Refresh the application data
      const response = await api.get(`/api/applications/${applicationId}`);
      setApplication(response.data.application);
      setCounterOffer("");
      setAcceptingTerms(false);
      setActiveTab("details");
    } catch (err) {
      console.error("Error submitting counter offer:", err);
      alert(
        "Failed to submit counter offer. " + (err.response?.data?.error || "")
      );
    }
  };

  const handleGateListSubmitted = async () => {
    try {
      // Refresh the application data
      const response = await api.get(`/api/applications/${applicationId}`);
      setApplication(response.data.application);
      setActiveTab("details");
    } catch (err) {
      console.error("Error refreshing application:", err);
    }
  };

  // Format date function
  const formatDate = (dateString) => {
    try {
      return format(new Date(dateString), "MMMM d, yyyy");
    } catch (e) {
      return "Invalid date";
    }
  };

  if (loading) {
    return (

      <div className="max-w-4xl mx-auto flex justify-center items-center h-full">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>

    );
  }

  if (error) {
    return (

      <div className="max-w-4xl mx-auto">
        <div className="bg-red-50 border-l-4 border-red-500 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <AlertTriangle className="h-5 w-5 text-red-400" />
            </div>
            <div className="ml-3">
              <p className="text-sm text-red-700">{error}</p>
            </div>
          </div>
        </div>
        <button
          onClick={() => navigate("/my-applications")}
          className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
        >
          <ArrowLeft className="w-5 h-5 mr-2" />
          Back to My Applications
        </button>
      </div>

    );
  }

  if (!application) {
    return (

      <div className="max-w-4xl mx-auto">
        <div className="bg-yellow-50 border-l-4 border-yellow-500 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <AlertTriangle className="h-5 w-5 text-yellow-400" />
            </div>
            <div className="ml-3">
              <p className="text-sm text-yellow-700">
                Application not found
              </p>
            </div>
          </div>
        </div>
        <button
          onClick={() => navigate("/my-applications")}
          className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
        >
          <ArrowLeft className="w-5 h-5 mr-2" />
          Back to My Applications
        </button>
      </div>

    );
  }

  return (

    <div className="max-w-4xl mx-auto">
      {/* Header */}
      <div className="bg-white shadow rounded-lg mb-6">
        <div className="px-6 py-5">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <button
                onClick={() => navigate("/my-applications")}
                className="mr-4 p-2 rounded-full hover:bg-gray-100"
              >
                <ArrowLeft className="w-5 h-5" />
              </button>
              <div>
                <h1 className="text-2xl font-bold text-gray-900">
                  Application #{applicationId}
                </h1>
                <div className="flex items-center mt-1">
                  <p className="text-sm text-gray-500 mr-3">
                    Submitted: {formatDate(application.recDate)}
                  </p>
                  <ApplicationStatusBadge status={application.status} />
                </div>
              </div>
            </div>

            <div className="space-x-2">
              <button
                onClick={handleViewEvent}
                className="inline-flex items-center px-3 py-1.5 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50"
              >
                <ExternalLink className="w-4 h-4 mr-1" />
                View Event
              </button>
              <button
                onClick={() => window.print()}
                className="inline-flex items-center px-3 py-1.5 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50"
              >
                <Printer className="w-4 h-4 mr-1" />
                Print
              </button>
            </div>
          </div>
        </div>

        {/* Tabs */}
        <div className="border-t border-gray-200">
          <nav className="flex -mb-px">
            <button
              onClick={() => setActiveTab("details")}
              className={`${activeTab === "details"
                  ? "border-blue-500 text-blue-600"
                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                } whitespace-nowrap py-4 px-6 border-b-2 font-medium text-sm`}
            >
              Details
            </button>
            <button
              onClick={() => setActiveTab("communication")}
              className={`${activeTab === "communication"
                  ? "border-blue-500 text-blue-600"
                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                } whitespace-nowrap py-4 px-6 border-b-2 font-medium text-sm`}
            >
              Messages
            </button>

            {needsGateList(application.status) && (
              <button
                onClick={() => setActiveTab("gatelist")}
                className={`${activeTab === "gatelist"
                    ? "border-blue-500 text-blue-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                  } whitespace-nowrap py-4 px-6 border-b-2 font-medium text-sm`}
              >
                Submit Gate List
              </button>
            )}

            {needsTermsAcceptance(application.status) && (
              <button
                onClick={() => setActiveTab("terms")}
                className={`${activeTab === "terms"
                    ? "border-blue-500 text-blue-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                  } whitespace-nowrap py-4 px-6 border-b-2 font-medium text-sm`}
              >
                Review Terms
              </button>
            )}
          </nav>
        </div>
      </div>

      {/* Tab Content */}
      {activeTab === "details" && (
        <div className="space-y-6">
          {/* Event and Group Information */}
          <div className="bg-white shadow rounded-lg overflow-hidden">
            <div className="px-6 py-5 border-b border-gray-200">
              <h2 className="text-lg font-medium text-gray-900">
                Event Information
              </h2>
            </div>

            <div className="px-6 py-5">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <h3 className="text-sm font-medium text-gray-500">
                    Event
                  </h3>
                  <p className="mt-1 text-sm text-gray-900">
                    {application.eventName}
                  </p>
                </div>

                <div>
                  <h3 className="text-sm font-medium text-gray-500">
                    Event Date
                  </h3>
                  <div className="mt-1 flex items-center text-sm text-gray-900">
                    <Calendar className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" />
                    {formatDate(application.event_startdate)}
                  </div>
                </div>

                <div>
                  <h3 className="text-sm font-medium text-gray-500">
                    Group
                  </h3>
                  <p className="mt-1 text-sm text-gray-900">
                    {application.group_name}
                  </p>
                </div>

                <div>
                  <h3 className="text-sm font-medium text-gray-500">
                    Group Type
                  </h3>
                  <p className="mt-1 text-sm text-gray-900 capitalize">
                    {application.class}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Application Answers */}
          {answers.length > 0 && (
            <div className="bg-white shadow rounded-lg overflow-hidden">
              <div className="px-6 py-5 border-b border-gray-200">
                <h2 className="text-lg font-medium text-gray-900">
                  Questionnaire Responses
                </h2>
              </div>

              <div className="px-6 py-5">
                <div className="space-y-4">
                  {answers.map((answer) => (
                    <div key={answer.answerid}>
                      <h3 className="text-sm font-medium text-gray-500">
                        {answer.question}
                      </h3>
                      <p className="mt-1 text-sm text-gray-900 whitespace-pre-wrap">
                        {answer.answer}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {/* Special Requests */}
          {application.notes && (
            <div className="bg-white shadow rounded-lg overflow-hidden">
              <div className="px-6 py-5 border-b border-gray-200">
                <h2 className="text-lg font-medium text-gray-900">
                  Special Requests/Notes
                </h2>
              </div>

              <div className="px-6 py-5">
                <p className="text-sm text-gray-900 whitespace-pre-wrap">
                  {application.notes}
                </p>
              </div>
            </div>
          )}

          {/* Payment Information */}
          {needsPayment(application) && (
            <div className="bg-white shadow rounded-lg overflow-hidden">
              <div className="px-6 py-5 border-b border-gray-200">
                <h2 className="text-lg font-medium text-gray-900">
                  Payment Required
                </h2>
              </div>

              <div className="px-6 py-5">
                <div className="flex justify-between items-center">
                  <div>
                    <h3 className="text-sm font-medium text-gray-500">
                      Amount Due
                    </h3>
                    <div className="mt-1">
                      <span className="text-xl font-bold">
                        ${application.pymt.toFixed(2)}
                      </span>
                    </div>
                    <p className="mt-2 text-sm text-gray-500">
                      Please complete your payment to secure your spot at
                      this event.
                    </p>
                  </div>

                  <button
                    onClick={handlePayNow}
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
                  >
                    <CreditCard className="w-4 h-4 mr-1" />
                    Pay Now
                  </button>
                </div>
              </div>
            </div>
          )}

          {/* Performer Payment Information */}
          {willBePaid(application) && (
            <div className="bg-white shadow rounded-lg overflow-hidden">
              <div className="px-6 py-5 border-b border-gray-200">
                <h2 className="text-lg font-medium text-gray-900">
                  Performance Compensation
                </h2>
              </div>

              <div className="px-6 py-5">
                <div className="flex items-center">
                  <div>
                    <h3 className="text-sm font-medium text-gray-500">
                      Agreed Amount
                    </h3>
                    <div className="mt-1">
                      <span className="text-xl font-bold">
                        ${application.pymt.toFixed(2)}
                      </span>
                    </div>
                    <p className="mt-2 text-sm text-gray-500">
                      This is the agreed compensation for your participation
                      at this event. Payment will be processed after the
                      event concludes.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Application Status */}
          <div className="bg-white shadow rounded-lg overflow-hidden">
            <div className="px-6 py-5 border-b border-gray-200">
              <h2 className="text-lg font-medium text-gray-900">
                Application Status
              </h2>
            </div>

            <div className="px-6 py-5">
              <div className="flex justify-between items-center">
                <div>
                  <h3 className="text-sm font-medium text-gray-500">
                    Current Status
                  </h3>
                  <div className="mt-1">
                    <ApplicationStatusBadge status={application.status} />
                  </div>
                </div>

                {canCancelApplication(
                  application.status,
                  application.event_startdate
                ) && (
                    <button
                      onClick={handleCancelApplication}
                      className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700"
                    >
                      <XCircle className="w-4 h-4 mr-1" />
                      Cancel Application
                    </button>
                  )}
              </div>

              {/* Status explanation */}
              <div className="mt-4 p-5 bg-gray-50 rounded-lg border border-gray-200 shadow-sm">
                <div className="flex items-start">
                  {application.status.startsWith("Pending") && (
                    <AlertTriangle className="w-5 h-5 text-yellow-500 mt-0.5 mr-3 flex-shrink-0" />
                  )}
                  {application.status.startsWith("Accepted") && (
                    <CheckCircle className="w-5 h-5 text-green-500 mt-0.5 mr-3 flex-shrink-0" />
                  )}
                  {(application.status.startsWith("Removed") ||
                    application.status === "Declined" ||
                    application.status === "Event Cancelled") && (
                      <XCircle className="w-5 h-5 text-red-500 mt-0.5 mr-3 flex-shrink-0" />
                    )}
                  <div>
                    <h4 className="text-sm font-medium text-gray-900 mb-1">
                      Status Information
                    </h4>
                    <p className="text-sm text-gray-700">
                      {getStatusDescription(
                        application.status,
                        application.class
                      )}
                    </p>
                  </div>
                </div>
              </div>

              {/* Next steps based on status */}
              {application.status === APPLICATION_STATUS.PENDING && (
                <div className="mt-4 p-5 bg-blue-50 rounded-lg border border-blue-100 shadow-sm">
                  <div className="flex items-start">
                    <AlertTriangle className="w-5 h-5 text-blue-500 mt-0.5 mr-3 flex-shrink-0" />
                    <div>
                      <h4 className="text-sm font-medium text-blue-900 mb-1">
                        What's Next?
                      </h4>
                      <p className="text-sm text-blue-700">
                        Your application is being reviewed by the event
                        organizers. Check back later for updates or visit
                        the Messages tab to communicate with the organizers.
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {application.status === APPLICATION_STATUS.PENDING_INFO && (
                <div className="mt-4 p-4 bg-yellow-50 rounded-md">
                  <div className="flex items-start">
                    <AlertTriangle className="w-5 h-5 text-yellow-500 mt-0.5 mr-2 flex-shrink-0" />
                    <div>
                      <p className="text-sm text-yellow-700 font-medium">
                        Additional information required
                      </p>
                      <p className="text-sm text-yellow-700 mt-1">
                        The event organizers need more information. Please
                        check the Messages tab and respond to their
                        questions.
                      </p>
                      <button
                        onClick={() => setActiveTab("communication")}
                        className="mt-2 inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-md text-yellow-700 bg-yellow-100 hover:bg-yellow-200"
                      >
                        Go to Messages
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {application.status ===
                APPLICATION_STATUS.ACCEPTED_PAYMENT && (
                  <div className="mt-4 p-5 bg-green-50 rounded-lg border border-green-100 shadow-sm transform transition-all duration-300 hover:shadow-md">
                    <div className="flex flex-col sm:flex-row items-start">
                      <DollarSign className="w-5 h-5 text-green-500 mt-0.5 mr-3 flex-shrink-0 mb-2 sm:mb-0" />
                      <div className="flex-grow">
                        <h4 className="text-sm font-medium text-green-900 mb-1">
                          Payment Required
                        </h4>
                        <p className="text-sm text-green-700 mb-3">
                          Your application has been approved! Please complete
                          your payment of{" "}
                          <span className="font-semibold">
                            ${application.pymt.toFixed(2)}
                          </span>{" "}
                          to confirm your participation.
                        </p>
                        <button
                          onClick={handlePayNow}
                          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition-colors duration-200"
                        >
                          <DollarSign className="w-4 h-4 mr-2" />
                          Pay Now
                        </button>
                      </div>
                    </div>
                  </div>
                )}

              {application.status ===
                APPLICATION_STATUS.ACCEPTED_GATELIST && (
                  <div className="mt-4 p-4 bg-green-50 rounded-md">
                    <div className="flex items-start">
                      <Users className="w-5 h-5 text-green-500 mt-0.5 mr-2 flex-shrink-0" />
                      <div>
                        <p className="text-sm text-green-700 font-medium">
                          Gate List Required
                        </p>
                        <p className="text-sm text-green-700 mt-1">
                          Please submit your gate list with the names of
                          everyone who will be participating with your group.
                        </p>
                        <button
                          onClick={() =>
                            navigate(
                              `/applications/gatelist/${applicationId}`
                            )
                          }
                          className="mt-2 inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
                        >
                          Submit Gate List
                        </button>
                      </div>
                    </div>
                  </div>
                )}

              {application.status === APPLICATION_STATUS.ACCEPTED_TERMS && (
                <div className="mt-4 p-4 bg-green-50 rounded-md">
                  <div className="flex items-start">
                    <FileText className="w-5 h-5 text-green-500 mt-0.5 mr-2 flex-shrink-0" />
                    <div>
                      <p className="text-sm text-green-700 font-medium">
                        Terms Acceptance Required
                      </p>
                      <p className="text-sm text-green-700 mt-1">
                        The event has proposed a compensation of $
                        {application.pymt.toFixed(2)} for your performance.
                        Please review and accept the terms or propose a
                        counter-offer.
                      </p>
                      <button
                        onClick={() => setActiveTab("terms")}
                        className="mt-2 inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-md text-white bg-green-600 hover:bg-green-700"
                      >
                        Review Terms
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Messages Tab */}
      {activeTab === "communication" && (
        <ApplicationMessages
          applicationId={applicationId}
          eventId={application.eventID}
          groupId={application.groupID}
        />
      )}

      {/* Gate List Tab */}
      {activeTab === "gatelist" && (
        <GateListForm
          applicationId={applicationId}
          eventId={application.eventID}
          groupId={application.groupID}
          onComplete={handleGateListSubmitted}
        />
      )}

      {/* Terms Review Tab */}
      {activeTab === "terms" && (
        <div className="bg-white shadow rounded-lg overflow-hidden">
          <div className="px-6 py-5 border-b border-gray-200">
            <h2 className="text-lg font-medium text-gray-900">
              Review Compensation Terms
            </h2>
          </div>

          <div className="px-6 py-5">
            <div className="mb-6">
              <h3 className="text-base font-medium text-gray-900 mb-2">
                Event Offer
              </h3>
              <div className="p-5 bg-blue-50 rounded-lg border border-blue-100">
                <div className="text-center">
                  <p className="text-sm text-blue-700 mb-2">
                    The event organizer has offered your group a
                    compensation of:
                  </p>
                  <p className="text-3xl font-bold text-blue-900 mb-3">
                    ${application.pymt.toFixed(2)}
                  </p>
                  <p className="text-sm text-blue-700">
                    This compensation is for your participation at{" "}
                    {application.eventName} on{" "}
                    {formatDate(application.event_startdate)}.
                  </p>
                </div>
              </div>
            </div>

            <div className="mt-6 space-y-4">
              {!acceptingTerms ? (
                <div className="flex flex-col sm:flex-row justify-center space-y-3 sm:space-y-0 sm:space-x-4">
                  <button
                    onClick={() => setAcceptingTerms(true)}
                    className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition-colors duration-200"
                  >
                    <ThumbsUp className="w-4 h-4 mr-2" />
                    Accept Terms
                  </button>
                  <button
                    onClick={() => setAcceptingTerms("counter")}
                    className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200"
                  >
                    <ThumbsDown className="w-4 h-4 mr-2" />
                    Counter Offer
                  </button>
                </div>
              ) : acceptingTerms === "counter" ? (
                <div className="border border-gray-200 p-5 rounded-lg shadow-sm bg-gray-50">
                  <h3 className="text-base font-medium text-gray-900 mb-3">
                    Submit Counter Offer
                  </h3>
                  <div className="mb-4">
                    <label
                      htmlFor="counterOffer"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Your Counter Offer Amount ($)
                    </label>
                    <div className="relative rounded-md shadow-sm max-w-xs mx-auto">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <span className="text-gray-500 sm:text-sm">$</span>
                      </div>
                      <input
                        type="number"
                        id="counterOffer"
                        name="counterOffer"
                        value={counterOffer}
                        onChange={(e) => setCounterOffer(e.target.value)}
                        className="focus:ring-blue-500 focus:border-blue-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                        placeholder="0.00"
                        step="0.01"
                        min="0"
                      />
                      <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        <span className="text-gray-500 sm:text-sm">
                          USD
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end space-x-3 mt-6">
                    <button
                      onClick={() => setAcceptingTerms(false)}
                      className="inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors duration-200"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleCounterOffer}
                      className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200"
                    >
                      Submit Counter Offer
                    </button>
                  </div>
                </div>
              ) : (
                <div className="border border-green-200 p-5 rounded-lg shadow-sm bg-green-50">
                  <h3 className="text-base font-medium text-green-900 mb-3 flex items-center">
                    <CheckCircle className="w-5 h-5 mr-2 text-green-500" />
                    Confirm Acceptance
                  </h3>
                  <p className="text-sm text-green-700 mb-4">
                    Are you sure you want to accept this compensation
                    amount? Once accepted, you'll need to submit your gate
                    list to complete the process.
                  </p>
                  <div className="flex justify-end space-x-3 mt-6">
                    <button
                      onClick={() => setAcceptingTerms(false)}
                      className="inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors duration-200"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleAcceptTerms}
                      className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition-colors duration-200"
                    >
                      Confirm Acceptance
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>

  );
};

export default ApplicationView;
