// client/src/utils/applicationStatusUtils.js

/**
 * Application status constants
 */
export const APPLICATION_STATUS = {
  PENDING: "Pending",
  PENDING_INFO: "Pending - Further Information Needed",
  ACCEPTED_PAYMENT: "Accepted - Awaiting Fee Payment",
  ACCEPTED_TERMS: "Accepted - Terms Acceptance Needed",
  ACCEPTED_GATELIST: "Accepted - Awaiting Gatelist",
  ACCEPTED_COMPLETE: "Accepted - Process Complete",
  REMOVED_ADMIN: "Removed - Admin",
  REMOVED_SELF: "Removed - Self",
  DECLINED: "Declined",
  EVENT_CANCELLED: "Event Cancelled",
};

/**
 * Get appropriate CSS class for status badge
 * @param {string} status - Application status
 * @returns {string} CSS class for badge
 */
export const getStatusColor = (status) => {
  if (!status) return "bg-gray-100 text-gray-800";

  if (status.startsWith("Pending")) {
    return "bg-yellow-100 text-yellow-800";
  } else if (status.startsWith("Accepted")) {
    return "bg-green-100 text-green-800";
  } else if (
    status.startsWith("Removed") ||
    status === "Declined" ||
    status === "Event Cancelled"
  ) {
    return "bg-red-100 text-red-800";
  }

  return "bg-blue-100 text-blue-800";
};

/**
 * Get status icon component name based on status
 * @param {string} status - Application status
 * @returns {string} Icon component name
 */
export const getStatusIcon = (status) => {
  if (!status) return "HelpCircle";

  if (status.startsWith("Pending")) {
    return "Clock";
  } else if (status.startsWith("Accepted")) {
    if (status.includes("Payment")) {
      return "DollarSign";
    } else if (status.includes("Terms")) {
      return "FileText";
    } else if (status.includes("Gatelist")) {
      return "Users";
    } else {
      return "CheckCircle";
    }
  } else if (
    status.startsWith("Removed") ||
    status === "Declined" ||
    status === "Event Cancelled"
  ) {
    return "XCircle";
  }

  return "HelpCircle";
};

/**
 * Get human-readable status description
 * @param {string} status - Application status
 * @param {string} groupType - Type of group (vendor, guild, act)
 * @returns {string} Status description
 */
export const getStatusDescription = (status, groupType = "") => {
  switch (status) {
    case APPLICATION_STATUS.PENDING:
      return "Your application is currently under review by the event organizers.";

    case APPLICATION_STATUS.PENDING_INFO:
      return "The event organizers need additional information from you. Please check messages related to this application.";

    case APPLICATION_STATUS.ACCEPTED_PAYMENT:
      return "Your application has been accepted! A fee payment is required to complete your registration.";

    case APPLICATION_STATUS.ACCEPTED_TERMS:
      return "The event has proposed compensation terms for your participation. Please review and respond to their offer.";

    case APPLICATION_STATUS.ACCEPTED_GATELIST:
      return "Please submit your gate list with names of your staff/participants for check-in at the event.";

    case APPLICATION_STATUS.ACCEPTED_COMPLETE:
      return "Your registration is complete! See you at the event.";

    case APPLICATION_STATUS.REMOVED_ADMIN:
      return "This application has been removed by the event administrators.";

    case APPLICATION_STATUS.REMOVED_SELF:
      return "You have withdrawn this application.";

    case APPLICATION_STATUS.DECLINED:
      return "We're sorry, but your application has not been accepted for this event.";

    case APPLICATION_STATUS.EVENT_CANCELLED:
      return "This event has been cancelled by the organizers.";

    default:
      return "Unknown application status.";
  }
};

/**
 * Check if user can cancel/withdraw an application
 * @param {string} status - Application status
 * @param {Date} eventDate - Event date
 * @returns {boolean} Whether application can be cancelled
 */
export const canCancelApplication = (status, eventDate) => {
  // Can't cancel if already removed, declined, or event cancelled
  if (
    status === APPLICATION_STATUS.REMOVED_ADMIN ||
    status === APPLICATION_STATUS.REMOVED_SELF ||
    status === APPLICATION_STATUS.DECLINED ||
    status === APPLICATION_STATUS.EVENT_CANCELLED
  ) {
    return false;
  }

  // Check if event is within 2 weeks
  if (eventDate) {
    const now = new Date();
    const twoWeeksFromNow = new Date(now);
    twoWeeksFromNow.setDate(now.getDate() + 14);

    if (new Date(eventDate) < twoWeeksFromNow) {
      return false; // Too close to event to cancel
    }
  }

  return true;
};

/**
 * Check if user needs to pay a fee for this application
 * @param {object} application - Application data
 * @returns {boolean} Whether payment is needed
 */
export const needsPayment = (application) => {
  if (!application) return false;

  return (
    application.status === APPLICATION_STATUS.ACCEPTED_PAYMENT &&
    application.pymt > 0
  );
};

/**
 * Check if the group will be paid for this event
 * @param {object} application - Application data
 * @returns {boolean} Whether group will be paid
 */
export const willBePaid = (application) => {
  if (!application) return false;

  // For acts/guilds that will be paid by the event
  const isPerformer =
    application.class === "act" || application.class === "guild";
  return (
    isPerformer &&
    application.pymt > 0 &&
    (application.status === APPLICATION_STATUS.ACCEPTED_TERMS ||
      application.status === APPLICATION_STATUS.ACCEPTED_GATELIST ||
      application.status === APPLICATION_STATUS.ACCEPTED_COMPLETE)
  );
};

/**
 * Check if user needs to submit a gate list
 * @param {string} status - Application status
 * @returns {boolean} Whether gate list is needed
 */
export const needsGateList = (status) => {
  return status === APPLICATION_STATUS.ACCEPTED_GATELIST;
};

/**
 * Checks if terms acceptance is needed for this application
 * @param {string} status - Application status
 * @returns {boolean} Whether terms need to be accepted
 */
export const needsTermsAcceptance = (status) => {
  return status === APPLICATION_STATUS.ACCEPTED_TERMS;
};
