// client/src/components/EventApplicationDetail.js
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MainLayout from "./MainLayout";
import api from "../utils/api";
import { format } from "date-fns";
import ApplicationStatusBadge from "./ApplicationStatusBadge";
import {
  ArrowLeft,
  AlertTriangle,
  CheckCircle,
  XCircle,
  MessageSquare,
  DollarSign,
  User,
  Mail,
  Phone,
  MapPin,
  Globe,
  Clipboard,
  Users,
  Calendar,
  Clock,
  Loader,
} from "lucide-react";
import { APPLICATION_STATUS } from "../utils/applicationStatusUtils";

const EventApplicationDetail = () => {
  const { eventId, applicationId } = useParams();
  const navigate = useNavigate();
  const [application, setApplication] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [event, setEvent] = useState(null);
  const [group, setGroup] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [statusChangeLoading, setStatusChangeLoading] = useState(false);
  const [showStatusDropdown, setShowStatusDropdown] = useState(false);
  const [feeAmount, setFeeAmount] = useState("");
  const [showFeeForm, setShowFeeForm] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        // Fetch application data
        const appResponse = await api.get(
          `/api/events/${eventId}/applications/${applicationId}`
        );
        setApplication(appResponse.data.application);
        setAnswers(appResponse.data.answers || []);

        // Fetch event details
        const eventResponse = await api.get(`/api/events/${eventId}`);
        setEvent(eventResponse.data);

        try {
          // Try to fetch group details - but don't fail the entire request if it fails
          const groupResponse = await api.get(
            `/api/admin/groups/${appResponse.data.application.groupID}`
          );
          setGroup(groupResponse.data);
        } catch (groupErr) {
          console.error("Error fetching group details:", groupErr);
          // Still set basic group info from the application data
          setGroup({
            groupID: appResponse.data.application.groupID,
            group_name: appResponse.data.application.group_name,
            class: appResponse.data.application.class,
            contact_name: appResponse.data.application.contact_name,
            email: appResponse.data.application.email,
          });
        }

        setError(null);
      } catch (err) {
        console.error("Error fetching application details:", err);
        setError("Failed to load application details");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [eventId, applicationId]);

  // Format date function
  const formatDate = (dateString) => {
    try {
      return format(new Date(dateString), "MMMM d, yyyy");
    } catch (e) {
      return "Invalid date";
    }
  };

  // Handle status change
  const handleStatusChange = async (newStatus) => {
    try {
      setStatusChangeLoading(true);
      await api.put(
        `/api/events/${eventId}/applications/${applicationId}/status`,
        {
          status: newStatus,
        }
      );

      // Refresh application data
      const appResponse = await api.get(
        `/api/events/${eventId}/applications/${applicationId}`
      );
      setApplication(appResponse.data.application);

      setShowStatusDropdown(false);
    } catch (err) {
      console.error("Error updating application status:", err);
      alert("Failed to update application status");
    } finally {
      setStatusChangeLoading(false);
    }
  };

  // Handle setting fee amount
  const handleSetFee = async () => {
    if (!feeAmount || isNaN(parseFloat(feeAmount))) {
      alert("Please enter a valid fee amount");
      return;
    }

    try {
      await api.put(
        `/api/events/${eventId}/applications/${applicationId}/fee`,
        {
          amount: parseFloat(feeAmount),
        }
      );

      // Refresh application data
      const appResponse = await api.get(
        `/api/events/${eventId}/applications/${applicationId}`
      );
      setApplication(appResponse.data.application);

      setShowFeeForm(false);
      setFeeAmount("");
    } catch (err) {
      console.error("Error setting fee amount:", err);
      alert("Failed to set fee amount");
    }
  };

  if (loading) {
    return (
      <div className="max-w-4xl mx-auto flex justify-center items-center h-full">
        <Loader className="animate-spin h-12 w-12 text-blue-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-4xl mx-auto">
        <div className="bg-red-50 border-l-4 border-red-500 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <AlertTriangle className="h-5 w-5 text-red-400" />
            </div>
            <div className="ml-3">
              <p className="text-sm text-red-700">{error}</p>
            </div>
          </div>
        </div>
        <button
          onClick={() => navigate(`/events/${eventId}/applications`)}
          className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
        >
          <ArrowLeft className="w-5 h-5 mr-2" />
          Back to Applications
        </button>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto">
      {/* Header */}
      <div className="bg-white shadow rounded-lg mb-6">
        <div className="px-6 py-5">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <button
                onClick={() => navigate(`/events/${eventId}/applications`)}
                className="mr-4 p-2 rounded-full hover:bg-gray-100"
              >
                <ArrowLeft className="w-5 h-5" />
              </button>
              <div>
                <h1 className="text-2xl font-bold text-gray-900">
                  Application #{applicationId}
                </h1>
                <div className="flex items-center mt-1">
                  <p className="text-sm text-gray-500 mr-3">
                    Submitted: {formatDate(application.recDate)}
                  </p>
                  <ApplicationStatusBadge status={application.status} />
                </div>
              </div>
            </div>

            <div className="relative">
              <button
                onClick={() => setShowStatusDropdown(!showStatusDropdown)}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
                disabled={statusChangeLoading}
              >
                {statusChangeLoading ? (
                  <>
                    <Loader className="animate-spin w-4 h-4 mr-2" />
                    Updating...
                  </>
                ) : (
                  <>
                    <Clipboard className="w-4 h-4 mr-2" />
                    Change Status
                  </>
                )}
              </button>

              {showStatusDropdown && (
                <div className="origin-top-right absolute right-0 mt-2 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                  <div
                    className="py-1"
                    role="menu"
                    aria-orientation="vertical"
                  >
                    <button
                      onClick={() =>
                        handleStatusChange(APPLICATION_STATUS.PENDING)
                      }
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                    >
                      Pending
                    </button>
                    <button
                      onClick={() =>
                        handleStatusChange(APPLICATION_STATUS.PENDING_INFO)
                      }
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                    >
                      Pending - Further Information Needed
                    </button>
                    <button
                      onClick={() =>
                        handleStatusChange(
                          APPLICATION_STATUS.ACCEPTED_PAYMENT
                        )
                      }
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                    >
                      Accepted - Awaiting Fee Payment
                    </button>
                    <button
                      onClick={() =>
                        handleStatusChange(
                          APPLICATION_STATUS.ACCEPTED_TERMS
                        )
                      }
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                    >
                      Accepted - Terms Acceptance Needed
                    </button>
                    <button
                      onClick={() =>
                        handleStatusChange(
                          APPLICATION_STATUS.ACCEPTED_GATELIST
                        )
                      }
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                    >
                      Accepted - Awaiting Gatelist
                    </button>
                    <button
                      onClick={() =>
                        handleStatusChange(
                          APPLICATION_STATUS.ACCEPTED_COMPLETE
                        )
                      }
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                    >
                      Accepted - Process Complete
                    </button>
                    <button
                      onClick={() =>
                        handleStatusChange(APPLICATION_STATUS.DECLINED)
                      }
                      className="block px-4 py-2 text-sm text-red-700 hover:bg-red-100 w-full text-left"
                    >
                      Declined
                    </button>
                    <button
                      onClick={() =>
                        handleStatusChange(APPLICATION_STATUS.REMOVED_ADMIN)
                      }
                      className="block px-4 py-2 text-sm text-red-700 hover:bg-red-100 w-full text-left"
                    >
                      Removed - Admin
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Action Buttons */}
          <div className="mt-4 flex flex-wrap gap-2">
            <button
              onClick={() =>
                navigate(`/events/${eventId}/messages/${applicationId}`)
              }
              className="inline-flex items-center px-3 py-1.5 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50"
            >
              <MessageSquare className="w-4 h-4 mr-1" />
              Messages
            </button>

            {group && (
              <button
                onClick={() => navigate(`/groups/view/${group.groupID}`)}
                className="inline-flex items-center px-3 py-1.5 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50"
              >
                <User className="w-4 h-4 mr-1" />
                View Group Profile
              </button>
            )}

            {/* Fee button for vendors */}
            {group && group.class === "vendor" && (
              <button
                onClick={() => setShowFeeForm(!showFeeForm)}
                className="inline-flex items-center px-3 py-1.5 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50"
              >
                <DollarSign className="w-4 h-4 mr-1" />
                Set Fee Amount
              </button>
            )}

            {/* View gatelist button */}
            <button
              onClick={() =>
                navigate(`/events/${eventId}/gatelist/${applicationId}`)
              }
              className="inline-flex items-center px-3 py-1.5 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50"
            >
              <Users className="w-4 h-4 mr-1" />
              View Gate List
            </button>
          </div>

          {/* Fee form */}
          {showFeeForm && (
            <div className="mt-4 p-4 border border-gray-200 rounded-lg bg-gray-50">
              <h3 className="text-sm font-medium text-gray-700 mb-2">
                Set Fee Amount
              </h3>
              <div className="flex items-end gap-2">
                <div className="flex-1">
                  <label htmlFor="feeAmount" className="sr-only">
                    Fee Amount
                  </label>
                  <div className="relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <span className="text-gray-500 sm:text-sm">$</span>
                    </div>
                    <input
                      type="number"
                      name="feeAmount"
                      id="feeAmount"
                      className="focus:ring-blue-500 focus:border-blue-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                      placeholder="0.00"
                      step="0.01"
                      min="0"
                      value={feeAmount}
                      onChange={(e) => setFeeAmount(e.target.value)}
                    />
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                      <span className="text-gray-500 sm:text-sm">USD</span>
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  onClick={handleSetFee}
                  className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
                >
                  Set Fee
                </button>
                <button
                  type="button"
                  onClick={() => setShowFeeForm(false)}
                  className="inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Group Information */}
      <div className="bg-white shadow rounded-lg overflow-hidden mb-6">
        <div className="px-6 py-5 border-b border-gray-200">
          <h2 className="text-lg font-medium text-gray-900">
            Group Information
          </h2>
        </div>

        <div className="px-6 py-5">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <h3 className="text-sm font-medium text-gray-500">
                Group Name
              </h3>
              <p className="mt-1 text-sm text-gray-900">
                {group?.group_name}
              </p>
            </div>

            <div>
              <h3 className="text-sm font-medium text-gray-500">
                Group Type
              </h3>
              <p className="mt-1 text-sm text-gray-900 capitalize">
                {group?.class}
              </p>
            </div>

            <div>
              <h3 className="text-sm font-medium text-gray-500">
                Contact Name
              </h3>
              <p className="mt-1 text-sm text-gray-900">
                {group?.contact_name}
              </p>
            </div>

            <div>
              <h3 className="text-sm font-medium text-gray-500">Email</h3>
              <div className="mt-1 flex items-center text-sm text-gray-900">
                <Mail className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" />
                <a
                  href={`mailto:${group?.email}`}
                  className="text-blue-600 hover:underline"
                >
                  {group?.email}
                </a>
              </div>
            </div>

            {group?.contact_phone && (
              <div>
                <h3 className="text-sm font-medium text-gray-500">Phone</h3>
                <div className="mt-1 flex items-center text-sm text-gray-900">
                  <Phone className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" />
                  {group.contact_phone}
                </div>
              </div>
            )}

            {group?.group_website && (
              <div>
                <h3 className="text-sm font-medium text-gray-500">
                  Website
                </h3>
                <div className="mt-1 flex items-center text-sm text-gray-900">
                  <Globe className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" />
                  <a
                    href={group.group_website}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:underline"
                  >
                    {group.group_website}
                  </a>
                </div>
              </div>
            )}
          </div>

          {/* Vendor-specific or space requirements */}
          {group?.camp_req === "Yes" && (
            <div className="mt-6 p-4 bg-gray-50 rounded-lg">
              <h3 className="text-sm font-medium text-gray-700 mb-2">
                Space Requirements
              </h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <h4 className="text-xs font-medium text-gray-500">
                    Frontage
                  </h4>
                  <p className="text-sm text-gray-900">
                    {group.frontage} feet
                  </p>
                </div>
                <div>
                  <h4 className="text-xs font-medium text-gray-500">
                    Depth
                  </h4>
                  <p className="text-sm text-gray-900">
                    {group.depth} feet
                  </p>
                </div>
              </div>
            </div>
          )}

          {group?.class === "vendor" && (
            <div className="mt-4 p-4 bg-gray-50 rounded-lg">
              <h3 className="text-sm font-medium text-gray-700 mb-2">
                Vendor Requirements
              </h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <h4 className="text-xs font-medium text-gray-500">
                    Needs Water
                  </h4>
                  <p className="text-sm text-gray-900">
                    {group.v_needWater ? "Yes" : "No"}
                  </p>
                </div>
                <div>
                  <h4 className="text-xs font-medium text-gray-500">
                    Needs Power
                  </h4>
                  <p className="text-sm text-gray-900">
                    {group.v_needPower ? "Yes" : "No"}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Application Answers */}
      {answers.length > 0 && (
        <div className="bg-white shadow rounded-lg overflow-hidden mb-6">
          <div className="px-6 py-5 border-b border-gray-200">
            <h2 className="text-lg font-medium text-gray-900">
              Questionnaire Responses
            </h2>
          </div>

          <div className="px-6 py-5">
            <div className="space-y-4">
              {answers.map((answer) => (
                <div key={answer.answerid}>
                  <h3 className="text-sm font-medium text-gray-500">
                    {answer.question}
                  </h3>
                  <p className="mt-1 text-sm text-gray-900 whitespace-pre-wrap">
                    {answer.answer}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* Special Requests */}
      {application.notes && (
        <div className="bg-white shadow rounded-lg overflow-hidden mb-6">
          <div className="px-6 py-5 border-b border-gray-200">
            <h2 className="text-lg font-medium text-gray-900">
              Special Requests/Notes
            </h2>
          </div>

          <div className="px-6 py-5">
            <p className="text-sm text-gray-900 whitespace-pre-wrap">
              {application.notes}
            </p>
          </div>
        </div>
      )}

      {/* Payment Information - if applicable */}
      {application.pymt > 0 && (
        <div className="bg-white shadow rounded-lg overflow-hidden mb-6">
          <div className="px-6 py-5 border-b border-gray-200">
            <h2 className="text-lg font-medium text-gray-900">
              Payment Information
            </h2>
          </div>

          <div className="px-6 py-5">
            <div className="flex justify-between items-center">
              <div>
                <h3 className="text-sm font-medium text-gray-500">
                  Amount
                </h3>
                <div className="mt-1">
                  <span className="text-xl font-bold">
                    ${application.pymt.toFixed(2)}
                  </span>
                </div>
                <p className="mt-2 text-sm text-gray-500">
                  {application.status ===
                    APPLICATION_STATUS.ACCEPTED_PAYMENT
                    ? "Payment is pending"
                    : application.status ===
                      APPLICATION_STATUS.ACCEPTED_COMPLETE
                      ? "Payment has been completed"
                      : "Payment status unknown"}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Application Status */}
      <div className="bg-white shadow rounded-lg overflow-hidden">
        <div className="px-6 py-5 border-b border-gray-200">
          <h2 className="text-lg font-medium text-gray-900">
            Application Status
          </h2>
        </div>

        <div className="px-6 py-5">
          <div className="flex justify-between items-center">
            <div>
              <h3 className="text-sm font-medium text-gray-500">
                Current Status
              </h3>
              <div className="mt-1">
                <ApplicationStatusBadge status={application.status} />
              </div>
            </div>
          </div>

          {/* Application timeline */}
          <div className="mt-6">
            <h3 className="text-sm font-medium text-gray-700 mb-3">
              Application Timeline
            </h3>
            <div className="flow-root">
              <ul className="-mb-8">
                <li>
                  <div className="relative pb-8">
                    <div className="relative flex space-x-3">
                      <div>
                        <span className="h-8 w-8 rounded-full bg-gray-200 flex items-center justify-center ring-8 ring-white">
                          <Clock className="h-5 w-5 text-gray-500" />
                        </span>
                      </div>
                      <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                        <div>
                          <p className="text-sm text-gray-500">
                            Application submitted
                          </p>
                        </div>
                        <div className="text-right text-sm whitespace-nowrap text-gray-500">
                          {formatDate(application.recDate)}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                {/* Additional timeline events could be added here based on status history */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default EventApplicationDetail;
