// src/index.js
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App.js";
import reportWebVitals from "./reportWebVitals";
import { app } from "./config/firebase";

// Import Tailwind base styles
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
console.log("Firebase initialized with app:", app.name);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
