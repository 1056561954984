// src/components/ui/Card.js
import React from "react";

/**
 * Base Card component
 */
export const Card = ({ children, className = "" }) => {
  return (
    <div
      className={`bg-white rounded-lg shadow-md overflow-hidden ${className}`}
    >
      {children}
    </div>
  );
};

/**
 * Card Header component
 */
export const CardHeader = ({
  title,
  subtitle,
  icon: Icon,
  action,
  className = "",
}) => {
  return (
    <div className={`px-4 sm:px-6 py-4 border-b border-gray-200 ${className}`}>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          {Icon && <Icon className="h-5 w-5 text-primary-500 mr-2" />}
          <div>
            {title && (
              <h3 className="text-lg font-medium text-gray-900">{title}</h3>
            )}
            {subtitle && <p className="text-sm text-gray-500">{subtitle}</p>}
          </div>
        </div>
        {action && <div>{action}</div>}
      </div>
    </div>
  );
};

/**
 * Card Content component
 */
export const CardContent = ({ children, className = "" }) => {
  return <div className={`px-4 sm:px-6 py-4 ${className}`}>{children}</div>;
};

/**
 * Card Footer component
 */
export const CardFooter = ({ children, className = "" }) => {
  return (
    <div
      className={`px-4 sm:px-6 py-3 bg-gray-50 border-t border-gray-200 ${className}`}
    >
      {children}
    </div>
  );
};

/**
 * Feature Card with icon for dashboard and landing pages
 */
export const FeatureCard = ({
  title,
  description,
  icon: Icon,
  className = "",
  onClick,
}) => {
  return (
    <div
      className={`bg-white rounded-lg shadow-md p-5 transition-all duration-200 hover:shadow-lg ${
        onClick ? "cursor-pointer hover:translate-y-[-4px]" : ""
      } ${className}`}
      onClick={onClick}
    >
      <div className="flex items-start">
        {Icon && (
          <div className="flex-shrink-0 mr-4">
            <div className="w-10 h-10 rounded-full bg-primary-100 flex items-center justify-center">
              <Icon className="h-5 w-5 text-primary-600" />
            </div>
          </div>
        )}
        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-1">{title}</h3>
          <p className="text-sm text-gray-500">{description}</p>
        </div>
      </div>
    </div>
  );
};

/**
 * Status Card for showing application or event status
 */
export const StatusCard = ({
  title,
  status,
  statusColor,
  icon: Icon,
  content,
  actions,
  className = "",
}) => {
  // Status color classes
  const statusColorClasses = {
    green: "bg-green-100 text-green-800",
    red: "bg-red-100 text-red-800",
    yellow: "bg-yellow-100 text-yellow-800",
    blue: "bg-blue-100 text-blue-800",
    gray: "bg-gray-100 text-gray-800",
    primary: "bg-primary-100 text-primary-800",
    secondary: "bg-secondary-100 text-secondary-800",
    accent: "bg-accent-100 text-accent-800",
  };

  const statusClass =
    statusColorClasses[statusColor] || statusColorClasses.gray;

  return (
    <Card className={className}>
      <CardHeader
        title={title}
        icon={Icon}
        action={
          status && (
            <span
              className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${statusClass}`}
            >
              {status}
            </span>
          )
        }
      />
      <CardContent>{content}</CardContent>
      {actions && (
        <CardFooter className="flex justify-end space-x-2">
          {actions}
        </CardFooter>
      )}
    </Card>
  );
};

/**
 * Event Card specifically for displaying event information
 */
export const EventCard = ({
  event,
  onView,
  onApply,
  showApplyButton = false,
  className = "",
}) => {
  return (
    <Card
      className={`hover:shadow-lg transition-all duration-200 ${className}`}
    >
      <CardHeader
        title={event.eventName || event.event}
        subtitle={event.eventLocation || ""}
        action={
          <span
            className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
              event.isCancelled === 1
                ? "bg-red-100 text-red-800"
                : "bg-green-100 text-green-800"
            }`}
          >
            {event.isCancelled === 1 ? "Cancelled" : "Active"}
          </span>
        }
      />
      <CardContent>
        <div className="mb-4 space-y-2">
          <div className="flex items-center text-sm text-gray-600">
            <svg
              className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                clipRule="evenodd"
              />
            </svg>
            {/* Format date appropriately using your formatting function */}
            {event.event_dates ||
              new Date(event.event_startdate).toLocaleDateString()}
          </div>
          {event.eventLocation && (
            <div className="flex items-center text-sm text-gray-600">
              <svg
                className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                  clipRule="evenodd"
                />
              </svg>
              {event.eventLocation}
            </div>
          )}
        </div>
      </CardContent>
      <CardFooter className="flex justify-end space-x-2">
        {onView && (
          <button
            onClick={() => onView(event.eventID)}
            className="inline-flex items-center px-3 py-1.5 border border-gray-300 text-sm font-medium rounded text-gray-700 bg-white hover:bg-gray-50"
          >
            <svg
              className="w-4 h-4 mr-1"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
              />
            </svg>
            Details
          </button>
        )}
        {showApplyButton && onApply && (
          <button
            onClick={() => onApply(event.eventID)}
            className="inline-flex items-center px-3 py-1.5 border border-transparent text-sm font-medium rounded text-white bg-primary-600 hover:bg-primary-700"
          >
            <svg
              className="w-4 h-4 mr-1"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
              />
            </svg>
            Apply
          </button>
        )}
      </CardFooter>
    </Card>
  );
};

/**
 * Application Card specifically for displaying application information
 */
export const ApplicationCard = ({
  application,
  onView,
  onAction,
  actionLabel,
  actionIcon,
  className = "",
}) => {
  // Helper function to determine status color
  const getStatusColor = (status) => {
    if (status.startsWith("Pending")) return "yellow";
    if (status.startsWith("Accepted")) return "green";
    if (status.startsWith("Declined") || status.startsWith("Removed"))
      return "red";
    return "blue";
  };

  return (
    <Card
      className={`hover:shadow-lg transition-all duration-200 ${className}`}
    >
      <CardHeader
        title={application.eventName}
        subtitle={`Applied as: ${application.group_name} (${application.class})`}
        action={<ApplicationStatusBadge status={application.status} />}
      />
      <CardContent>
        <div className="grid grid-cols-2 gap-y-2 text-sm">
          <div>
            <span className="text-gray-500">Event Date:</span>
          </div>
          <div>
            {new Date(application.event_startdate).toLocaleDateString()}
          </div>
          <div>
            <span className="text-gray-500">Applied On:</span>
          </div>
          <div>{new Date(application.recDate).toLocaleDateString()}</div>

          {/* Show payment info if applicable */}
          {application.pymt > 0 && (
            <>
              <div>
                <span className="text-gray-500">Payment:</span>
              </div>
              <div className="flex items-center">
                <svg
                  className="w-3 h-3 mr-1 text-accent-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                ${application.pymt.toFixed(2)}
              </div>
            </>
          )}
        </div>
      </CardContent>
      <CardFooter className="flex justify-end space-x-2">
        {onView && (
          <button
            onClick={() => onView(application.statusID)}
            className="inline-flex items-center px-3 py-1.5 border border-gray-300 text-sm font-medium rounded text-gray-700 bg-white hover:bg-gray-50"
          >
            <svg
              className="w-4 h-4 mr-1"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
              />
            </svg>
            Details
          </button>
        )}
        {onAction && actionLabel && (
          <button
            onClick={() => onAction(application.statusID)}
            className="inline-flex items-center px-3 py-1.5 border border-transparent text-sm font-medium rounded text-white bg-primary-600 hover:bg-primary-700"
          >
            {actionIcon || null}
            {actionLabel}
          </button>
        )}
      </CardFooter>
    </Card>
  );
};

// Helper component that's needed for the ApplicationCard
const ApplicationStatusBadge = ({ status, className = "", iconSize = 4 }) => {
  if (!status) return null;

  // Helper function to determine icon name based on status
  const getStatusIcon = (status) => {
    if (status.startsWith("Pending")) {
      return "ClockIcon";
    } else if (status.startsWith("Accepted")) {
      if (status.includes("Payment")) {
        return "DollarSignIcon";
      } else if (status.includes("Terms")) {
        return "FileTextIcon";
      } else if (status.includes("Gatelist")) {
        return "UsersIcon";
      } else {
        return "CheckCircleIcon";
      }
    } else if (
      status.startsWith("Removed") ||
      status === "Declined" ||
      status === "Event Cancelled"
    ) {
      return "XCircleIcon";
    }
    return "HelpCircleIcon";
  };

  // Helper function to determine color based on status
  const getStatusColor = (status) => {
    if (status.startsWith("Pending")) {
      return "bg-yellow-100 text-yellow-800";
    } else if (status.startsWith("Accepted")) {
      return "bg-green-100 text-green-800";
    } else if (
      status.startsWith("Removed") ||
      status === "Declined" ||
      status === "Event Cancelled"
    ) {
      return "bg-red-100 text-red-800";
    }
    return "bg-blue-100 text-blue-800";
  };

  // Get the icon component name
  const iconName = getStatusIcon(status);
  const statusColor = getStatusColor(status);

  // Simplified icon display without dynamic import
  return (
    <span
      className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${statusColor} ${className}`}
    >
      {status}
    </span>
  );
};
