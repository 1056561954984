// src/components/Dashboard.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { useGroup } from "../contexts/GroupContext";
import EmailVerificationStatus from "./EmailVerificationStatus";
import {
  Calendar,
  Clock,
  FileText,
  AlertTriangle,
  User,
  ChevronRight,
  Users,
  Tent,
  Store,
  Theater,
  Castle,
  MessageSquare,
  Bell,
  X,
  ArrowRight,
} from "lucide-react";
import {
  Card,
  CardHeader,
  CardContent,
  CardFooter,
  FeatureCard,
  StatusCard,
} from "./ui/Card";
import { Button } from "./ui/Button";
import { Alert } from "./ui/Alert";
import api from "../utils/api";

// Message Modal Component (inline for simplicity)
const ActivityMessageModal = ({
  isOpen,
  onClose,
  applicationId,
  eventId,
  messageId,
  previewContent,
  timestamp,
  otherParty
}) => {
  const { activeGroup } = useGroup();
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMessageDetails = async () => {
      if (!isOpen) return;

      try {
        setLoading(true);

        // Try to fetch the actual message if we have an ID
        if (messageId) {
          try {
            const response = await api.get(`/api/messages/${messageId}`);
            setMessage(response.data);
          } catch (err) {
            console.error("Error fetching specific message:", err);
            // Fall back to a generic message
            setMessage({
              body: previewContent,
              sdate: timestamp,
              otherParty: otherParty
            });
          }
        } else {
          // Without an ID, use the preview content
          setMessage({
            body: previewContent,
            sdate: timestamp,
            otherParty: otherParty
          });
        }

        // Mark as read if needed
        if (messageId) {
          try {
            await api.put(`/api/messages/${messageId}/status`, {
              status: "Read"
            });
          } catch (err) {
            console.error("Error marking message as read:", err);
          }
        }
      } catch (err) {
        console.error("Error in message modal:", err);
        setError("Unable to load message details");
      } finally {
        setLoading(false);
      }
    };

    fetchMessageDetails();
  }, [isOpen, messageId, applicationId, previewContent, timestamp]);

  const handleViewApplication = () => {
    if (applicationId) {
      // If user is an event organizer (faire), use the events route
      if (activeGroup && activeGroup.class === "faire") {
        navigate(`/events/${eventId}/applications/${applicationId}`);
      } else {
        // For regular groups viewing their own application
        navigate(`/applications/view/${applicationId}`);
      }
      onClose();
    }
  };

  const handleReply = () => {
    if (applicationId) {
      // If user is an event organizer (faire), use the events messaging route
      if (activeGroup && activeGroup.class === "faire") {
        navigate(`/events/${eventId}/messages/${applicationId}`);
      } else {
        // For regular groups, go to their application view with communication tab
        navigate(`/applications/view/${applicationId}`, {
          state: { tab: 'communication' }
        });
      }
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg shadow-xl max-w-lg w-full max-h-[80vh] overflow-hidden">
        <div className="px-6 py-4 border-b border-gray-200 flex justify-between items-center">
          <h3 className="text-lg font-medium text-gray-900 flex items-center">
            <MessageSquare className="w-5 h-5 mr-2 text-blue-500" />
            Message Details
          </h3>
          <button
            onClick={onClose}
            className="rounded-full p-1 hover:bg-gray-100 text-gray-400 hover:text-gray-500"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        <div className="px-6 py-4 overflow-y-auto max-h-[50vh]">
          {loading ? (
            <div className="flex justify-center py-8">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
            </div>
          ) : error ? (
            <div className="p-4 bg-red-50 text-red-700 rounded-md">
              {error}
            </div>
          ) : message ? (
            <div>
              <div className="mb-2 text-sm text-gray-500 flex justify-between">
                {message.otherParty && (
                  <span>From: {message.otherParty}</span>
                )}
                <span>{new Date(message.sdate).toLocaleString()}</span>
              </div>
              <div className="p-4 bg-blue-50 rounded-lg whitespace-pre-wrap">
                {message.body || previewContent}
              </div>
            </div>
          ) : (
            <div className="p-4 bg-yellow-50 text-yellow-700 rounded-md">
              Message not found
            </div>
          )}
        </div>

        <div className="px-6 py-4 border-t border-gray-200 bg-gray-50 flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
          >
            Close
          </button>
          <button
            onClick={handleViewApplication}
            className="px-4 py-2 border border-gray-300 rounded-md text-blue-700 hover:bg-blue-50"
          >
            View Application
          </button>
          <button
            onClick={handleReply}
            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 flex items-center"
          >
            Reply <ArrowRight className="ml-1 w-4 h-4" />
          </button>
        </div>
      </div>
    </div>
  );
};

const Dashboard = () => {
  const { user } = useAuth();
  const { activeGroup } = useGroup();
  const [applications, setApplications] = useState([]);
  const [events, setEvents] = useState([]);
  const [activityLog, setActivityLog] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [helpContent, setHelpContent] = useState(null);

  // New state for the message modal
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);

  useEffect(() => {
    // This would be replaced with actual API calls
    const fetchUserData = async () => {
      try {
        const fetchApplications = async () => {
          setLoading(true);
          try {
            const response = await api.get(`/api/applications?upcoming=true`);
            setApplications(response.data);
            setError(null);
          } catch (err) {
            console.error("Error fetching applications:", err);
            setError("Failed to load applications");
          } finally {
            setLoading(false);
          }
        };

        fetchApplications();

        if (activeGroup && activeGroup.class === "faire") {
          try {
            const response = await api.get(`/api/events/group/${activeGroup.groupID}?upcoming=true`);
            setEvents(response.data);
          } catch (err) {
            console.error("Error fetching events:", err);
            setError("Failed to load events");
          }
        }

        const fetchActivityLog = async () => {
          try {
            // Only fetch activity if we have an active group
            if (activeGroup) {
              // Get recent messages for the group (the API already limits to 10 most recent)
              const response = await api.get('/api/messages');

              // Transform messages into the activity log format
              const formattedActivities = response.data.map(message => ({
                id: message.msgID,
                type: "message",
                description: message.isFromGroup
                  ? `You sent a message about ${message.eventName}`
                  : `New message received regarding ${message.eventName}`,
                timestamp: message.sdate,
                applicationId: message.statusID, // If your message contains this
                eventId: message.eventID,
                isRead: message.status === "Read",
                otherParty: message.otherPartyName
              }));

              setActivityLog(formattedActivities);
            } else {
              // If no active group, set empty activity log
              setActivityLog([]);
            }
          } catch (err) {
            console.error("Error fetching messages for activity log:", err);
            setActivityLog([]);
          }
        };

        await fetchActivityLog();

        setLoading(false);
      } catch (error) {
        console.error("Error fetching user data:", error);
        setLoading(false);
      }
    };

    fetchUserData();
  }, [user, activeGroup]);

  const handleActivityClick = (activity) => {
    // If it's a message type, open the modal instead of direct navigation
    if (activity.type === "message") {
      // First check if we have a valid application ID
      if (activity.applicationId) {
        setSelectedMessage(activity);
        setMessageModalOpen(true);

        // We'll mark the message as read in the modal component
      } else {
        // If there's no valid application ID, show an error message
        console.error("Cannot open message: Missing application ID");
        // You could show a toast notification here
      }
    } else if (activity.type === "application" && activity.applicationId) {
      // For application status changes, navigate to the correct URL based on group type
      if (activeGroup && activeGroup.class === "faire") {
        navigate(`/events/${activity.eventId}/applications/${activity.applicationId}`);
      } else {
        navigate(`/applications/view/${activity.applicationId}`);
      }
    }
  };

  const handleHelpArticleClick = (articleId) => {
    // You could fetch this from an API in a real app
    const helpArticles = {
      "getting-started": {
        title: "Getting Started Guide",
        content: `
          <h2>Welcome to RenRegister!</h2>
          <p>This guide will help you get started with our platform...</p>
          <h3>Step 1: Create your profile</h3>
          <p>Begin by setting up your personal profile with your contact information...</p>
          <h3>Step 2: Create or join a group</h3>
          <p>RenRegister works with groups - create one for your guild, act, or vendor business...</p>
          <h3>Step 3: Browse and apply for events</h3>
          <p>Once your group is set up, you can browse available events and submit applications...</p>
        `
      },
      "how-to-apply": {
        title: "How to Apply for Events",
        content: `
          <h2>Applying for Renaissance Events</h2>
          <p>Find and apply for events that match your group type...</p>
          <h3>Finding Events</h3>
          <p>Navigate to the Events page to see all upcoming events...</p>
          <h3>Application Process</h3>
          <p>Click "Apply" on any event with open applications for your group type...</p>
          <h3>After Applying</h3>
          <p>You can check your application status from the My Applications page...</p>
        `
      },
      "managing-groups": {
        title: "Creating and Managing Groups",
        content: `
          <h2>Group Management</h2>
          <p>Learn how to create and manage your Renaissance groups...</p>
          <h3>Creating a New Group</h3>
          <p>From the My Groups page, click "Add New Group" to create a group...</p>
          <h3>Editing Group Details</h3>
          <p>You can update your group information at any time...</p>
          <h3>Switching Between Groups</h3>
          <p>If you manage multiple groups, use the group selector to switch between them...</p>
        `
      }
    };

    setHelpContent(helpArticles[articleId]);
  };

  // Format timestamp to readable date/time
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  // Get icon based on activity type
  const getActivityIcon = (type) => {
    switch (type) {
      case "message":
        return <MessageSquare className="h-5 w-5 text-primary-500" />;
      case "application":
        return <FileText className="h-5 w-5 text-secondary-500" />;
      default:
        return <Bell className="h-5 w-5 text-accent-500" />;
    }
  };

  return (
    <div className="max-w-6xl mx-auto px-4 py-8">
      {/* Email Verification Status */}
      <EmailVerificationStatus />

      {/* Welcome Section */}
      <Card className="mb-8">
        <CardContent className="pt-6">
          <h1 className="text-2xl sm:text-3xl font-bold text-primary-800 mb-2">
            Welcome to your Dashboard
          </h1>
          <p className="text-gray-600 mb-4">
            Hello {user?.email}, welcome to your RenRegister dashboard.
          </p>

          {activeGroup ? (
            <div className="bg-primary-50 rounded-lg p-4 mb-4 border border-primary-100">
              <h2 className="text-lg font-medium text-primary-800 flex items-center">
                <div
                  className={`h-6 w-6 rounded-full flex items-center justify-center text-white text-xs mr-2 ${activeGroup.class === "guild"
                    ? "bg-secondary-500"
                    : activeGroup.class === "vendor"
                      ? "bg-accent-500"
                      : activeGroup.class === "act"
                        ? "bg-renaissance-burgundy"
                        : "bg-renaissance-navy"
                    }`}
                >
                  {activeGroup.class.charAt(0).toUpperCase()}
                </div>
                Active Group: {activeGroup.group_name}
              </h2>
              <p className="text-sm text-gray-600 mt-1 capitalize">
                Group Type: {activeGroup.class}
              </p>
            </div>
          ) : (
            <div className="bg-yellow-50 rounded-lg p-4 mb-4 border border-yellow-100">
              <p className="text-yellow-700">
                You're currently logged in, but have not yet selected a group to
                work as. Please selected <a href="/my-groups">My Groups</a> from
                the menu bar.
              </p>
            </div>
          )}
        </CardContent>
      </Card>

      {/* Dashboard Grid */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-8">
        {/* Left Column */}
        <div className="lg:col-span-2 space-y-6">
          {/* My Applications - Only show if not 'faire' */}
          {activeGroup && activeGroup.class !== "faire" && (
            <Card>
              <CardHeader
                title="My Applications"
                icon={FileText}
                action={
                  <Button
                    size="sm"
                    variant="outline"
                    onClick={() => navigate("/my-applications")}
                  >
                    View All
                  </Button>
                }
              />
              <CardContent>
                {loading ? (
                  <div className="animate-pulse space-y-4">
                    <div className="h-10 bg-gray-200 rounded"></div>
                    <div className="h-10 bg-gray-200 rounded"></div>
                  </div>
                ) : applications.length > 0 ? (
                  <div className="space-y-3">
                    {applications.slice(0, 3).map((app) => (
                      <div
                        key={app.statusID}
                        className="border-l-4 border-primary-500 pl-4 py-3 hover:bg-gray-50 rounded-r transition-colors"
                      >
                        <div className="font-medium">{app.eventName}</div>
                        <div className="flex justify-between text-sm mt-1">
                          <span className="text-gray-600">
                            Submitted: {app.event_dates}
                          </span>
                          <span
                            className={`font-medium px-2 py-0.5 rounded-full text-xs ${app.status === "Approved"
                              ? "bg-green-100 text-green-800"
                              : app.status === "Rejected"
                                ? "bg-red-100 text-red-800"
                                : "bg-yellow-100 text-yellow-800"
                              }`}
                          >
                            {app.status}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="text-center py-6">
                    <p className="text-gray-500 mb-4">
                      You don't have any applications yet
                    </p>
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => navigate("/events")}
                    >
                      Browse Events
                    </Button>
                  </div>
                )}
              </CardContent>
            </Card>
          )}

          {/* My Events - Only show if 'faire' */}
          {activeGroup && activeGroup.class === "faire" && (
            <Card>
              <CardHeader
                title="My Events"
                icon={Calendar}
                action={
                  <Button
                    size="sm"
                    variant="outline"
                    onClick={() => navigate("/my-events")}
                  >
                    View All
                  </Button>
                }
              />
              <CardContent>
                {loading ? (
                  <div className="animate-pulse space-y-4">
                    <div className="h-10 bg-gray-200 rounded"></div>
                    <div className="h-10 bg-gray-200 rounded"></div>
                  </div>
                ) : events.length > 0 ? (
                  <div className="space-y-3">
                    {events.map((event) => (
                      <div
                        key={event.eventID}
                        className="border-l-4 border-green-500 pl-4 py-3 hover:bg-gray-50 rounded-r transition-colors"
                      >
                        <div className="font-medium">{event.eventName}</div>
                        <div className="text-sm text-gray-600 mt-1">
                          {event.event_dates}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="text-center py-6">
                    <p className="text-gray-500 mb-4">
                      You haven't created any events yet
                    </p>
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => navigate("/my-events/create")}
                    >
                      Create New Event
                    </Button>
                  </div>
                )}
              </CardContent>

              {/* Pending Applications Count for Faire */}
              {applications.length > 0 && (
                <CardFooter>
                  <div className="w-full flex justify-between items-center px-4 py-3 bg-blue-50 rounded-lg">
                    <span className="text-blue-800">Pending Applications:</span>
                    <span className="bg-blue-100 px-2.5 py-0.5 rounded-full text-blue-800 font-medium text-sm">
                      {
                        applications.filter((app) => app.status === "Pending")
                          .length
                      }
                    </span>
                  </div>
                </CardFooter>
              )}
            </Card>
          )}

          {/* Recent Activity */}
          <Card>
            <CardHeader title="Recent Activity" icon={Bell} />
            <CardContent>
              {loading ? (
                <div className="animate-pulse space-y-4">
                  <div className="h-14 bg-gray-200 rounded"></div>
                  <div className="h-14 bg-gray-200 rounded"></div>
                  <div className="h-14 bg-gray-200 rounded"></div>
                </div>
              ) : activityLog.length > 0 ? (
                <div className="space-y-3">
                  {activityLog.map((activity) => (
                    <div
                      key={activity.id}
                      className={`flex items-start space-x-3 p-3 hover:bg-gray-50 rounded transition-colors cursor-pointer ${!activity.isRead ? "bg-blue-50" : ""
                        }`}
                      onClick={() => handleActivityClick(activity)}
                    >
                      <div className="flex-shrink-0 mt-1">
                        {getActivityIcon(activity.type)}
                      </div>
                      <div className="flex-1">
                        <p className="text-gray-800">
                          {activity.description}
                          {activity.otherParty && ` from ${activity.otherParty}`}
                        </p>
                        <p className="text-xs text-gray-500 mt-1">
                          {formatTimestamp(activity.timestamp)}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center py-10">
                  <p className="text-gray-500">No recent activity to display</p>
                </div>
              )}
            </CardContent>
          </Card>
        </div>

        {/* Right Column */}
        <div className="space-y-6">
          {/* Quick Actions */}
          <Card>
            <CardHeader title="Quick Actions" />
            <CardContent>
              <div className="space-y-2">
                {activeGroup?.class === "faire" ? (
                  <>
                    <Button
                      variant="primary"
                      fullWidth
                      icon={Calendar}
                      onClick={() => navigate("/my-events/create")}
                    >
                      Create New Event
                    </Button>
                    <Button
                      variant="outline"
                      fullWidth
                      icon={FileText}
                      onClick={() => navigate("/events")}
                    >
                      View All Events
                    </Button>
                  </>
                ) : activeGroup ? (
                  <>
                    <Button
                      variant="primary"
                      fullWidth
                      icon={Calendar}
                      onClick={() => navigate("/events")}
                    >
                      Browse Events
                    </Button>
                    <Button
                      variant="outline"
                      fullWidth
                      icon={FileText}
                      onClick={() => navigate("/my-applications")}
                    >
                      View My Applications
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      variant="primary"
                      fullWidth
                      icon={Users}
                      onClick={() => navigate("/my-groups")}
                    >
                      Create or Join Group
                    </Button>
                    <Button
                      variant="outline"
                      fullWidth
                      icon={User}
                      onClick={() => navigate("/my-profile")}
                    >
                      Update Profile
                    </Button>
                  </>
                )}
              </div>
            </CardContent>
          </Card>

          {/* Available Features */}
          <Card>
            <CardHeader title="Available Features" />
            <CardContent>
              <div className="space-y-3">
                <div className="flex items-center p-2 rounded hover:bg-gray-50">
                  <div className="h-8 w-8 rounded-full bg-primary-100 flex items-center justify-center mr-3">
                    <Users className="h-4 w-4 text-primary-600" />
                  </div>
                  <div>
                    <h3 className="text-sm font-medium">Group Management</h3>
                    <p className="text-xs text-gray-500">
                      Create and manage your groups
                    </p>
                  </div>
                </div>
                <div className="flex items-center p-2 rounded hover:bg-gray-50">
                  <div className="h-8 w-8 rounded-full bg-secondary-100 flex items-center justify-center mr-3">
                    <FileText className="h-4 w-4 text-secondary-600" />
                  </div>
                  <div>
                    <h3 className="text-sm font-medium">Application System</h3>
                    <p className="text-xs text-gray-500">
                      Apply for events and manage submissions
                    </p>
                  </div>
                </div>
                <div className="flex items-center p-2 rounded hover:bg-gray-50">
                  <div className="h-8 w-8 rounded-full bg-accent-100 flex items-center justify-center mr-3">
                    <Calendar className="h-4 w-4 text-accent-600" />
                  </div>
                  <div>
                    <h3 className="text-sm font-medium">Event Management</h3>
                    <p className="text-xs text-gray-500">
                      Create and organize Renaissance events
                    </p>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>

          {/* Help & Resources */}
          <Card>
            <CardHeader title="Help & Resources" />
            <CardContent>
              {helpContent && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
                  <div className="bg-white rounded-lg max-w-3xl w-full p-6 max-h-[90vh] overflow-y-auto">
                    <div className="flex justify-between items-center mb-4">
                      <h2 className="text-xl sm:text-2xl font-bold text-primary-900">
                        {helpContent.title}
                      </h2>
                      <button
                        onClick={() => setHelpContent(null)}
                        className="p-1 rounded-full hover:bg-gray-100 text-gray-500 hover:text-gray-700"
                      >
                        <X className="h-6 w-6" />
                      </button>
                    </div>

                    <div
                      className="prose max-w-none"
                      dangerouslySetInnerHTML={{ __html: helpContent.content }}
                    />

                    <div className="mt-6 flex justify-end">
                      <Button variant="outline" onClick={() => setHelpContent(null)}>
                        Close
                      </Button>
                    </div>
                  </div>
                </div>
              )}
              <div className="space-y-4">
                <p className="text-sm text-gray-700">
                  Need help getting started with RenRegister?
                </p>
                <div className="space-y-2">
                  <a
                    href="#"
                    className="block text-primary-600 hover:text-primary-800 text-sm"
                    onClick={(e) => {
                      e.preventDefault();
                      handleHelpArticleClick("getting-started");
                    }}
                  >
                    • Getting Started Guide
                  </a>
                  <a
                    href="#"
                    className="block text-primary-600 hover:text-primary-800 text-sm"
                    onClick={(e) => {
                      e.preventDefault();
                      handleHelpArticleClick("how-to-apply");
                    }}
                  >
                    • How to Apply for Events
                  </a>
                  <a
                    href="#"
                    className="block text-primary-600 hover:text-primary-800 text-sm"
                    onClick={(e) => {
                      e.preventDefault();
                      handleHelpArticleClick("managing-groups");
                    }}
                  >
                    • Creating and Managing Groups
                  </a>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>

      {/* Message Modal */}
      {messageModalOpen && selectedMessage && (
        <ActivityMessageModal
          isOpen={messageModalOpen}
          onClose={() => setMessageModalOpen(false)}
          messageId={selectedMessage.id}
          applicationId={selectedMessage.applicationId}
          eventId={selectedMessage.eventId}
          previewContent={selectedMessage.description}
          timestamp={selectedMessage.timestamp}
          otherParty={selectedMessage.otherParty}
        />
      )}

      {/* Footer */}
      <div className="text-center text-gray-500 text-sm mt-12">
        Copyright by Renaissance Productions 2006-{new Date().getFullYear()}
      </div>
    </div>
  );
};

export default Dashboard;