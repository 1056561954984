// client/src/components/EventApplications.js
import React, { useState, useEffect } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import MainLayout from "./MainLayout";
import api from "../utils/api";
import { format } from "date-fns";
import {
  ArrowLeft,
  FileText,
  Filter,
  Clock,
  CheckCircle,
  XCircle,
  AlertTriangle,
  Users,
  MessageSquare,
  Download,
  DollarSign,
  Search,
  Loader,
} from "lucide-react";
import ApplicationStatusBadge from "./ApplicationStatusBadge";

const EventApplications = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState(null);
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterStatus, setFilterStatus] = useState("all");
  const [filterType, setFilterType] = useState("all");

  // Load event data and applications
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Fetch the event details
        const eventResponse = await api.get(`/api/events/${eventId}`);
        setEvent(eventResponse.data);

        // Fetch applications for this event
        const applicationsResponse = await api.get(
          `/api/events/${eventId}/applications`
        );
        setApplications(applicationsResponse.data);

        setError(null);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to load event or applications data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [eventId]);

  // Format date function
  const formatDate = (dateString) => {
    try {
      return format(new Date(dateString), "MMM d, yyyy");
    } catch (e) {
      return "Invalid date";
    }
  };

  // Filter applications based on search, status, and type filters
  const filteredApplications = applications.filter((app) => {
    // Search term filter
    const searchMatch =
      searchTerm === "" ||
      app.group_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      app.contact_name?.toLowerCase().includes(searchTerm.toLowerCase());

    // Status filter
    const statusMatch =
      filterStatus === "all" ||
      (filterStatus === "pending" && app.status.startsWith("Pending")) ||
      (filterStatus === "accepted" && app.status.startsWith("Accepted")) ||
      (filterStatus === "removed" &&
        (app.status.startsWith("Removed") ||
          app.status === "Declined" ||
          app.status === "Event Cancelled"));

    // Type filter
    const typeMatch =
      filterType === "all" ||
      app.class.toLowerCase() === filterType.toLowerCase();

    return searchMatch && statusMatch && typeMatch;
  });

  // Handle viewing application details
  const handleViewApplication = (applicationId) => {
    navigate(`/events/${eventId}/applications/${applicationId}`);
  };

  // Handle status update
  const handleStatusUpdate = async (applicationId, newStatus) => {
    try {
      await api.put(
        `/api/events/${eventId}/applications/${applicationId}/status`,
        {
          status: newStatus,
        }
      );

      // Refresh applications after update
      const response = await api.get(`/api/events/${eventId}/applications`);
      setApplications(response.data);
    } catch (err) {
      console.error("Error updating application status:", err);
      alert("Failed to update application status");
    }
  };

  // Export applications as CSV
  const exportToCsv = () => {
    if (!applications.length) return;

    // Create CSV content
    let csvContent = "Group Name,Contact,Class,Status,Submitted Date\n";

    applications.forEach((app) => {
      csvContent += `"${app.group_name}","${app.contact_name || ""}","${app.class
        }","${app.status}","${formatDate(app.recDate)}"\n`;
    });

    // Create and download the file
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      `${event?.eventName || "event"}-applications.csv`
    );
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (loading) {
    return (
      <div className="max-w-6xl mx-auto flex justify-center items-center h-full">
        <Loader className="animate-spin h-12 w-12 text-blue-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-6xl mx-auto">
        <div className="bg-red-50 border-l-4 border-red-500 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <AlertTriangle className="h-5 w-5 text-red-400" />
            </div>
            <div className="ml-3">
              <p className="text-sm text-red-700">{error}</p>
            </div>
          </div>
        </div>
        <button
          onClick={() => navigate(`/my-events/view/${eventId}`)}
          className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
        >
          <ArrowLeft className="w-5 h-5 mr-2" />
          Back to Event Details
        </button>
      </div>
    );
  }

  return (
    <div className="max-w-6xl mx-auto">
      {/* Header */}
      <div className="bg-white shadow rounded-lg mb-6">
        <div className="px-6 py-5">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <button
                onClick={() => navigate(`/my-events/view/${eventId}`)}
                className="mr-4 p-2 rounded-full hover:bg-gray-100"
              >
                <ArrowLeft className="w-5 h-5" />
              </button>
              <div>
                <h1 className="text-2xl font-bold text-gray-900">
                  Applications for {event?.eventName}
                </h1>
                <p className="text-sm text-gray-500">
                  Event Date: {formatDate(event?.event_startdate)}
                </p>
              </div>
            </div>
            <button
              onClick={exportToCsv}
              className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50"
            >
              <Download className="w-4 h-4 mr-2" />
              Export CSV
            </button>
          </div>
        </div>
      </div>

      {/* Filters */}
      <div className="bg-white shadow rounded-lg p-6 mb-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          {/* Search */}
          <div>
            <label
              htmlFor="search"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Search
            </label>
            <div className="relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Search className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                name="search"
                id="search"
                className="focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                placeholder="Search by name..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>

          {/* Status Filter */}
          <div>
            <label
              htmlFor="status-filter"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Status
            </label>
            <select
              id="status-filter"
              name="status-filter"
              className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
            >
              <option value="all">All Statuses</option>
              <option value="pending">Pending</option>
              <option value="accepted">Accepted</option>
              <option value="removed">Removed/Declined</option>
            </select>
          </div>

          {/* Type Filter */}
          <div>
            <label
              htmlFor="type-filter"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Group Type
            </label>
            <select
              id="type-filter"
              name="type-filter"
              className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
              value={filterType}
              onChange={(e) => setFilterType(e.target.value)}
            >
              <option value="all">All Types</option>
              <option value="guild">Guilds</option>
              <option value="vendor">Vendors</option>
              <option value="act">Acts</option>
            </select>
          </div>
        </div>
      </div>

      {/* Applications List */}
      <div className="bg-white shadow rounded-lg overflow-hidden">
        <div className="px-6 py-4 border-b border-gray-200">
          <h2 className="text-lg font-medium text-gray-900">
            {filteredApplications.length} Applications
          </h2>
        </div>

        {filteredApplications.length === 0 ? (
          <div className="p-6 text-center">
            <p className="text-gray-500">
              No applications match your filters.
            </p>
          </div>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Group
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Type
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Submitted
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredApplications.map((application) => (
                  <tr
                    key={application.statusID}
                    className="hover:bg-gray-50"
                  >
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div>
                          <div className="text-sm font-medium text-gray-900">
                            {application.group_name}
                          </div>
                          <div className="text-xs text-gray-500">
                            {application.contact_name}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900 capitalize">
                        {application.class}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">
                        {formatDate(application.recDate)}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <ApplicationStatusBadge status={application.status} />
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <div className="flex justify-end space-x-2">
                        <button
                          onClick={() =>
                            handleViewApplication(application.statusID)
                          }
                          className="text-blue-600 hover:text-blue-900"
                          title="View Details"
                        >
                          <FileText className="w-5 h-5" />
                        </button>

                        {application.status.startsWith("Pending") && (
                          <button
                            onClick={() =>
                              handleStatusUpdate(
                                application.statusID,
                                "Accepted - Awaiting Fee Payment"
                              )
                            }
                            className="text-green-600 hover:text-green-900"
                            title="Accept Application"
                          >
                            <CheckCircle className="w-5 h-5" />
                          </button>
                        )}

                        {!application.status.startsWith("Removed") &&
                          !application.status.includes("Declined") && (
                            <button
                              onClick={() =>
                                handleStatusUpdate(
                                  application.statusID,
                                  "Declined"
                                )
                              }
                              className="text-red-600 hover:text-red-900"
                              title="Decline Application"
                            >
                              <XCircle className="w-5 h-5" />
                            </button>
                          )}

                        <button
                          onClick={() =>
                            navigate(
                              `/events/${eventId}/messages/${application.statusID}`
                            )
                          }
                          className="text-purple-600 hover:text-purple-900"
                          title="Messages"
                        >
                          <MessageSquare className="w-5 h-5" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default EventApplications;
