// client/src/components/ApplicationGateList.js
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MainLayout from "./MainLayout";
import api from "../utils/api";
import {
  ArrowLeft,
  Users,
  Download,
  AlertTriangle,
  Search,
  User,
  Mail,
  CheckCircle,
  XCircle,
  Loader,
} from "lucide-react";

const ApplicationGateList = () => {
  const { eventId, applicationId } = useParams();
  const navigate = useNavigate();
  const [gateList, setGateList] = useState([]);
  const [application, setApplication] = useState(null);
  const [group, setGroup] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        // Fetch application details
        const appResponse = await api.get(
          `/api/events/${eventId}/applications/${applicationId}`
        );
        setApplication(appResponse.data.application);

        try {
          // Try to fetch group details - but don't fail the entire request if it fails
          const groupResponse = await api.get(
            `/api/admin/groups/${appResponse.data.application.groupID}`
          );
          setGroup(groupResponse.data);
        } catch (groupErr) {
          console.error("Error fetching group details:", groupErr);
          // Still set basic group info from the application data
          setGroup({
            groupID: appResponse.data.application.groupID,
            group_name: appResponse.data.application.group_name,
            class: appResponse.data.application.class,
          });
        }

        // Fetch gate list
        const gatelistResponse = await api.get(
          `/api/events/${eventId}/gatelist/${applicationId}`
        );
        setGateList(gatelistResponse.data);

        setError(null);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to load gate list");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [eventId, applicationId]);

  // Filter gatelist based on search
  const filteredGateList = gateList.filter(
    (person) =>
      person.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (person.email &&
        person.email.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  // Export gate list as CSV
  const exportToCsv = () => {
    if (!gateList.length) return;

    // Create CSV content
    let csvContent = "Name,Email,Minor,Checked In\n";

    gateList.forEach((person) => {
      csvContent += `"${person.name}","${person.email || ""}","${person.isMinor
        }","${person.checkedIn || "No"}"\n`;
    });

    // Create and download the file
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      `gatelist-${group?.group_name || "group"}-${eventId}.csv`
    );
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (loading) {
    return (
      <div className="max-w-4xl mx-auto flex justify-center items-center h-full">
        <Loader className="animate-spin h-12 w-12 text-blue-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-4xl mx-auto">
        <div className="bg-red-50 border-l-4 border-red-500 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <AlertTriangle className="h-5 w-5 text-red-400" />
            </div>
            <div className="ml-3">
              <p className="text-sm text-red-700">{error}</p>
            </div>
          </div>
        </div>
        <button
          onClick={() =>
            navigate(`/events/${eventId}/applications/${applicationId}`)
          }
          className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
        >
          <ArrowLeft className="w-5 h-5 mr-2" />
          Back to Application
        </button>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto">
      {/* Header */}
      <div className="bg-white shadow rounded-lg mb-6">
        <div className="px-6 py-5">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <button
                onClick={() =>
                  navigate(
                    `/events/${eventId}/applications/${applicationId}`
                  )
                }
                className="mr-4 p-2 rounded-full hover:bg-gray-100"
              >
                <ArrowLeft className="w-5 h-5" />
              </button>
              <div>
                <h1 className="text-2xl font-bold text-gray-900">
                  Gate List
                </h1>
                <div className="flex items-center mt-1">
                  <p className="text-sm text-gray-500">
                    Group: {group?.group_name} ({group?.class})
                  </p>
                </div>
              </div>
            </div>

            <button
              onClick={exportToCsv}
              disabled={!gateList.length}
              className={`inline-flex items-center px-3 py-1.5 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 ${gateList.length
                ? "bg-white hover:bg-gray-50"
                : "bg-gray-100 cursor-not-allowed text-gray-400"
                }`}
            >
              <Download className="w-4 h-4 mr-2" />
              Export CSV
            </button>
          </div>
        </div>
      </div>

      {/* Gate List Section */}
      <div className="bg-white shadow rounded-lg overflow-hidden">
        <div className="px-6 py-5 border-b border-gray-200 flex justify-between items-center">
          <h2 className="text-lg font-medium text-gray-900">
            {gateList.length} Participants
          </h2>

          {/* Search */}
          <div className="relative w-64">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
            <input
              type="text"
              className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md text-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              placeholder="Search gate list..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>

        {gateList.length === 0 ? (
          <div className="px-6 py-12 text-center">
            <div className="mx-auto h-16 w-16 bg-gray-100 rounded-full flex items-center justify-center mb-4">
              <Users className="h-8 w-8 text-gray-400" />
            </div>
            <h3 className="text-sm font-medium text-gray-900 mb-1">
              No gate list submitted yet
            </h3>
            <p className="text-sm text-gray-500 max-w-sm mx-auto">
              This group has not submitted their gate list for this event.
            </p>
          </div>
        ) : filteredGateList.length === 0 ? (
          <div className="px-6 py-12 text-center">
            <p className="text-sm text-gray-500">
              No participants found matching your search.
            </p>
          </div>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Email
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Minor
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredGateList.map((person) => (
                  <tr key={person.gateID}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 h-8 w-8 rounded-full bg-gray-100 flex items-center justify-center">
                          <User className="h-4 w-4 text-gray-500" />
                        </div>
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">
                            {person.name}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {person.email ? (
                          <div className="flex items-center">
                            <Mail className="h-4 w-4 text-gray-400 mr-1" />
                            <a
                              href={`mailto:${person.email}`}
                              className="text-blue-600 hover:underline"
                            >
                              {person.email}
                            </a>
                          </div>
                        ) : (
                          <span className="text-gray-500">
                            Not provided
                          </span>
                        )}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span
                        className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${person.isMinor === "Yes"
                          ? "bg-yellow-100 text-yellow-800"
                          : "bg-green-100 text-green-800"
                          }`}
                      >
                        {person.isMinor === "Yes" ? "Yes" : "No"}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {person.checkedIn === "Yes" ? (
                        <span className="flex items-center text-green-600">
                          <CheckCircle className="h-4 w-4 mr-1" />
                          Checked In
                        </span>
                      ) : (
                        <span className="flex items-center text-gray-500">
                          <XCircle className="h-4 w-4 mr-1" />
                          Not Checked In
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>

  );
};

export default ApplicationGateList;
