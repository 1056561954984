// client/src/components/ApplicationMessages.js
import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import api from "../utils/api";
import { useAuth } from "../contexts/AuthContext";
import { Send, RefreshCw, UserCircle, User, MessageSquare } from "lucide-react";

/**
 * Component for displaying and sending messages related to an application
 */
const ApplicationMessages = ({ applicationId, eventId, groupId }) => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [error, setError] = useState(null);
  const { user } = useAuth();

  // Fetch messages
  const fetchMessages = async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `/api/applications/${applicationId}/messages`
      );
      setMessages(response.data);
      setError(null);
    } catch (err) {
      console.error("Error fetching messages:", err);
      setError("Failed to load messages");
    } finally {
      setLoading(false);
    }
  };

  // Initial fetch
  useEffect(() => {
    if (applicationId) {
      fetchMessages();
    }
  }, [applicationId]);

  // Send a new message
  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;

    try {
      setSending(true);

      await api.post(`/api/applications/${applicationId}/messages`, {
        body: newMessage,
        eventId,
        groupId,
      });

      // Clear input and reload messages
      setNewMessage("");
      fetchMessages();
    } catch (err) {
      console.error("Error sending message:", err);
      setError("Failed to send message");
    } finally {
      setSending(false);
    }
  };

  // Format date
  const formatMessageDate = (dateString) => {
    try {
      return format(new Date(dateString), "MMM d, yyyy h:mm a");
    } catch (e) {
      return "Unknown date";
    }
  };

  return (
    <div className="bg-white shadow rounded-lg overflow-hidden">
      <div className="px-6 py-4 border-b border-gray-200 flex justify-between items-center">
        <h2 className="text-lg font-medium text-gray-900">Messages</h2>
        <button
          onClick={fetchMessages}
          className="text-blue-600 hover:text-blue-800"
          disabled={loading}
        >
          <RefreshCw className={`w-4 h-4 ${loading ? "animate-spin" : ""}`} />
        </button>
      </div>

      <div className="px-6 py-4">
        {error && (
          <div className="mb-4 bg-red-50 border-l-4 border-red-400 p-3 text-sm text-red-700">
            {error}
          </div>
        )}

        {/* Messages list */}
        <div className="mb-4 space-y-4 max-h-96 overflow-y-auto">
          {messages.length === 0 ? (
            <div className="text-center py-12">
              <div className="mx-auto h-16 w-16 bg-gray-100 rounded-full flex items-center justify-center mb-4">
                <MessageSquare className="h-8 w-8 text-gray-400" />
              </div>
              <h3 className="text-sm font-medium text-gray-900 mb-1">
                No messages yet
              </h3>
              <p className="text-sm text-gray-500 max-w-sm mx-auto">
                Start a conversation with the event organizers by sending a
                message below.
              </p>
            </div>
          ) : (
            messages.map((message) => (
              <div
                key={message.msgID}
                className={`p-3 rounded-lg ${
                  message.isFromGroup ? "bg-blue-50 ml-8" : "bg-gray-50 mr-8"
                }`}
              >
                <div className="flex items-start mb-1">
                  <div
                    className={`flex-shrink-0 mr-2 ${
                      message.isFromGroup ? "text-blue-600" : "text-gray-600"
                    }`}
                  >
                    {message.isFromGroup ? (
                      <User className="w-5 h-5" />
                    ) : (
                      <UserCircle className="w-5 h-5" />
                    )}
                  </div>
                  <div className="flex-1">
                    <p className="text-sm font-medium">
                      {message.isFromGroup ? "You" : "Event Organizer"}
                    </p>
                    <p className="text-xs text-gray-500">
                      {formatMessageDate(message.sdate)}
                    </p>
                  </div>
                </div>
                <div className="pl-7">
                  <p className="text-sm text-gray-800 whitespace-pre-wrap">
                    {message.body}
                  </p>
                </div>
              </div>
            ))
          )}
        </div>

        {/* New message form */}
        <div className="mt-6 bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden">
          <div className="p-3 bg-gray-50 border-b border-gray-200">
            <h3 className="text-sm font-medium text-gray-700">New Message</h3>
          </div>
          <div className="p-4">
            <textarea
              className="w-full p-3 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 transition-colors duration-200"
              rows="3"
              placeholder="Type your message to the event organizers..."
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              disabled={sending}
            ></textarea>
            <div className="mt-3 flex justify-end">
              <button
                onClick={handleSendMessage}
                disabled={sending || !newMessage.trim()}
                className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white transition-colors duration-200 ${
                  sending || !newMessage.trim()
                    ? "bg-blue-300 cursor-not-allowed"
                    : "bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                }`}
              >
                {sending ? (
                  <>
                    <RefreshCw className="animate-spin mr-2 h-4 w-4" />
                    Sending...
                  </>
                ) : (
                  <>
                    <Send className="mr-2 h-4 w-4" />
                    Send Message
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationMessages;
