// client/src/components/payment/PaymentComplete.js
import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import api from "../../utils/api";
import MainLayout from "../MainLayout";
import { CheckCircle, AlertTriangle, ArrowLeft, Loader } from "lucide-react";

const PaymentComplete = () => {
  const { applicationId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [checkCount, setCheckCount] = useState(0);
  const [params, setParams] = useState(
    new URLSearchParams(window.location.search)
  );
  const MAX_RETRIES = 5;

  useEffect(() => {
    // Log when the component mounts with the full URL
    console.log(
      "PaymentComplete component mounted with URL:",
      window.location.href
    );

    // Get parameters from URL
    const params = new URLSearchParams(window.location.search);
    setParams(params);

    // Log all URL parameters for debugging
    console.log("Payment redirect parameters:");
    params.forEach((value, key) => {
      console.log(`  ${key}: ${value}`);
    });

    // Check if this is a cancelled payment
    if (params.get("cancelled") === "true") {
      console.log("Payment was cancelled according to URL parameter");
      setSuccess(false);
      setError("Payment was cancelled");
      setLoading(false);
      return;
    }

    const checkPaymentStatus = async () => {
      try {
        // If we've tried too many times, stop trying
        if (checkCount >= MAX_RETRIES) {
          console.log(
            `Maximum retry attempts (${MAX_RETRIES}) reached for payment verification`
          );
          setError(
            "Could not verify payment status after multiple attempts. Please check your email for confirmation."
          );
          setLoading(false);
          return;
        }

        console.log(
          `Payment verification attempt ${checkCount + 1}/${MAX_RETRIES}`
        );

        // Extract payment identifiers from various possible query parameters
        const squareCheckoutId = params.get("checkoutId");
        const paypalToken = params.get("token");
        const paypalPaymentId = params.get("paymentId");

        // Use the first available identifier
        const paymentIdentifier =
          squareCheckoutId || paypalToken || paypalPaymentId;

        console.log("Payment identifier found:", {
          squareCheckoutId,
          paypalToken,
          paypalPaymentId,
          using: paymentIdentifier,
        });

        if (!paymentIdentifier) {
          console.log("No payment identifier found in redirect URL");
          setError("No payment information found in redirect");
          setLoading(false);
          return;
        }

        // Determine which processor was used based on the parameters
        const processor = squareCheckoutId ? "square" : "paypal";
        console.log(`Determined payment processor: ${processor}`);

        if (processor === "paypal") {
          try {
            // Instead of verifying the payment directly, check the application status
            const response = await api.get(
              `/api/applications/status/${applicationId}`
            );

            if (response.data.payment_status === "completed") {
              console.log("Application payment status is completed");
              setSuccess(true);
              setLoading(false);
              return;
            }

            // If not completed yet, we'll check again after a delay
            // The webhook might not have processed yet
            if (checkCount < MAX_RETRIES) {
              console.log(
                `Payment not yet confirmed. Will retry (${
                  checkCount + 1
                }/${MAX_RETRIES})`
              );
              setCheckCount((prev) => prev + 1);
              setTimeout(checkPaymentStatus, 2000);
              return;
            } else {
              // After max retries, consider it successful anyway since we got the redirect
              console.log(
                "Max retries reached, but PayPal redirected to success URL. Considering successful."
              );
              setSuccess(true);
              setLoading(false);

              // Send a manual verification attempt as backup
              api
                .get(`/api/payments/verify/${paymentIdentifier}`, {
                  params: { processor, applicationId },
                })
                .catch((err) =>
                  console.log("Backup verification attempt failed:", err)
                );
            }
          } catch (err) {
            console.error("Error checking application status:", err);
            // For redirect-based flows, still consider successful if we got redirected
            setSuccess(true);
            setLoading(false);
          }
          return;
        }

        // Call the verification endpoint
        console.log(
          `Making verification request to: /api/payments/verify/${paymentIdentifier}`,
          {
            processor,
            applicationId,
          }
        );

        const response = await api.get(
          `/api/payments/verify/${paymentIdentifier}`,
          {
            params: {
              processor,
              applicationId,
            },
          }
        );

        console.log("Payment verification response:", response.data);

        const paymentStatus = response.data.payment.status;
        if (
          paymentStatus === "completed" ||
          paymentStatus === "COMPLETED" ||
          paymentStatus.toLowerCase() === "completed"
        ) {
          console.log("Payment verified as completed");
          setSuccess(true);
          setLoading(false);
        } else {
          // For PayPal orders in APPROVED state, consider them successful too
          if (paymentStatus === "APPROVED" || paymentStatus === "approved") {
            console.log("PayPal payment is approved but not yet captured");
            setSuccess(true);
            setLoading(false);
          } else {
            console.log(
              `Payment not yet completed. Status: ${paymentStatus}. Will retry...`
            );
            // Increment check count and try again in 3 seconds
            setCheckCount((prev) => prev + 1);
            setTimeout(checkPaymentStatus, 3000);
          }
        }
      } catch (err) {
        console.error("Payment verification error:", err);
        console.error("Error details:", {
          message: err.message,
          response: err.response?.data,
          status: err.response?.status,
        });

        // Increment check count and try again if we haven't hit the maximum
        if (checkCount < MAX_RETRIES - 1) {
          console.log(
            `Verification failed but will retry (${
              checkCount + 1
            }/${MAX_RETRIES})`
          );
          setCheckCount((prev) => prev + 1);
          setTimeout(checkPaymentStatus, 3000);
        } else {
          console.log(
            "Maximum retries reached. Giving up on payment verification."
          );
          setError(
            "Failed to verify payment. Please contact customer support if payment was completed."
          );
          setLoading(false);
        }
      }
    };

    checkPaymentStatus();
  }, [applicationId, location, checkCount]);

  return (
<MainLayout>
        <div className="max-w-3xl mx-auto">
          <div className="bg-white shadow-sm rounded-lg overflow-hidden">
            <div className="px-6 py-5 border-b border-gray-200">
              <h1 className="text-xl font-medium text-gray-900">
                Payment {success ? "Successful" : "Status"}
              </h1>
            </div>

            <div className="px-6 py-16 text-center">
              {loading ? (
                <div className="flex flex-col items-center">
                  <Loader className="animate-spin h-12 w-12 text-blue-500 mb-4" />
                  <p className="text-gray-600">
                    Verifying payment status
                    {checkCount > 0
                      ? ` (attempt ${checkCount}/${MAX_RETRIES})`
                      : ""}
                    ...
                  </p>
                </div>
              ) : success ? (
                <>
                  <div className="rounded-full bg-green-100 mx-auto h-16 w-16 flex items-center justify-center mb-6">
                    <CheckCircle className="h-8 w-8 text-green-600" />
                  </div>
                  <h2 className="text-2xl font-bold text-gray-900 mb-4">
                    Payment Successful!
                  </h2>
                  <p className="text-gray-600 mb-8 max-w-lg mx-auto">
                    Your payment has been processed successfully. Your
                    application is now one step closer to completion.
                  </p>
                </>
              ) : params.get("source") === "square" ? (
                <>
                  <div className="rounded-full bg-blue-100 mx-auto h-16 w-16 flex items-center justify-center mb-6">
                    <CheckCircle className="h-8 w-8 text-blue-600" />
                  </div>
                  <h2 className="text-2xl font-bold text-gray-900 mb-4">
                    Square Payment Complete
                  </h2>
                  <p className="text-gray-600 mb-4 max-w-lg mx-auto">
                    We've detected you just completed a payment with Square. If
                    your Square payment was successful, please click the button
                    below to return to your application.
                  </p>
                  <p className="text-gray-600 mb-8 max-w-lg mx-auto">
                    You can close the Square window/tab if it's still open.
                  </p>
                </>
              ) : (
                <>
                  <div className="rounded-full bg-red-100 mx-auto h-16 w-16 flex items-center justify-center mb-6">
                    <AlertTriangle className="h-8 w-8 text-red-600" />
                  </div>
                  <h2 className="text-2xl font-bold text-gray-900 mb-4">
                    Payment Issue
                  </h2>
                  <p className="text-gray-600 mb-8 max-w-lg mx-auto">
                    {error ||
                      "There was an issue processing your payment. Please try again."}
                  </p>
                </>
              )}

              <button
                onClick={() => navigate(`/applications/view/${applicationId}`)}
                className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white 
                  ${
                    params.get("source") === "square"
                      ? "bg-green-600 hover:bg-green-700 text-base py-3 px-6"
                      : "bg-blue-600 hover:bg-blue-700"
                  }`}
              >
                <ArrowLeft className="w-4 h-4 mr-2" />
                {params.get("source") === "square"
                  ? "Continue to My Application →"
                  : "Return to Application"}
              </button>
            </div>
          </div>
        </div>
        </MainLayout>
  );
};

export default PaymentComplete;
