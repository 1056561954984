// src/components/LandingPage.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../contexts/AuthContext";
import { useGroup } from "../contexts/GroupContext";
import {
  Eye,
  Store,
  Theater,
  Castle,
  Info,
  Search,
  Calendar,
  MapPin,
  X,
  ChevronRight,
  LogIn,
  UserPlus,
} from "lucide-react";
import {
  Card,
  CardHeader,
  CardContent,
  CardFooter,
  EventCard,
  FeatureCard,
} from "./ui/Card";
import { Button } from "./ui/Button";
import { Alert } from "./ui/Alert";

const LandingPage = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [activeTab, setActiveTab] = useState("description");
  const { user } = useAuth();
  const { activeGroup } = useGroup();
  const navigate = useNavigate();

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/api/events/upcoming`;
      const response = await axios.get(url);
      setEvents(response.data);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching events:", err);
      setError("Failed to load events");
      setLoading(false);
    }
  };

  const handleViewDetails = (event) => {
    setSelectedEvent(event);
    setActiveTab("description"); // Reset to description tab when opening a new event
  };

  const handleApplyForEvent = (eventId) => {
    if (!user) {
      navigate("/login");
      return;
    }

    if (!activeGroup) {
      alert("Please select a group before applying for an event");
      return;
    }

    navigate(`/applications/create/${eventId}`);
  };

  const filteredEvents = events.filter(
    (event) =>
      event.eventName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      event.eventLocation?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const canApplyForEvent = (event) => {
    if (!activeGroup) return false;

    const today = new Date();
    const cutoffDate = new Date(
      activeGroup.class === "vendor" ? event.vendor_cutoff : event.app_cutoff
    );

    // Check if event is in the future and cutoff date hasn't passed
    const eventDate = new Date(event.event_startdate);

    // Handle different application types based on group class
    if (activeGroup.class === "vendor" && event.vendorAppType === "closed") {
      return false;
    }
    if (activeGroup.class === "guild" && event.appType === "closed") {
      return false;
    }
    if (activeGroup.class === "act" && event.actAppType === "closed") {
      return false;
    }

    return eventDate > today && today <= cutoffDate;
  };

  // Format date function
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("en-US", {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  return (
    <main className="flex-1 p-4 sm:p-6 md:p-8">
      <div className="max-w-6xl mx-auto">
        {/* Welcome Section */}
        <Card className="mb-8">
          <CardContent className="pt-6">
            <h1 className="text-2xl sm:text-3xl font-bold text-primary-800 mb-4">
              Welcome to Renaissance Productions
            </h1>
            <div className="prose max-w-none">
              <p className="text-base sm:text-lg text-gray-700 mb-4">
                Discover the magic of historical reenactment and immersive
                events that bring the past to life. Renaissance Productions
                connects artisans, performers, guilds, and history enthusiasts
                with authentic Renaissance faires and historical events across
                the country.
              </p>
              <p className="text-base sm:text-lg text-gray-700 mb-6">
                Whether you're a seasoned vendor, talented performer,
                established guild, or simply a passionate enthusiast, our
                platform helps you find and participate in the most vibrant
                historical events. Browse upcoming events, check application
                deadlines, and become part of our thriving community.
              </p>
            </div>

            {/* Login Callout for non-authenticated users */}
            {!user && (
              <div className="bg-blue-50 border border-blue-200 rounded-lg p-6 my-6">
                <h3 className="text-xl font-semibold text-blue-800 mb-3">
                  Join Our Community of Renaissance Enthusiasts
                </h3>
                <p className="text-blue-700 mb-4">
                  Sign in or create an account to apply for events, manage your renaissance groups,
                  and connect with event organizers across the country.
                </p>
                <div className="flex flex-wrap gap-4">
                  <Button
                    variant="primary"
                    onClick={() => navigate("/login")}
                    icon={LogIn}
                  >
                    Sign In
                  </Button>
                  <Button
                    variant="outline"
                    onClick={() => navigate("/create-account")}
                    icon={UserPlus}
                  >
                    Create Account
                  </Button>
                </div>
              </div>
            )}

            <div className="flex flex-wrap gap-4 mt-6">
              <Button
                variant="primary"
                size="lg"
                onClick={() =>
                  window.open("https://renaissanceproductions.com", "_blank")
                }
                icon={Info}
              >
                Learn More About Us
              </Button>
              <Button
                variant="outline"
                size="lg"
                onClick={() =>
                  window.open(
                    "https://www.facebook.com/groups/150089888367685",
                    "_blank"
                  )
                }
              >
                Join Our Community
              </Button>
            </div>
          </CardContent>
        </Card>

        {user && activeGroup && activeGroup.class === "faire" && (
          <>
            {/* Featured Categories */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
              <FeatureCard
                title="Guilds & Troupes"
                description="Historical reenactment groups bringing history to life through demonstrations and activities."
                icon={Castle}
                onClick={() => navigate("/guilds")}
              />
              <FeatureCard
                title="Stage Acts"
                description="Musicians, dancers, comedy acts, and performers entertaining faire visitors."
                icon={Theater}
                onClick={() => navigate("/stage-acts")}
              />
              <FeatureCard
                title="Vendors & Artisans"
                description="Craftspeople and merchants selling period-appropriate goods and wares."
                icon={Store}
                onClick={() => navigate("/vendors")}
              />
            </div>
          </>
        )}

        {/* Upcoming Events Section */}
        <Card className="mb-8">
          <CardHeader
            title="Upcoming Events"
            action={
              <div className="relative flex items-center">
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                <input
                  type="text"
                  className="pl-10 pr-3 py-2 border border-gray-300 rounded-md focus:ring-primary-500 focus:border-primary-500"
                  placeholder="Search events..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            }
          />
          <CardContent>
            {loading ? (
              <div className="flex justify-center py-12">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary-600"></div>
              </div>
            ) : error ? (
              <Alert
                variant="error"
                title="Error Loading Events"
                message={error}
              />
            ) : filteredEvents.length === 0 ? (
              <div className="text-center py-12">
                <p className="text-lg text-gray-500">No events found</p>
              </div>
            ) : (
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                {filteredEvents.map((event) => (
                  <EventCard
                    key={event.eventID}
                    event={event}
                    onView={() => handleViewDetails(event)}
                    onApply={() => handleApplyForEvent(event.eventID)}
                    showApplyButton={
                      user && activeGroup && canApplyForEvent(event)
                    }
                  />
                ))}
              </div>
            )}
          </CardContent>
          <CardFooter>
            <p className="text-sm text-gray-500">
              Showing {filteredEvents.length} of {events.length} events
            </p>
          </CardFooter>
        </Card>

        {/* Event Details Modal */}
        {selectedEvent && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
            <div className="bg-white rounded-lg max-w-3xl w-full p-6 max-h-[90vh] overflow-y-auto">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl sm:text-2xl font-bold text-primary-900">
                  {selectedEvent.eventName}
                </h2>
                <button
                  onClick={() => setSelectedEvent(null)}
                  className="p-1 rounded-full hover:bg-gray-100 text-gray-500 hover:text-gray-700"
                >
                  <X className="h-6 w-6" />
                </button>
              </div>

              <div className="mb-6 space-y-3">
                <div className="flex items-center text-gray-700">
                  <MapPin className="flex-shrink-0 mr-2 h-5 w-5 text-primary-500" />
                  <span>{selectedEvent.eventLocation || "Location TBD"}</span>
                </div>
                <div className="flex items-center text-gray-700">
                  <Calendar className="flex-shrink-0 mr-2 h-5 w-5 text-primary-500" />
                  <span>
                    {selectedEvent.event_dates ||
                      formatDate(selectedEvent.event_startdate)}
                  </span>
                </div>
              </div>

              {/* Application Status Icons */}
              <div className="flex space-x-6 mb-6 bg-gray-50 p-3 rounded-lg">
                <div className="flex items-center space-x-2">
                  <Store
                    className={`h-5 w-5 ${selectedEvent.vendorAppType === "Open"
                        ? "text-green-500"
                        : "text-gray-300"
                      }`}
                  />
                  <span className="text-sm text-gray-600">
                    {selectedEvent.vendorAppType === "Open"
                      ? "Vendor Applications Open"
                      : "Vendor Applications Closed"}
                  </span>
                </div>
                <div className="flex items-center space-x-2">
                  <Theater
                    className={`h-5 w-5 ${selectedEvent.actAppType === "Open"
                        ? "text-green-500"
                        : "text-gray-300"
                      }`}
                  />
                  <span className="text-sm text-gray-600">
                    {selectedEvent.actAppType === "Open"
                      ? "Performer Applications Open"
                      : "Performer Applications Closed"}
                  </span>
                </div>
                <div className="flex items-center space-x-2">
                  <Castle
                    className={`h-5 w-5 ${selectedEvent.appType === "Open"
                        ? "text-green-500"
                        : "text-gray-300"
                      }`}
                  />
                  <span className="text-sm text-gray-600">
                    {selectedEvent.appType === "Open"
                      ? "Guild Applications Open"
                      : "Guild Applications Closed"}
                  </span>
                </div>
              </div>

              {/* Tabs */}
              <div className="border-b border-gray-200 mb-6">
                <nav className="flex -mb-px">
                  <button
                    onClick={() => setActiveTab("description")}
                    className={`mr-1 py-2 px-4 font-medium text-sm rounded-t-md ${activeTab === "description"
                        ? "border-b-2 border-primary-500 text-primary-600"
                        : "text-gray-500 hover:text-gray-700"
                      }`}
                  >
                    Description
                  </button>
                  <button
                    onClick={() => setActiveTab("rules")}
                    className={`mr-1 py-2 px-4 font-medium text-sm rounded-t-md ${activeTab === "rules"
                        ? "border-b-2 border-primary-500 text-primary-600"
                        : "text-gray-500 hover:text-gray-700"
                      }`}
                  >
                    Rules
                  </button>
                  <button
                    onClick={() => setActiveTab("vendor-notes")}
                    className={`py-2 px-4 font-medium text-sm rounded-t-md ${activeTab === "vendor-notes"
                        ? "border-b-2 border-primary-500 text-primary-600"
                        : "text-gray-500 hover:text-gray-700"
                      }`}
                  >
                    Vendor Notes
                  </button>
                </nav>
              </div>

              {/* Tab Content */}
              <div className="prose max-w-none mb-6">
                {activeTab === "description" && (
                  <div>
                    {selectedEvent.notes ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: selectedEvent.notes,
                        }}
                      />
                    ) : (
                      <p className="text-gray-500 italic">
                        No description available.
                      </p>
                    )}
                  </div>
                )}

                {activeTab === "rules" && (
                  <div>
                    {selectedEvent.rules ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: selectedEvent.rules,
                        }}
                      />
                    ) : (
                      <p className="text-gray-500 italic">
                        No rules specified for this event.
                      </p>
                    )}
                  </div>
                )}

                {activeTab === "vendor-notes" && (
                  <div>
                    {selectedEvent.v_notes ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: selectedEvent.v_notes,
                        }}
                      />
                    ) : (
                      <p className="text-gray-500 italic">
                        No vendor-specific notes for this event.
                      </p>
                    )}
                  </div>
                )}
              </div>

              <div className="flex justify-between">
                {user && activeGroup && canApplyForEvent(selectedEvent) && (
                  <Button
                    variant="primary"
                    onClick={() => handleApplyForEvent(selectedEvent.eventID)}
                    icon={ChevronRight}
                    iconPosition="right"
                  >
                    Apply Now
                  </Button>
                )}
                <Button
                  variant="outline"
                  onClick={() => setSelectedEvent(null)}
                >
                  Close
                </Button>
              </div>
            </div>
          </div>
        )}

        {/* Footer */}
        <footer className="mt-8 text-center text-gray-500 text-sm">
          Copyright by Renaissance Productions 2006-{new Date().getFullYear()}
        </footer>
      </div>
    </main>
  );
};

export default LandingPage;