import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MainLayout from "../components/MainLayout";

import api from "../utils/api";
import {
  CheckCircle,
  ArrowLeft,
  FileText,
  AlertTriangle,
  ExternalLink,
} from "lucide-react";

const ApplicationConfirmation = () => {
  const { applicationId } = useParams();
  const navigate = useNavigate();
  const [application, setApplication] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchApplication = async () => {
      try {
        const response = await api.get(`/api/applications/${applicationId}`);
        setApplication(response.data);
        setError(null);
      } catch (err) {
        console.error("Error fetching application:", err);
        setError("Failed to load application data");
      } finally {
        setLoading(false);
      }
    };

    fetchApplication();
  }, [applicationId]);

  if (loading) {
    return (
          <MainLayout>
            <div className="max-w-4xl mx-auto flex justify-center items-center h-full">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
            </div>
          </MainLayout>
    );
  }

  if (error) {
    return (
<MainLayout>
          <div className="max-w-4xl mx-auto">
            <div className="bg-red-50 border-l-4 border-red-500 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <AlertTriangle className="h-5 w-5 text-red-400" />
                </div>
                <div className="ml-3">
                  <p className="text-sm text-red-700">{error}</p>
                </div>
              </div>
            </div>
            <button
              onClick={() => navigate("/my-applications")}
              className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
            >
              <ArrowLeft className="w-5 h-5 mr-2" />
              Back to My Applications
            </button>
          </div>
          </MainLayout>
    );
  }

  return (
<MainLayout>
        <div className="max-w-3xl mx-auto">
          <div className="bg-white shadow-sm rounded-lg overflow-hidden">
            <div className="px-6 py-16 text-center">
              <div className="rounded-full bg-green-100 mx-auto h-16 w-16 flex items-center justify-center mb-6">
                <CheckCircle className="h-8 w-8 text-green-600" />
              </div>

              <h2 className="text-2xl font-bold text-gray-900 mb-4">
                Application Submitted Successfully!
              </h2>
              <p className="text-gray-600 mb-8 max-w-lg mx-auto">
                Thank you for your application. Your submission has been
                received and will be reviewed by the event organizers.
              </p>

              <div className="mb-8 bg-gray-50 rounded-lg p-4 max-w-md mx-auto">
                <p className="text-sm text-gray-500 mb-2">
                  Application Reference:
                </p>
                <p className="text-lg font-medium text-gray-900">
                  #{applicationId}
                </p>
              </div>

              <div className="flex flex-col sm:flex-row justify-center gap-4">
                <button
                  onClick={() => navigate("/my-applications")}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
                >
                  <FileText className="w-4 h-4 mr-2" />
                  View My Applications
                </button>
                <button
                  onClick={() => navigate("/events")}
                  className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50"
                >
                  <ExternalLink className="w-4 h-4 mr-2" />
                  Explore More Events
                </button>
              </div>

              {application && application.paymentRequired && (
                <div className="mt-8 bg-blue-50 p-4 rounded-lg max-w-md mx-auto">
                  <h3 className="text-lg font-medium text-blue-900 mb-2">
                    Payment Required
                  </h3>
                  <p className="text-sm text-blue-700 mb-4">
                    Your application requires payment to complete the process.
                  </p>
                  <button
                    onClick={() =>
                      navigate(`/applications/payment/${applicationId}`)
                    }
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
                  >
                    Proceed to Payment
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        </MainLayout>
  );
};

export default ApplicationConfirmation;
