// client/src/contexts/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import {
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  getAuth,
} from "firebase/auth";
import { auth } from "../config/firebase";

const AuthContext = createContext({});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Handle initial auth state and auth state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      try {
        if (firebaseUser) {
          // Check if email is verified (for email/password users)
          if (
            firebaseUser.providerData.some(
              (provider) => provider.providerId === "password"
            ) &&
            !firebaseUser.emailVerified
          ) {
            console.log("Email not verified, signing out");
            await signOut(auth);
            setUser(null);
            localStorage.removeItem("authUser");
            localStorage.removeItem("activeGroupId");
            setError("Please verify your email before logging in");
            setLoading(false);
            return;
          }

          // Get a fresh token
          const token = await firebaseUser.getIdToken(true);

          const userData = {
            uid: firebaseUser.uid,
            email: firebaseUser.email,
            displayName: firebaseUser.displayName,
            photoURL: firebaseUser.photoURL,
            emailVerified: firebaseUser.emailVerified,
            token,
          };
          setUser(userData);

          // Store auth state in localStorage with token
          localStorage.setItem("authUser", JSON.stringify(userData));
        } else {
          setUser(null);
          localStorage.removeItem("authUser");
          localStorage.removeItem("activeGroupId");
        }
      } catch (error) {
        console.error("Error in auth state change:", error);
        setError(error.message);
        setUser(null);
      } finally {
        setLoading(false);
      }
    });

    // Cleanup subscription
    return () => unsubscribe();
  }, []);

  // Token refresh logic - more frequent than before
  useEffect(() => {
    let refreshInterval;

    if (user?.uid) {
      refreshInterval = setInterval(async () => {
        try {
          const currentUser = auth.currentUser;
          if (currentUser) {
            // Verify that email is still verified for password providers
            if (
              currentUser.providerData.some(
                (provider) => provider.providerId === "password"
              ) &&
              !currentUser.emailVerified
            ) {
              // Force reload user to check current verification status
              await currentUser.reload();
              if (!currentUser.emailVerified) {
                await signOut(auth);
                setUser(null);
                localStorage.removeItem("authUser");
                localStorage.removeItem("activeGroupId");
                setError("Email verification required");
                return;
              }
            }

            const newToken = await currentUser.getIdToken(true);
            setUser((prevUser) => ({
              ...prevUser,
              token: newToken,
              emailVerified: currentUser.emailVerified,
            }));

            // Update token in localStorage
            const storedUser = JSON.parse(
              localStorage.getItem("authUser") || "{}"
            );
            storedUser.token = newToken;
            storedUser.emailVerified = currentUser.emailVerified;
            localStorage.setItem("authUser", JSON.stringify(storedUser));
          }
        } catch (error) {
          console.error("Token refresh error:", error);
          setError("Failed to refresh authentication");
        }
      }, 5 * 60 * 1000); // Refresh every 5 minutes
    }

    return () => {
      if (refreshInterval) {
        clearInterval(refreshInterval);
      }
    };
  }, [user?.uid]);

  // Auth methods
  const login = async (email, password) => {
    try {
      const result = await auth.signInWithEmailAndPassword(email, password);

      // Check if email is verified
      if (!result.user.emailVerified) {
        await signOut(auth);
        throw new Error("Please verify your email before logging in");
      }

      const token = await result.user.getIdToken();

      // Clear active group when logging in
      localStorage.removeItem("activeGroupId");

      return { user: result.user, token };
    } catch (error) {
      console.error("Login error:", error);
      setError(error.message);
      throw error;
    }
  };

  const loginWithGoogle = async () => {
    try {
      // Clear active group when logging in with Google
      localStorage.removeItem("activeGroupId");

      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({ prompt: "select_account" });
      const result = await signInWithPopup(auth, provider);
      const token = await result.user.getIdToken();
      return { user: result.user, token };
    } catch (error) {
      console.error("Google login error:", error);
      setError(error.message);
      throw error;
    }
  };

  const logout = async () => {
    try {
      // Clear active group when logging out
      localStorage.removeItem("activeGroupId");

      await signOut(auth);
      setUser(null);
      localStorage.removeItem("authUser");
    } catch (error) {
      console.error("Logout error:", error);
      setError(error.message);
      throw error;
    }
  };

  const refreshUserToken = async () => {
    try {
      const currentUser = auth.currentUser;
      if (currentUser) {
        // Check email verification for password providers
        if (
          currentUser.providerData.some(
            (provider) => provider.providerId === "password"
          ) &&
          !currentUser.emailVerified
        ) {
          // Force reload user to check current verification status
          await currentUser.reload();
          if (!currentUser.emailVerified) {
            await signOut(auth);
            setUser(null);
            localStorage.removeItem("authUser");
            throw new Error("Email verification required");
          }
        }

        const newToken = await currentUser.getIdToken(true);
        setUser((prevUser) => ({
          ...prevUser,
          token: newToken,
          emailVerified: currentUser.emailVerified,
        }));

        // Update token in localStorage
        const storedUser = JSON.parse(localStorage.getItem("authUser") || "{}");
        storedUser.token = newToken;
        storedUser.emailVerified = currentUser.emailVerified;
        localStorage.setItem("authUser", JSON.stringify(storedUser));

        return newToken;
      }
      return null;
    } catch (error) {
      console.error("Token refresh error:", error);
      setError(error.message);
      throw error;
    }
  };

  const clearError = () => setError(null);

  const value = {
    user,
    loading,
    error,
    login,
    loginWithGoogle,
    logout,
    refreshUserToken,
    clearError,
    isAuthenticated: !!user,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
