// src/components/Header.js
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { Menu, X, ChevronDown, User, LogOut, LogIn } from "lucide-react";
import { useGroup } from "../contexts/GroupContext";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const { user, logout } = useAuth();
  const { activeGroup } = useGroup();
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleUserMenu = () => {
    setShowUserMenu(!showUserMenu);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/login");
    } catch (error) {
      console.error("Failed to log out:", error);
    } finally {
      setShowUserMenu(false);
    }
  };

  return (
    <header className="w-full">
      {/* Banner Image - Centered with proper handling */}
      <div className="w-full h-16 sm:h-20 md:h-36 overflow-hidden relative bg-black flex justify-center items-center">
        <img
          src="/banner.jpg"
          alt="Renaissance Register Banner"
          className="h-full object-cover object-center"
          style={{ objectFit: "cover" }}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src =
              "https://via.placeholder.com/1000x100/2d3748/FFFFFF?text=RenRegister";
          }}
        />

        {/* Overlay for text/controls on the banner */}
        <div className="absolute inset-0 flex items-center justify-between px-4 md:px-8">
          <h1
            className="text-white text-xl sm:text-2xl md:text-3xl font-bold tracking-wider"
            style={{ textShadow: "2px 2px 4px rgba(0,0,0,0.7)" }}
          >
            RenRegister
          </h1>

          {/* User info / Login-Logout (Desktop) - Styled as a button */}
          <div className="hidden md:block">
            {user ? (
              <div className="relative">
                <button
                  onClick={toggleUserMenu}
                  className="flex items-center bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-4 rounded-md transition-colors"
                >
                  <User className="h-5 w-5 mr-2" />
                  <span className="mr-1 max-w-[150px] truncate">{user.email}</span>
                  <ChevronDown className="h-4 w-4" />
                </button>

                {/* User dropdown menu */}
                {showUserMenu && (
                  <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10">
                    <button
                      onClick={handleLogout}
                      className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                    >
                      <LogOut className="h-4 w-4 mr-2" />
                      Log Out
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <Link
                to="/login"
                className="flex items-center bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-4 rounded-md transition-colors"
              >
                <LogIn className="h-5 w-5 mr-2" />
                Sign In
              </Link>
            )}
          </div>

          {/* Mobile Menu Toggle */}
          <button
            className="md:hidden text-white p-2 rounded focus:outline-none"
            onClick={toggleMenu}
          >
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>
      </div>

      {/* Navigation Links - Desktop version - Centered items */}
      <nav className="hidden md:flex bg-indigo-900 text-white px-4 py-2 justify-center">
        <div className="max-w-6xl w-full flex justify-between">
          <div className="flex space-x-6">
            <Link to="/" className="hover:text-indigo-200 transition-colors">
              Home
            </Link>

            {user && activeGroup && activeGroup.class === "faire" && (
              <>
                <Link
                  to="/guilds"
                  className="hover:text-indigo-200 transition-colors"
                >
                  Guilds
                </Link>
                <Link
                  to="/stage-acts"
                  className="hover:text-indigo-200 transition-colors"
                >
                  Stage Acts
                </Link>
                <Link
                  to="/vendors"
                  className="hover:text-indigo-200 transition-colors"
                >
                  Vendors
                </Link>
                <Link
                  to="/events"
                  className="hover:text-indigo-200 transition-colors"
                >
                  Events
                </Link>
              </>
            )}
          </div>

          {user && (
            <div className="flex space-x-6">
              <Link
                to="/dashboard"
                className="hover:text-indigo-200 transition-colors"
              >
                Dashboard
              </Link>
              <Link
                to="/my-profile"
                className="hover:text-indigo-200 transition-colors"
              >
                Profile
              </Link>
            </div>
          )}
        </div>
      </nav>

      {/* Mobile Menu - Slide down when toggled */}
      <div
        className={`md:hidden bg-indigo-900 text-white overflow-hidden transition-all duration-300 ${isMenuOpen ? "max-h-screen" : "max-h-0"
          }`}
      >
        <div className="px-4 py-2 space-y-3">
          <Link
            to="/"
            className="block hover:text-indigo-200 transition-colors"
          >
            Home
          </Link>
          <Link
            to="/guilds"
            className="block hover:text-indigo-200 transition-colors"
          >
            Guilds
          </Link>
          <Link
            to="/stage-acts"
            className="block hover:text-indigo-200 transition-colors"
          >
            Stage Acts
          </Link>
          <Link
            to="/vendors"
            className="block hover:text-indigo-200 transition-colors"
          >
            Vendors
          </Link>
          <Link
            to="/events"
            className="block hover:text-indigo-200 transition-colors"
          >
            Events
          </Link>

          {user ? (
            <>
              <Link
                to="/dashboard"
                className="block hover:text-indigo-200 transition-colors"
              >
                Dashboard
              </Link>
              <Link
                to="/my-profile"
                className="block hover:text-indigo-200 transition-colors"
              >
                Profile
              </Link>
              <hr className="border-indigo-800 my-2" />
              <div className="flex items-center justify-between">
                <span className="text-sm opacity-80 truncate max-w-[160px]">{user.email}</span>
                <button
                  onClick={handleLogout}
                  className="flex items-center text-red-300 hover:text-red-200"
                >
                  <LogOut className="h-4 w-4 mr-1" />
                  Log Out
                </button>
              </div>
            </>
          ) : (
            <div className="flex flex-col space-y-2 pt-2 border-t border-indigo-800">
              <Link
                to="/login"
                className="flex items-center hover:text-indigo-200 transition-colors"
              >
                <LogIn className="h-4 w-4 mr-1" />
                Sign In
              </Link>
              <Link
                to="/create-account"
                className="block px-3 py-1 bg-indigo-700 hover:bg-indigo-600 rounded-md text-sm transition-colors text-center"
              >
                Create Account
              </Link>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;