// client/src/components/payment/PaymentPage.js
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import api from "../../utils/api";
import MainLayout from "../MainLayout";
import {
  CreditCard,
  DollarSign,
  Check,
  AlertTriangle,
  Loader,
} from "lucide-react";

const PaymentPage = () => {
  const { applicationId } = useParams();
  const navigate = useNavigate();
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [selectedProcessor, setSelectedProcessor] = useState("paypal");
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);

  // Fetch payment details on load
  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const response = await api.get(`/api/payments/${applicationId}`);
        setPaymentDetails(response.data);

        // Set Square as default payment processor
        setSelectedProcessor("square");

        setLoading(false);
      } catch (err) {
        console.error("Error fetching payment details:", err);
        setError("Failed to load payment details");
        setLoading(false);
      }
    };

    fetchDetails();
  }, [applicationId]);

  // Handle payment initiation
  const initiatePayment = async () => {
    try {
      setProcessing(true);
      setError(null);

      // Determine return URLs
      const returnUrl = `${window.location.origin}/applications/payment-complete/${applicationId}`;
      const cancelUrl = `${window.location.origin}/applications/payment/${applicationId}?cancelled=true`;

      // Initialize payment
      const response = await api.post("/api/payments/initialize", {
        applicationId,
        processor: selectedProcessor,
        returnUrl,
        cancelUrl,
      });

      // Redirect to payment processor
      window.location.href = response.data.redirectUrl;
    } catch (err) {
      console.error("Payment initiation error:", err);
      setError("Failed to initiate payment");
      setProcessing(false);
    }
  };

  if (loading) {
    return (
<MainLayout>
          <div className="max-w-3xl mx-auto flex justify-center items-center h-full">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
          </div>
          </MainLayout>
    );
  }

  // Handle payment options
  const renderPaymentOptions = () => {
    return (
      <div className="space-y-3">
        {/* Square (always available) */}
        <div className="relative">
          <input
            type="radio"
            id="square"
            name="paymentMethod"
            value="square"
            checked={selectedProcessor === "square"}
            onChange={() => setSelectedProcessor("square")}
            className="hidden"
          />
          <label
            htmlFor="square"
            className={`flex items-center p-4 border rounded-md cursor-pointer ${
              selectedProcessor === "square"
                ? "border-blue-500 bg-blue-50"
                : "border-gray-300"
            }`}
          >
            <div
              className={`w-5 h-5 rounded-full border mr-3 flex items-center justify-center ${
                selectedProcessor === "square"
                  ? "border-blue-500"
                  : "border-gray-400"
              }`}
            >
              {selectedProcessor === "square" && (
                <div className="w-3 h-3 rounded-full bg-blue-500"></div>
              )}
            </div>
            <div className="flex-1">
              <p className="font-medium">Square / Credit Card</p>
              <p className="text-sm text-gray-500">
                Pay securely with Square or any major credit card
              </p>
            </div>
            <CreditCard className="h-6 w-6 text-blue-500" />
          </label>
        </div>

        {/* PayPal (optional) */}
        {paymentDetails.paymentMethods.allowPaypal && (
          <div className="relative">
            <input
              type="radio"
              id="paypal"
              name="paymentMethod"
              value="paypal"
              checked={selectedProcessor === "paypal"}
              onChange={() => setSelectedProcessor("paypal")}
              className="hidden"
            />
            <label
              htmlFor="paypal"
              className={`flex items-center p-4 border rounded-md cursor-pointer ${
                selectedProcessor === "paypal"
                  ? "border-blue-500 bg-blue-50"
                  : "border-gray-300"
              }`}
            >
              <div
                className={`w-5 h-5 rounded-full border mr-3 flex items-center justify-center ${
                  selectedProcessor === "paypal"
                    ? "border-blue-500"
                    : "border-gray-400"
                }`}
              >
                {selectedProcessor === "paypal" && (
                  <div className="w-3 h-3 rounded-full bg-blue-500"></div>
                )}
              </div>
              <div className="flex-1">
                <p className="font-medium">PayPal</p>
                <p className="text-sm text-gray-500">
                  Pay securely with your PayPal account
                </p>
              </div>
              <DollarSign className="h-6 w-6 text-blue-500" />
            </label>
          </div>
        )}

        {/* Check (optional) */}
        {paymentDetails.paymentMethods.allowCheck === 1 && (
          <div>
            <div className="relative">
              <input
                type="radio"
                id="check"
                name="paymentMethod"
                value="check"
                checked={selectedProcessor === "check"}
                onChange={() => setSelectedProcessor("check")}
                className="hidden"
              />
              <label
                htmlFor="check"
                className={`flex items-center p-4 border rounded-md cursor-pointer ${
                  selectedProcessor === "check"
                    ? "border-blue-500 bg-blue-50"
                    : "border-gray-300"
                }`}
              >
                <div
                  className={`w-5 h-5 rounded-full border mr-3 flex items-center justify-center ${
                    selectedProcessor === "check"
                      ? "border-blue-500"
                      : "border-gray-400"
                  }`}
                >
                  {selectedProcessor === "check" && (
                    <div className="w-3 h-3 rounded-full bg-blue-500"></div>
                  )}
                </div>
                <div className="flex-1">
                  <p className="font-medium">Pay by Check</p>
                  <p className="text-sm text-gray-500">
                    Mail a check to the event organizer
                  </p>
                </div>
                <Check className="h-6 w-6 text-green-500" />
              </label>
            </div>

            {selectedProcessor === "check" && (
              <div className="mt-3 p-4 bg-gray-50 rounded-md">
                <h4 className="text-sm font-medium text-gray-700 mb-2">
                  Mailing Instructions
                </h4>
                <p className="text-sm text-gray-600 whitespace-pre-line">
                  {paymentDetails.application.CheckAddress ||
                    "Please make your check payable to the event organizer and mail it to the address provided in your confirmation email."}
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
<MainLayout>
        <div className="max-w-3xl mx-auto">
          <div className="bg-white shadow-sm rounded-lg overflow-hidden">
            <div className="px-6 py-5 border-b border-gray-200">
              <h1 className="text-xl font-medium text-gray-900">
                Complete Payment
              </h1>
            </div>

            <div className="px-6 py-5">
              {error && (
                <div className="mb-6 bg-red-50 border-l-4 border-red-500 p-4 text-red-700">
                  <div className="flex">
                    <AlertTriangle className="h-5 w-5 text-red-500 mr-2" />
                    {error}
                  </div>
                </div>
              )}

              {paymentDetails && (
                <div className="space-y-6">
                  <div className="bg-blue-50 p-5 rounded-lg">
                    <h2 className="text-lg font-medium text-blue-900 mb-2">
                      Payment Summary
                    </h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                      <div>
                        <p className="text-sm text-blue-800">Event:</p>
                        <p className="font-medium">
                          {paymentDetails.application.eventName}
                        </p>
                      </div>
                      <div>
                        <p className="text-sm text-blue-800">Group:</p>
                        <p className="font-medium">
                          {paymentDetails.application.group_name}
                        </p>
                      </div>
                    </div>
                    <div className="mt-4 text-center">
                      <p className="text-sm text-blue-800">Amount Due:</p>
                      <p className="text-3xl font-bold">
                        ${paymentDetails.application.pymt.toFixed(2)}
                      </p>
                    </div>
                  </div>

                  <div>
                    <h3 className="text-lg font-medium text-gray-900 mb-3">
                      Select Payment Method
                    </h3>
                    {renderPaymentOptions()}
                  </div>

                  <div className="flex justify-end pt-4">
                    <button
                      onClick={() =>
                        navigate(`/applications/view/${applicationId}`)
                      }
                      className="mr-3 px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                    >
                      Cancel
                    </button>

                    <button
                      onClick={initiatePayment}
                      disabled={processing || selectedProcessor === "check"}
                      className={`px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white ${
                        processing || selectedProcessor === "check"
                          ? "bg-blue-300 cursor-not-allowed"
                          : "bg-blue-600 hover:bg-blue-700"
                      }`}
                    >
                      {processing ? (
                        <>
                          <Loader className="animate-spin mr-2 h-4 w-4 inline" />
                          Processing...
                        </>
                      ) : (
                        <>
                          {selectedProcessor === "check"
                            ? "See Instructions Above"
                            : "Pay Now"}
                        </>
                      )}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        </MainLayout>
  );
};

export default PaymentPage;
