// src/components/GroupProfile.js
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
    ArrowLeft,
    User,
    Mail,
    Phone,
    MapPin,
    Globe,
    Calendar,
    Info,
    Tent,
    Home,
    Castle,
    Store,
    Theater,
    Users,
    Loader,
    AlertTriangle,
    CheckCircle
} from "lucide-react";
import api from "../utils/api";

const GroupProfile = () => {
    const { groupId } = useParams();
    const navigate = useNavigate();
    const [group, setGroup] = useState(null);
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchGroupData = async () => {
            try {
                setLoading(true);
                const response = await api.get(`/api/public/groups/detail/${groupId}`);
                setGroup(response.data);

                // Fetch recent events this group has participated in
                try {
                    const eventsResponse = await api.get(`/api/public/groups/${groupId}/events`);
                    setEvents(eventsResponse.data);
                } catch (eventsError) {
                    console.error("Error fetching group events:", eventsError);
                    // Don't set an error, just show empty events
                    setEvents([]);
                }

                setError(null);
            } catch (err) {
                console.error("Error fetching group details:", err);
                setError("Failed to load group profile");
            } finally {
                setLoading(false);
            }
        };

        if (groupId) {
            fetchGroupData();
        }
    }, [groupId]);

    const getGroupTypeIcon = (type) => {
        switch (type?.toLowerCase()) {
            case 'guild':
                return <Castle className="h-6 w-6 text-secondary-600" />;
            case 'vendor':
                return <Store className="h-6 w-6 text-accent-600" />;
            case 'act':
                return <Theater className="h-6 w-6 text-renaissance-burgundy" />;
            case 'faire':
                return <Tent className="h-6 w-6 text-renaissance-navy" />;
            case 'clan':
                return <Users className="h-6 w-6 text-green-600" />;
            default:
                return <User className="h-6 w-6 text-gray-600" />;
        }
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center min-h-screen bg-gray-50">
                <Loader className="h-12 w-12 text-primary-500 animate-spin" />
            </div>
        );
    }

    if (error) {
        return (
            <div className="max-w-4xl mx-auto px-4 py-8">
                <div className="bg-red-50 border-l-4 border-red-500 p-4 mb-6">
                    <div className="flex">
                        <AlertTriangle className="h-6 w-6 text-red-400" />
                        <div className="ml-3">
                            <p className="text-red-700">{error}</p>
                        </div>
                    </div>
                </div>
                <button
                    onClick={() => navigate(-1)}
                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700"
                >
                    <ArrowLeft className="h-4 w-4 mr-2" />
                    Go Back
                </button>
            </div>
        );
    }

    if (!group) {
        return (
            <div className="max-w-4xl mx-auto px-4 py-8">
                <div className="bg-yellow-50 border-l-4 border-yellow-500 p-4 mb-6">
                    <div className="flex">
                        <AlertTriangle className="h-6 w-6 text-yellow-400" />
                        <div className="ml-3">
                            <p className="text-yellow-700">Group not found</p>
                        </div>
                    </div>
                </div>
                <button
                    onClick={() => navigate(-1)}
                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700"
                >
                    <ArrowLeft className="h-4 w-4 mr-2" />
                    Go Back
                </button>
            </div>
        );
    }

    return (
        <div className="max-w-4xl mx-auto px-4 py-8">
            {/* Back button */}
            <button
                onClick={() => navigate(-1)}
                className="inline-flex items-center mb-6 px-3 py-1.5 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50"
            >
                <ArrowLeft className="h-4 w-4 mr-2" />
                Back
            </button>

            {/* Group Profile Header */}
            <div className="bg-white shadow rounded-lg overflow-hidden mb-6">
                <div className="px-6 py-5 border-b border-gray-200">
                    <div className="flex items-center">
                        <div className="flex-shrink-0">
                            <div className="h-16 w-16 rounded-full bg-primary-100 flex items-center justify-center">
                                {getGroupTypeIcon(group.class)}
                            </div>
                        </div>
                        <div className="ml-4">
                            <h1 className="text-2xl font-bold text-gray-900">{group.group_name}</h1>
                            <div className="mt-1 flex items-center">
                                <span className="capitalize text-sm font-medium text-gray-500">
                                    {group.class} {group.class === "vendor" ? "/ Merchant" : group.class === "guild" ? "/ Troupe" : ""}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Group information */}
                <div className="px-6 py-6">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-4">
                        {group.description && (
                            <div className="md:col-span-2">
                                <h3 className="text-sm font-medium text-gray-500">About</h3>
                                <div className="mt-1 text-gray-900 whitespace-pre-wrap">
                                    {group.description}
                                </div>
                            </div>
                        )}

                        {group.contact_name && (
                            <div>
                                <h3 className="text-sm font-medium text-gray-500">Contact</h3>
                                <div className="mt-1 flex items-center text-gray-900">
                                    <User className="h-4 w-4 text-gray-400 mr-1.5" />
                                    {group.contact_name}
                                </div>
                            </div>
                        )}

                        {group.email && (
                            <div>
                                <h3 className="text-sm font-medium text-gray-500">Email</h3>
                                <div className="mt-1 flex items-center">
                                    <Mail className="h-4 w-4 text-gray-400 mr-1.5" />
                                    <a href={`mailto:${group.email}`} className="text-primary-600 hover:text-primary-800">
                                        {group.email}
                                    </a>
                                </div>
                            </div>
                        )}

                        {group.contact_phone && (
                            <div>
                                <h3 className="text-sm font-medium text-gray-500">Phone</h3>
                                <div className="mt-1 flex items-center text-gray-900">
                                    <Phone className="h-4 w-4 text-gray-400 mr-1.5" />
                                    {group.contact_phone}
                                </div>
                            </div>
                        )}

                        {group.zip && (
                            <div>
                                <h3 className="text-sm font-medium text-gray-500">Location</h3>
                                <div className="mt-1 flex items-center text-gray-900">
                                    <MapPin className="h-4 w-4 text-gray-400 mr-1.5" />
                                    {group.city ? `${group.city}, ` : ''}{group.state ? `${group.state}, ` : ''}{group.zip}
                                </div>
                            </div>
                        )}

                        {group.group_website && (
                            <div>
                                <h3 className="text-sm font-medium text-gray-500">Website</h3>
                                <div className="mt-1 flex items-center">
                                    <Globe className="h-4 w-4 text-gray-400 mr-1.5" />
                                    <a
                                        href={group.group_website.startsWith('http') ? group.group_website : `https://${group.group_website}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-primary-600 hover:text-primary-800 truncate"
                                    >
                                        {group.group_website}
                                    </a>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* Group Type Specific Information */}
            {(group.class === "guild" || group.class === "vendor" || group.class === "act") && (
                <div className="bg-white shadow rounded-lg overflow-hidden mb-6">
                    <div className="px-6 py-5 border-b border-gray-200">
                        <h2 className="text-lg font-medium text-gray-900 flex items-center">
                            {group.class === "guild" ?
                                <Castle className="h-5 w-5 mr-2 text-secondary-500" /> :
                                group.class === "vendor" ?
                                    <Store className="h-5 w-5 mr-2 text-accent-500" /> :
                                    <Theater className="h-5 w-5 mr-2 text-renaissance-burgundy" />
                            }
                            {group.class === "guild" ? "Guild Information" :
                                group.class === "vendor" ? "Vendor Information" : "Performer Information"}
                        </h2>
                    </div>

                    <div className="px-6 py-5">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            {/* Vendor-specific fields */}
                            {group.class === "vendor" && (
                                <>
                                    {group.v_ca_resaleNumber && (
                                        <div>
                                            <h3 className="text-sm font-medium text-gray-500">CA Resale Number</h3>
                                            <p className="mt-1 text-gray-900">{group.v_ca_resaleNumber}</p>
                                        </div>
                                    )}

                                    <div>
                                        <h3 className="text-sm font-medium text-gray-500">Utilities Requirements</h3>
                                        <div className="mt-1 space-y-1">
                                            <p className="text-gray-900">
                                                Needs Water: {group.v_needWater ? "Yes" : "No"}
                                            </p>
                                            <p className="text-gray-900">
                                                Needs Power: {group.v_needPower ? "Yes" : "No"}
                                            </p>
                                        </div>
                                    </div>
                                </>
                            )}

                            {/* Performer-specific fields */}
                            {group.class === "act" && (
                                <>
                                    {group.stage_req === "Yes" && (
                                        <div>
                                            <h3 className="text-sm font-medium text-gray-500">Stage Requirements</h3>
                                            <div className="mt-1 space-y-1">
                                                {group.stage_time && (
                                                    <p className="text-gray-900">
                                                        Performance Time: {group.stage_time} minutes
                                                    </p>
                                                )}
                                                {group.stage_no && (
                                                    <p className="text-gray-900">
                                                        Performances Per Day: {group.stage_no}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}

                            {/* Common fields for space requirements */}
                            {group.camp_req === "Yes" && (
                                <div>
                                    <h3 className="text-sm font-medium text-gray-500">Space Requirements</h3>
                                    <div className="mt-1 space-y-1">
                                        {group.frontage && group.depth && (
                                            <p className="text-gray-900">
                                                Space Size: {group.frontage}' × {group.depth}'
                                            </p>
                                        )}
                                        {group.haybale_req === "Yes" && group.haybale_no && (
                                            <p className="text-gray-900">
                                                Hay Bales Needed: {group.haybale_no}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            )}

                            {/* Staff/Members count */}
                            {group.howmany && (
                                <div>
                                    <h3 className="text-sm font-medium text-gray-500">
                                        {group.class === "act"
                                            ? "Number of Performers"
                                            : group.class === "vendor"
                                                ? "Staff Count"
                                                : "Member Count"}
                                    </h3>
                                    <p className="mt-1 text-gray-900">{group.howmany} people</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}

            {/* Recent Events */}
            {events.length > 0 && (
                <div className="bg-white shadow rounded-lg overflow-hidden">
                    <div className="px-6 py-5 border-b border-gray-200">
                        <h2 className="text-lg font-medium text-gray-900 flex items-center">
                            <Calendar className="h-5 w-5 mr-2 text-primary-500" />
                            Recent Events
                        </h2>
                    </div>

                    <div className="px-6 py-5">
                        <ul className="divide-y divide-gray-200">
                            {events.map((event) => (
                                <li key={event.eventID} className="py-4">
                                    <div className="flex items-center justify-between">
                                        <div>
                                            <h3 className="text-sm font-medium text-gray-900">{event.eventName}</h3>
                                            <div className="mt-1 flex items-center text-sm text-gray-500">
                                                <Calendar className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" />
                                                {new Date(event.event_startdate).toLocaleDateString()}
                                                {event.eventLocation && (
                                                    <>
                                                        <span className="mx-2">•</span>
                                                        <MapPin className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" />
                                                        {event.eventLocation}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
};

export default GroupProfile;