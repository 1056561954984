// client/src/components/ApplicationMessageInterface.js
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../utils/api";
import { format } from "date-fns";
import {
  ArrowLeft,
  Send,
  RefreshCw,
  User,
  UserCircle,
  Calendar,
  AlertTriangle,
  Loader,
  MessageSquare,
  CheckCircle,
  HelpCircle,
} from "lucide-react";

const ApplicationMessageInterface = () => {
  const { eventId, applicationId } = useParams();
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [application, setApplication] = useState(null);
  const [group, setGroup] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [error, setError] = useState(null);
  const [needsMoreInfo, setNeedsMoreInfo] = useState(false);
  const messagesEndRef = useRef(null);

  // Scroll to the bottom of the messages
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // Fetch data on mount and when messages are updated

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        // Fetch application details
        const appResponse = await api.get(
          `/api/events/${eventId}/applications/${applicationId}`
        );
        setApplication(appResponse.data.application);

        try {
          // Try to fetch group details - but don't fail the entire request if it fails
          const groupResponse = await api.get(
            `/api/admin/groups/${appResponse.data.application.groupID}`
          );
          setGroup(groupResponse.data);
        } catch (groupErr) {
          console.error("Error fetching group details:", groupErr);
          // Still set basic group info from the application data
          setGroup({
            groupID: appResponse.data.application.groupID,
            group_name: appResponse.data.application.group_name,
            class: appResponse.data.application.class,
            contact_name: appResponse.data.application.contact_name,
            email: appResponse.data.application.email,
          });
        }

        // Fetch messages
        const messagesResponse = await api.get(
          `/api/events/${eventId}/messages/${applicationId}`
        );
        setMessages(messagesResponse.data);

        setError(null);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to load messages");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [eventId, applicationId]);

  // Scroll to bottom when messages change
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Format date function
  const formatDate = (dateString) => {
    try {
      return format(new Date(dateString), "MMM d, yyyy h:mm a");
    } catch (e) {
      return "Unknown date";
    }
  };

  // Refresh messages
  const handleRefreshMessages = async () => {
    try {
      setRefreshing(true);
      const response = await api.get(
        `/api/events/${eventId}/messages/${applicationId}`
      );
      setMessages(response.data);
      setError(null);
    } catch (err) {
      console.error("Error refreshing messages:", err);
      setError("Failed to refresh messages");
    } finally {
      setRefreshing(false);
    }
  };

  // Send a new message
  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;

    try {
      setSending(true);

      // Determine if we should update the status
      const updateStatus = needsMoreInfo ? "needInfo" : undefined;

      await api.post(
        `/api/events/${eventId}/messages/${applicationId}?updateStatus=${updateStatus}`,
        {
          body: newMessage,
        }
      );

      // Clear input and reload messages
      setNewMessage("");
      await handleRefreshMessages();

      // If we requested more info, refresh the application to get updated status
      if (needsMoreInfo) {
        const appResponse = await api.get(
          `/api/events/${eventId}/applications/${applicationId}`
        );
        setApplication(appResponse.data.application);
        setNeedsMoreInfo(false);
      }
    } catch (err) {
      console.error("Error sending message:", err);
      setError("Failed to send message");
    } finally {
      setSending(false);
    }
  };

  if (loading) {
    return (
      <div className="max-w-4xl mx-auto flex justify-center items-center h-full">
        <Loader className="animate-spin h-12 w-12 text-blue-500" />
      </div>
    );
  }

  if (error) {
    return (

      <div className="max-w-4xl mx-auto">
        <div className="bg-red-50 border-l-4 border-red-500 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <AlertTriangle className="h-5 w-5 text-red-400" />
            </div>
            <div className="ml-3">
              <p className="text-sm text-red-700">{error}</p>
            </div>
          </div>
        </div>
        <button
          onClick={() =>
            navigate(`/events/${eventId}/applications/${applicationId}`)
          }
          className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
        >
          <ArrowLeft className="w-5 h-5 mr-2" />
          Back to Application
        </button>
      </div>

    );
  }

  return (

    <div className="max-w-4xl mx-auto">
      {/* Header */}
      <div className="bg-white shadow rounded-lg mb-6">
        <div className="px-6 py-5">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <button
                onClick={() =>
                  navigate(
                    `/events/${eventId}/applications/${applicationId}`
                  )
                }
                className="mr-4 p-2 rounded-full hover:bg-gray-100"
              >
                <ArrowLeft className="w-5 h-5" />
              </button>
              <div>
                <h1 className="text-2xl font-bold text-gray-900">
                  Messages for Application #{applicationId}
                </h1>
                <div className="flex items-center mt-1">
                  <p className="text-sm text-gray-500">
                    Group: {group?.group_name} ({group?.class})
                  </p>
                </div>
              </div>
            </div>

            <button
              onClick={handleRefreshMessages}
              className={`p-2 rounded-full hover:bg-gray-100 ${refreshing ? "text-blue-500" : "text-gray-500"
                }`}
              disabled={refreshing}
            >
              <RefreshCw
                className={`w-5 h-5 ${refreshing ? "animate-spin" : ""}`}
              />
            </button>
          </div>
        </div>
      </div>

      {/* Messages Section */}
      <div className="bg-white shadow rounded-lg overflow-hidden mb-6">
        <div className="px-6 py-5 border-b border-gray-200">
          <h2 className="text-lg font-medium text-gray-900">
            Conversation
          </h2>
        </div>

        <div className="px-6 py-5">
          {/* Messages list */}
          <div className="mb-6 space-y-4 max-h-96 overflow-y-auto">
            {messages.length === 0 ? (
              <div className="text-center py-12">
                <div className="mx-auto h-16 w-16 bg-gray-100 rounded-full flex items-center justify-center mb-4">
                  <MessageSquare className="h-8 w-8 text-gray-400" />
                </div>
                <h3 className="text-sm font-medium text-gray-900 mb-1">
                  No messages yet
                </h3>
                <p className="text-sm text-gray-500 max-w-sm mx-auto">
                  Start a conversation with the applicant by sending a
                  message below.
                </p>
              </div>
            ) : (
              messages.map((message) => (
                <div
                  key={message.msgID}
                  className={`p-4 rounded-lg ${message.isFromOrganizer
                    ? "bg-blue-50 ml-8"
                    : "bg-gray-50 mr-8"
                    }`}
                >
                  <div className="flex items-start mb-1">
                    <div
                      className={`flex-shrink-0 mr-2 ${message.isFromOrganizer
                        ? "text-blue-600"
                        : "text-gray-600"
                        }`}
                    >
                      {message.isFromOrganizer ? (
                        <UserCircle className="w-5 h-5" />
                      ) : (
                        <User className="w-5 h-5" />
                      )}
                    </div>
                    <div className="flex-1">
                      <p className="text-sm font-medium">
                        {message.isFromOrganizer
                          ? "Event Organizer"
                          : group?.group_name}
                      </p>
                      <p className="text-xs text-gray-500">
                        {formatDate(message.sdate)}
                      </p>
                    </div>
                  </div>
                  <div className="pl-7">
                    <p className="text-sm text-gray-800 whitespace-pre-wrap">
                      {message.body}
                    </p>
                  </div>
                </div>
              ))
            )}
            <div ref={messagesEndRef} />
          </div>

          {/* New message form */}
          <div className="mt-6 bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden">
            <div className="p-3 bg-gray-50 border-b border-gray-200 flex justify-between items-center">
              <h3 className="text-sm font-medium text-gray-700">
                New Message
              </h3>

              {/* Request more info checkbox - only show if status is "Pending" */}
              {application?.status === "Pending" && (
                <div className="flex items-center">
                  <input
                    id="needsMoreInfo"
                    name="needsMoreInfo"
                    type="checkbox"
                    checked={needsMoreInfo}
                    onChange={(e) => setNeedsMoreInfo(e.target.checked)}
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  />
                  <label
                    htmlFor="needsMoreInfo"
                    className="ml-2 block text-xs text-gray-700"
                  >
                    Request more information
                  </label>
                  <div className="ml-1 group relative">
                    <HelpCircle className="w-3 h-3 text-gray-400" />
                    <div className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 invisible group-hover:visible bg-gray-900 text-white text-xs rounded py-1 px-2 w-48">
                      This will change the application status to "Pending -
                      Further Information Needed"
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="p-4">
              <textarea
                className="w-full p-3 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 transition-colors duration-200"
                rows="4"
                placeholder="Type your message to the applicant..."
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                disabled={sending}
              ></textarea>
              <div className="mt-3 flex justify-end">
                <button
                  onClick={handleSendMessage}
                  disabled={sending || !newMessage.trim()}
                  className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white transition-colors duration-200 ${sending || !newMessage.trim()
                    ? "bg-blue-300 cursor-not-allowed"
                    : "bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    }`}
                >
                  {sending ? (
                    <>
                      <Loader className="animate-spin mr-2 h-4 w-4" />
                      Sending...
                    </>
                  ) : (
                    <>
                      <Send className="mr-2 h-4 w-4" />
                      Send Message
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default ApplicationMessageInterface;
