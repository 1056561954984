// client/src/components/MyApplications.js
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import MainLayout from "../MainLayout";
import api from "../../utils/api";
import { format } from "date-fns";
import ApplicationStatusBadge from "../ApplicationStatusBadge";
import {
  APPLICATION_STATUS,
  canCancelApplication,
  needsPayment,
  willBePaid,
  needsGateList,
  needsTermsAcceptance,
} from "../../utils/applicationStatusUtils";
import {
  Calendar,
  Eye,
  FileText,
  Clock,
  AlertTriangle,
  CheckCircle,
  XCircle,
  Filter,
  ExternalLink,
  DollarSign,
  MessageSquare,
  Users,
  Plus,
} from "lucide-react";
import { useGroup } from "../../contexts/GroupContext";
import {
  Card,
  CardHeader,
  CardContent,
  CardFooter,
  EventCard,
  FeatureCard,
} from "../ui/Card";
import { Button } from "../ui/Button";
import { Alert } from "../ui/Alert";

const MyApplications = () => {
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [filterStatus, setFilterStatus] = useState("all");
  const { activeGroup } = useGroup();
  const navigate = useNavigate();
  const location = useLocation();
  const [timeFilter, setTimeFilter] = useState("upcoming");

  // Check for success message in location state
  useEffect(() => {
    if (location.state?.message) {
      setSuccessMessage(location.state.message);
      // Clear the message from the location state
      window.history.replaceState({}, document.title);
    }
  }, [location]);

  // Fetch applications
  useEffect(() => {
    const fetchApplications = async () => {
      setLoading(true);
      try {
        const response = await api.get(`/api/applications?${timeFilter}=true`);
        setApplications(response.data);
        setError(null);
      } catch (err) {
        console.error("Error fetching applications:", err);
        setError("Failed to load applications");
      } finally {
        setLoading(false);
      }
    };

    fetchApplications();
  }, [timeFilter]);

  const handleViewEvent = (eventId) => {
    navigate(`/events/view/${eventId}`);
  };

  const handleViewApplication = (applicationId) => {
    navigate(`/applications/view/${applicationId}`);
  };

  const handlePayForApplication = (applicationId) => {
    navigate(`/applications/payment/${applicationId}`);
  };

  const handleSubmitGateList = (applicationId) => {
    navigate(`/applications/gatelist/${applicationId}`);
  };

  const handleReviewTerms = (applicationId) => {
    navigate(`/applications/view/${applicationId}`, {
      state: { tab: "terms" },
    });
  };

  const handleMessagesForApplication = (applicationId) => {
    navigate(`/applications/view/${applicationId}`, {
      state: { tab: "communication" },
    });
  };

  const handleCancelApplication = async (applicationId, eventDate) => {
    if (!window.confirm("Are you sure you want to cancel this application?")) {
      return;
    }

    try {
      await api.delete(`/api/applications/${applicationId}`);

      // Update local state
      setApplications(
        applications.map((app) =>
          app.statusID === parseInt(applicationId)
            ? { ...app, status: APPLICATION_STATUS.REMOVED_SELF }
            : app
        )
      );

      setSuccessMessage("Application cancelled successfully");
    } catch (err) {
      console.error("Error cancelling application:", err);
      setError(
        "Failed to cancel application. " + (err.response?.data?.error || "")
      );
    }
  };

  const handleApplyToNewEvent = () => {
    navigate("/events");
  };

  // Format date function
  const formatDate = (dateString) => {
    try {
      return format(new Date(dateString), "MMM d, yyyy");
    } catch (e) {
      return "Invalid date";
    }
  };

  // Filter applications based on status
  const getFilteredApplications = () => {
    if (filterStatus === "all") {
      return applications;
    } else if (filterStatus === "active") {
      return applications.filter(
        (app) =>
          !app.status.startsWith("Removed") &&
          app.status !== APPLICATION_STATUS.DECLINED &&
          app.status !== APPLICATION_STATUS.EVENT_CANCELLED
      );
    } else if (filterStatus === "pending") {
      return applications.filter(
        (app) =>
          app.status === APPLICATION_STATUS.PENDING ||
          app.status === APPLICATION_STATUS.PENDING_INFO
      );
    } else if (filterStatus === "accepted") {
      return applications.filter((app) => app.status.startsWith("Accepted"));
    } else if (filterStatus === "cancelled") {
      return applications.filter(
        (app) =>
          app.status.startsWith("Removed") ||
          app.status === APPLICATION_STATUS.DECLINED ||
          app.status === APPLICATION_STATUS.EVENT_CANCELLED
      );
    }
    return applications;
  };

  // Get action buttons based on application status
  const getActionButtons = (application) => {
    const buttons = [];

    // Always include view details button
    buttons.push(
      <button
        key="view"
        onClick={() => handleViewApplication(application.statusID)}
        className="inline-flex items-center px-2 py-1 text-xs font-medium rounded text-blue-700 bg-blue-100 hover:bg-blue-200"
      >
        <Eye className="w-3 h-3 mr-1" />
        Details
      </button>
    );

    // Status-specific actions
    if (application.status === APPLICATION_STATUS.PENDING_INFO) {
      buttons.push(
        <button
          key="messages"
          onClick={() => handleMessagesForApplication(application.statusID)}
          className="inline-flex items-center px-2 py-1 text-xs font-medium rounded text-yellow-700 bg-yellow-100 hover:bg-yellow-200"
        >
          <MessageSquare className="w-3 h-3 mr-1" />
          Respond
        </button>
      );
    }

    if (needsPayment(application)) {
      buttons.push(
        <button
          key="pay"
          onClick={() => handlePayForApplication(application.statusID)}
          className="inline-flex items-center px-2 py-1 text-xs font-medium rounded text-green-700 bg-green-100 hover:bg-green-200"
        >
          <DollarSign className="w-3 h-3 mr-1" />
          Pay
        </button>
      );
    }

    if (needsGateList(application)) {
      buttons.push(
        <button
          key="gatelist"
          onClick={() => handleSubmitGateList(application.statusID)}
          className="inline-flex items-center px-2 py-1 text-xs font-medium rounded text-green-700 bg-green-100 hover:bg-green-200"
        >
          <Users className="w-3 h-3 mr-1" />
          Gate List
        </button>
      );
    }

    if (needsTermsAcceptance(application)) {
      buttons.push(
        <button
          key="terms"
          onClick={() => handleReviewTerms(application.statusID)}
          className="inline-flex items-center px-2 py-1 text-xs font-medium rounded text-green-700 bg-green-100 hover:bg-green-200"
        >
          <FileText className="w-3 h-3 mr-1" />
          Review Terms
        </button>
      );
    }

    // Cancel button if applicable
    if (canCancelApplication(application.status, application.event_startdate)) {
      buttons.push(
        <button
          key="cancel"
          onClick={() =>
            handleCancelApplication(
              application.statusID,
              application.event_startdate
            )
          }
          className="inline-flex items-center px-2 py-1 text-xs font-medium rounded text-red-700 bg-red-100 hover:bg-red-200"
        >
          <XCircle className="w-3 h-3 mr-1" />
          Cancel
        </button>
      );
    }

    return buttons;
  };

  return (
    <main className="flex-1 p-4 sm:p-6 md:p-8">
      <div className="max-w-6xl mx-auto">
        <div className="flex justify-between items-center mb-6">
          <div className="flex items-center">
            <h1 className="text-2xl font-bold text-gray-900 mr-4">
              My Applications
            </h1>

            {/* Group Selector Info */}
            {activeGroup && (
              <div className="px-3 py-2 bg-gray-100 border rounded-lg text-sm">
                <span className="text-gray-600 mr-2">Selected Group:</span>
                <span className="font-medium">{activeGroup.group_name}</span>
              </div>
            )}
          </div>
          <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-4">
            {/* Time Filter Dropdown */}
            <div className="relative mr-3">
              <label htmlFor="time-filter" className="sr-only">
                Filter by time
              </label>
              <div className="flex items-center">
                <Clock className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                <select
                  id="time-filter"
                  value={timeFilter}
                  onChange={(e) => setTimeFilter(e.target.value)}
                  className="appearance-none block w-full pl-10 pr-10 py-2 border border-gray-300 rounded-md shadow-sm bg-white focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-sm"
                >
                  <option value="upcoming">Upcoming Events</option>
                  <option value="past">Past Events</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400">
                  <svg
                    className="h-4 w-4"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </div>
            </div>
            {/* Filter Dropdown */}
            <div className="relative">
              <label htmlFor="status-filter" className="sr-only">
                Filter by status
              </label>
              <div className="flex items-center">
                <Filter className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                <select
                  id="status-filter"
                  value={filterStatus}
                  onChange={(e) => setFilterStatus(e.target.value)}
                  className="appearance-none block w-full pl-10 pr-10 py-2 border border-gray-300 rounded-md shadow-sm bg-white focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-sm"
                >
                  <option value="all">All Applications</option>
                  <option value="active">Active</option>
                  <option value="pending">Pending Review</option>
                  <option value="accepted">Accepted</option>
                  <option value="cancelled">Cancelled/Declined</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 text-gray-400">
                  <svg
                    className="h-4 w-4"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <button
              onClick={handleApplyToNewEvent}
              className="flex items-center justify-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <Plus className="w-5 h-5 mr-2" />
              Apply to Event
            </button>
          </div>
        </div>

        {/* Success message */}
        {successMessage && (
          <div className="mb-6 bg-green-50 border-l-4 border-green-400 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <CheckCircle className="h-5 w-5 text-green-400" />
              </div>
              <div className="ml-3">
                <p className="text-sm text-green-700">{successMessage}</p>
              </div>
              <div className="ml-auto pl-3">
                <div className="-mx-1.5 -my-1.5">
                  <button
                    onClick={() => setSuccessMessage(null)}
                    className="inline-flex rounded-md p-1.5 text-green-500 hover:bg-green-100"
                  >
                    <XCircle className="h-4 w-4" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Error message */}
        {error && (
          <div className="mb-6 bg-red-50 border-l-4 border-red-400 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <AlertTriangle className="h-5 w-5 text-red-400" />
              </div>
              <div className="ml-3">
                <p className="text-sm text-red-700">{error}</p>
              </div>
              <div className="ml-auto pl-3">
                <div className="-mx-1.5 -my-1.5">
                  <button
                    onClick={() => setError(null)}
                    className="inline-flex rounded-md p-1.5 text-red-500 hover:bg-red-100"
                  >
                    <XCircle className="h-4 w-4" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {loading ? (
          <div className="flex justify-center p-8">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
          </div>
        ) : getFilteredApplications().length === 0 ? (
          <div className="bg-white rounded-lg shadow p-8 text-center">
            <h3 className="text-lg font-medium text-gray-900 mb-2">
              No Applications Found
            </h3>
            <p className="text-gray-600 mb-4">
              {filterStatus !== "all"
                ? `You have no applications with the "${filterStatus}" status.`
                : "You haven't applied to any events yet."}
            </p>
            <button
              onClick={handleApplyToNewEvent}
              className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
            >
              <Calendar className="w-5 h-5 mr-2" />
              Browse Available Events
            </button>
          </div>
        ) : (
          <div className="bg-white rounded-lg shadow overflow-hidden">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Event
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Event Date
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Applied
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {getFilteredApplications().map((application) => (
                  <tr key={application.statusID} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div>
                          <div className="text-sm font-medium text-gray-900">
                            {application.eventName}
                          </div>
                          <div className="text-xs text-gray-500 capitalize">
                            Applied as: {application.group_name} (
                            {application.class})
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {formatDate(application.event_startdate)}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">
                        {formatDate(application.recDate)}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <ApplicationStatusBadge status={application.status} />

                      {/* Show payment info if appropriate */}
                      {needsPayment(application) && (
                        <div className="mt-1 text-xs text-gray-500 flex items-center">
                          <DollarSign className="w-3 h-3 mr-1 text-yellow-500" />
                          Payment: ${application.pymt.toFixed(2)}
                        </div>
                      )}

                      {/* Show compensation if performer */}
                      {willBePaid(application) && (
                        <div className="mt-1 text-xs text-gray-500 flex items-center">
                          <DollarSign className="w-3 h-3 mr-1 text-green-500" />
                          Compensation: ${application.pymt.toFixed(2)}
                        </div>
                      )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <div className="flex justify-end space-x-2">
                        {getActionButtons(application)}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {/* Footer */}
        <footer className="mt-8 text-center text-gray-500 text-sm">
          Copyright by Renaissance Productions 2006-{new Date().getFullYear()}
        </footer>
      </div>
    </main>
  );
};

export default MyApplications;
