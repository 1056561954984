import axios from "axios";
import { auth } from "../config/firebase";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "/api", // Use environment variable or default to '/api'
});

// Request interceptor to add auth token
api.interceptors.request.use(
  async (config) => {
    try {
      // Get current user
      const currentUser = auth.currentUser;

      if (currentUser) {
        // Get fresh token directly from Firebase
        const token = await currentUser.getIdToken(true);
        config.headers.Authorization = `Bearer ${token}`;

        // Update token in localStorage for future use
        const authUser = JSON.parse(localStorage.getItem("authUser") || "{}");
        authUser.token = token;
        localStorage.setItem("authUser", JSON.stringify(authUser));
      }

      // Add active group ID if available
      const activeGroupId = localStorage.getItem("activeGroupId");
      if (activeGroupId) {
        config.headers["Active-Group-Id"] = activeGroupId;
      }

      return config;
    } catch (error) {
      console.error("Error getting authentication token:", error);
      return config;
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle common errors
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // Handle 401 Unauthorized errors
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      try {
        // Try to get a fresh token
        const currentUser = auth.currentUser;
        if (currentUser) {
          const newToken = await currentUser.getIdToken(true);
          originalRequest.headers.Authorization = `Bearer ${newToken}`;

          // Update token in localStorage
          const authUser = JSON.parse(localStorage.getItem("authUser") || "{}");
          authUser.token = newToken;
          localStorage.setItem("authUser", JSON.stringify(authUser));

          return axios(originalRequest);
        }
      } catch (tokenError) {
        console.error("Error refreshing token:", tokenError);
      }

      // If we couldn't get a new token, redirect to login
      localStorage.removeItem("authUser");
      window.location.href = "/login";
    }

    return Promise.reject(error);
  }
);

// Check if the user profile exists
export const checkUserProfile = async () => {
  try {
    const response = await api.get("/api/profiles/me");
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return null; // Profile doesn't exist
    }
    throw error;
  }
};

// Create or update user profile
export const createUserProfile = async (profileData) => {
  try {
    const response = await api.post("/api/profiles", profileData);
    return response.data;
  } catch (error) {
    console.error("Error creating profile:", error);
    throw error;
  }
};

// Check if user has any groups
export const fetchUserGroups = async () => {
  try {
    const response = await api.get("/api/my-groups");
    return response.data;
  } catch (error) {
    console.error("Error fetching user groups:", error);
    throw error;
  }
};

export default api;
