import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { sendEmailVerification } from "firebase/auth";
import { auth } from "../config/firebase";

const VerifyEmailPage = () => {
  const { user, loading } = useAuth();
  const [sending, setSending] = useState(false);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [countdown, setCountdown] = useState(0);
  const navigate = useNavigate();

  // Check if user is already verified or is using OAuth
  useEffect(() => {
    if (!loading) {
      if (!user) {
        // Not logged in, redirect to login
        navigate("/login");
      } else {
        // Check if email password user and if already verified
        const isEmailPasswordUser = user.providerData.some(
          (provider) => provider.providerId === "password"
        );

        if (!isEmailPasswordUser || user.emailVerified) {
          // OAuth user or already verified, redirect to dashboard
          navigate("/dashboard");
        }
      }
    }
  }, [user, loading, navigate]);

  // Countdown timer for resend button
  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [countdown]);

  const handleResendVerification = async () => {
    if (countdown > 0) return;

    setSending(true);
    setMessage("");
    setError("");

    try {
      await sendEmailVerification(auth.currentUser);
      setMessage("Verification email sent! Please check your inbox.");
      // Set countdown to 60 seconds
      setCountdown(60);
    } catch (err) {
      console.error("Error sending verification email:", err);
      setError(
        err.message ||
          "Failed to send verification email. Please try again later."
      );
    } finally {
      setSending(false);
    }
  };

  // Handle manual refresh to check verification status
  const handleRefreshStatus = async () => {
    try {
      setSending(true);
      // Force refresh the current user data
      await auth.currentUser.reload();

      // If now verified, navigate to dashboard
      if (auth.currentUser.emailVerified) {
        navigate("/dashboard");
      } else {
        setMessage(
          "Your email is still not verified. Please check your inbox for the verification link."
        );
      }
    } catch (error) {
      console.error("Error refreshing verification status:", error);
      setError("Failed to refresh verification status. Please try again.");
    } finally {
      setSending(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Email Verification Required
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          Please verify your email address before continuing
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <div className="text-center">
            <div className="rounded-full bg-blue-100 p-3 mx-auto w-16 h-16 flex items-center justify-center mb-6">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8 text-blue-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                />
              </svg>
            </div>

            <h3 className="text-lg font-medium text-gray-900 mb-2">
              Verify Your Email
            </h3>

            <p className="text-gray-600 mb-6">
              We've sent a verification email to{" "}
              <span className="font-medium">{user?.email}</span>. Please check
              your inbox and click the verification link to activate your
              account.
            </p>

            {message && (
              <div className="mb-4 p-3 bg-green-50 rounded-md text-green-800">
                {message}
              </div>
            )}

            {error && (
              <div className="mb-4 p-3 bg-red-50 rounded-md text-red-800">
                {error}
              </div>
            )}

            <div className="space-y-3">
              <button
                type="button"
                onClick={handleRefreshStatus}
                disabled={sending}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-blue-300"
              >
                {sending ? "Checking..." : "I've Verified My Email"}
              </button>

              <button
                type="button"
                onClick={handleResendVerification}
                disabled={sending || countdown > 0}
                className="w-full flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:bg-gray-100 disabled:text-gray-400"
              >
                {sending
                  ? "Sending..."
                  : countdown > 0
                  ? `Resend Email (${countdown}s)`
                  : "Resend Verification Email"}
              </button>

              <button
                type="button"
                onClick={() => navigate("/login")}
                className="w-full flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
              >
                Back to Login
              </button>
            </div>

            <div className="mt-6 text-sm text-gray-500">
              <p>
                If you're having trouble, please contact our support team for
                assistance.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmailPage;
