import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import MainLayout from "./MainLayout";
import GateListForm from "./GateListForm";
import LoadingSkeleton from "./LoadingSkeleton";
import ApplicationStatusBadge from "./ApplicationStatusBadge";
import api from "../utils/api";
import { ArrowLeft, AlertTriangle } from "lucide-react";
import { needsGateList } from "../utils/applicationStatusUtils";

const ApplicationPage = () => {
  const { applicationId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [application, setApplication] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchApplication = async () => {
      try {
        setLoading(true);
        const response = await api.get(`/api/applications/${applicationId}`);
        setApplication(response.data.application);
      } catch (err) {
        console.error("Error loading application", err);
        setError("Failed to load application data");
      } finally {
        setLoading(false);
      }
    };

    fetchApplication();
  }, [applicationId]);

  const handleGateListComplete = () => {
    // Refresh the application data after gate list submission
    navigate(`/applications/view/${applicationId}`, {
      state: { message: "Gate list submitted successfully!" },
    });
  };

  if (loading) {
    return (
<MainLayout>
          <div className="max-w-4xl mx-auto">
            <LoadingSkeleton type="application-detail" />
          </div>
          </MainLayout>
    );
  }

  if (error) {
    return (
<MainLayout>
          <div className="max-w-4xl mx-auto">
            <div className="bg-red-50 border-l-4 border-red-500 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <AlertTriangle className="h-5 w-5 text-red-400" />
                </div>
                <div className="ml-3">
                  <p className="text-sm text-red-700">{error}</p>
                </div>
              </div>
            </div>
            <button
              onClick={() => navigate("/my-applications")}
              className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
            >
              <ArrowLeft className="w-5 h-5 mr-2" />
              Back to My Applications
            </button>
          </div>
          </MainLayout>
    );
  }

  if (!application) {
    return (
<MainLayout>
          <div className="max-w-4xl mx-auto">
            <div className="bg-yellow-50 border-l-4 border-yellow-500 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <AlertTriangle className="h-5 w-5 text-yellow-400" />
                </div>
                <div className="ml-3">
                  <p className="text-sm text-yellow-700">
                    Application not found
                  </p>
                </div>
              </div>
            </div>
            <button
              onClick={() => navigate("/my-applications")}
              className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
            >
              <ArrowLeft className="w-5 h-5 mr-2" />
              Back to My Applications
            </button>
          </div>
          </MainLayout>
    );
  }

  return (
<MainLayout>
        <div className="max-w-4xl mx-auto">
          {/* Header with application info */}
          <div className="bg-white shadow rounded-lg mb-6 p-6">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <button
                  onClick={() => navigate("/my-applications")}
                  className="mr-4 p-2 rounded-full hover:bg-gray-100"
                >
                  <ArrowLeft className="w-5 h-5" />
                </button>
                <div>
                  <h1 className="text-2xl font-bold text-gray-900">
                    Application #{applicationId} - Gate List Submission
                  </h1>
                  <div className="mt-1 flex items-center">
                    <p className="text-sm text-gray-500 mr-3">
                      {application.eventName}
                    </p>
                    <ApplicationStatusBadge status={application.status} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Gate list form or status message */}
          {needsGateList(application.status) ? (
            <GateListForm
              applicationId={applicationId}
              eventId={application.eventID}
              groupId={application.groupID}
              onComplete={handleGateListComplete}
            />
          ) : (
            <div className="bg-white shadow rounded-lg p-6">
              <h2 className="text-lg font-medium text-gray-900 mb-4">
                Gate List Status
              </h2>
              <div className="bg-blue-50 border-l-4 border-blue-400 p-4">
                <p className="text-blue-700">
                  Gate list submission is currently not needed for this
                  application. Current application status:{" "}
                  <strong>{application.status}</strong>
                </p>
              </div>
              <button
                onClick={() => navigate("/my-applications")}
                className="mt-4 inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50"
              >
                <ArrowLeft className="w-5 h-5 mr-2" />
                Back to My Applications
              </button>
            </div>
          )}
        </div>
        </MainLayout>
  );
};

export default ApplicationPage;
