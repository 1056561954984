import React, { useState, useEffect } from "react";
import api from "../utils/api";
import {
  Eye,
  MapPin,
  Globe,
  Phone,
  Mail,
  Search,
  ChevronLeft,
  ChevronRight,
  CheckCircle,
  XCircle,
  Loader,
} from "lucide-react";
import debounce from "lodash/debounce";
import MainLayout from "./MainLayout";

const Groups = ({ type }) => {
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedGroupDetails, setSelectedGroupDetails] = useState(null);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 0,
    totalItems: 0,
    limit: 20,
    offset: 0,
  });

  // Get the display name for the type
  const getTypeDisplayName = () => {
    switch (type) {
      case "guild":
        return "Guilds";
      case "act":
        return "Stage Acts";
      case "vendor":
        return "Vendors";
      default:
        return "Groups";
    }
  };

  // Debounced search function
  const debouncedSearch = debounce((term) => {
    setPagination((prev) => ({ ...prev, currentPage: 1, offset: 0 })); // Reset to first page on new search
    fetchGroups(0, term);
  }, 300);

  useEffect(() => {
    // Reset search and groups when type changes
    setSearchTerm("");
    setGroups([]);
    setSelectedGroup(null);
    setSelectedGroupDetails(null);
    setPagination({
      currentPage: 1,
      totalPages: 0,
      totalItems: 0,
      limit: 20,
      offset: 0,
    });
    fetchGroups(0, "");
  }, [type]);

  useEffect(() => {
    debouncedSearch(searchTerm);
  }, [searchTerm]);

  const fetchGroups = async (offset, search) => {
    try {
      setLoading(true);
      const response = await api.get(`/api/public/groups/${type}`, {
        params: {
          limit: pagination.limit,
          offset: offset,
          search: search,
        },
      });

      setGroups(response.data.items);
      setPagination(response.data.pagination);
      setLoading(false);
    } catch (err) {
      console.error(`Error fetching ${type}:`, err);
      setError(`Failed to load ${getTypeDisplayName()}`);
      setLoading(false);
    }
  };

  const fetchGroupDetails = async (groupId) => {
    try {
      setLoadingDetails(true);
      const response = await api.get(`/api/public/groups/detail/${groupId}`);
      setSelectedGroupDetails(response.data);
      setLoadingDetails(false);
    } catch (err) {
      console.error("Error fetching group details:", err);
      setLoadingDetails(false);
      // Keep the modal open but show error in it
      setSelectedGroupDetails({ error: "Failed to load details" });
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= pagination.totalPages) {
      const newOffset = (newPage - 1) * pagination.limit;
      setPagination((prev) => ({
        ...prev,
        currentPage: newPage,
        offset: newOffset,
      }));
      fetchGroups(newOffset, searchTerm);
    }
  };

  const handleGroupSelect = (group) => {
    setSelectedGroup(group);
    fetchGroupDetails(group.groupID);
  };

  const closeModal = () => {
    setSelectedGroup(null);
    setSelectedGroupDetails(null);
  };

  // Helper to format phone numbers
  const formatPhone = (phone) => {
    if (!phone) return "";
    // Remove non-numeric characters
    const cleaned = phone.replace(/\D/g, "");
    // Format as (XXX) XXX-XXXX if 10 digits
    if (cleaned.length === 10) {
      return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(
        6
      )}`;
    }
    return phone;
  };

  return (
    <MainLayout>
      <div className="max-w-6xl mx-auto">
        <div className="mb-8">
          <h1 className="text-3xl font-bold text-gray-900">
            {getTypeDisplayName()}
          </h1>
          <p className="mt-2 text-gray-600">
            Browse all {getTypeDisplayName().toLowerCase()} registered in our
            system.
          </p>
        </div>

        <div className="bg-white rounded-lg shadow-sm p-6">
          {/* Search Bar */}
          <div className="mb-6">
            <div className="relative">
              <Search
                className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                size={20}
              />
              <input
                type="text"
                className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder={`Search ${getTypeDisplayName()}...`}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>

          {loading ? (
            <div className="flex justify-center p-8">
              <Loader className="animate-spin h-8 w-8 text-blue-500" />
              <span className="ml-2 text-gray-600">
                Loading {getTypeDisplayName()}...
              </span>
            </div>
          ) : error ? (
            <div className="text-red-600 text-center p-4">{error}</div>
          ) : groups.length === 0 ? (
            <div className="text-center p-8">
              <p className="text-gray-500">
                {searchTerm
                  ? `No ${getTypeDisplayName().toLowerCase()} found matching "${searchTerm}"`
                  : `No ${getTypeDisplayName().toLowerCase()} available at this time`}
              </p>
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {groups.map((group) => (
                <div
                  key={group.groupID}
                  className="bg-gray-50 rounded-lg p-4 hover:bg-gray-100 transition duration-150 cursor-pointer"
                  onClick={() => handleGroupSelect(group)}
                >
                  <div className="flex justify-between items-start">
                    <h3 className="text-lg font-semibold text-gray-900 line-clamp-1">
                      {group.group_name}
                    </h3>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleGroupSelect(group);
                      }}
                      className="text-blue-600 hover:text-blue-800"
                    >
                      <Eye className="w-5 h-5" />
                    </button>
                  </div>

                  <div className="mt-2 text-sm text-gray-600 space-y-1">
                    {group.contact_name && (
                      <p className="line-clamp-1">
                        Contact: {group.contact_name}
                      </p>
                    )}

                    {group.email && (
                      <p className="flex items-center gap-2 line-clamp-1">
                        <Mail className="w-4 h-4 flex-shrink-0" />
                        {group.email}
                      </p>
                    )}

                    {group.group_website && (
                      <p className="flex items-center gap-2">
                        <Globe className="w-4 h-4 flex-shrink-0" />
                        <a
                          href={group.group_website}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-600 hover:underline line-clamp-1"
                          onClick={(e) => e.stopPropagation()}
                        >
                          Website
                        </a>
                      </p>
                    )}

                    {group.camp_req === "Yes" && (
                      <p className="flex items-center gap-2">
                        <MapPin className="w-4 h-4 flex-shrink-0" />
                        Space: {group.frontage}' × {group.depth}'
                      </p>
                    )}

                    <p className="line-clamp-2 mt-2 text-gray-500">
                      {group.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )}

          {/* Pagination */}
          {!loading && !error && groups.length > 0 && (
            <div className="mt-6 flex justify-between items-center border-t border-gray-200 pt-4">
              <div className="text-sm text-gray-700">
                Showing {pagination.offset + 1} to{" "}
                {Math.min(
                  pagination.offset + groups.length,
                  pagination.totalItems
                )}{" "}
                of {pagination.totalItems} results
              </div>

              <div className="flex space-x-2">
                <button
                  onClick={() => handlePageChange(pagination.currentPage - 1)}
                  disabled={pagination.currentPage === 1}
                  className={`px-3 py-1 rounded ${pagination.currentPage === 1
                    ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                    : "bg-white text-gray-700 hover:bg-gray-50 border"
                    }`}
                >
                  <ChevronLeft size={16} />
                </button>

                {/* Show page numbers */}
                {Array.from(
                  { length: Math.min(5, pagination.totalPages) },
                  (_, i) => {
                    // Determine which page numbers to show
                    let pageNumber;
                    if (pagination.totalPages <= 5) {
                      // If total pages is 5 or less, show all pages
                      pageNumber = i + 1;
                    } else if (pagination.currentPage <= 3) {
                      // If current page is near the start, show first 5 pages
                      pageNumber = i + 1;
                    } else if (
                      pagination.currentPage >=
                      pagination.totalPages - 2
                    ) {
                      // If current page is near the end, show last 5 pages
                      pageNumber = pagination.totalPages - 4 + i;
                    } else {
                      // Otherwise show 2 pages before and 2 pages after current page
                      pageNumber = pagination.currentPage - 2 + i;
                    }

                    return (
                      <button
                        key={pageNumber}
                        onClick={() => handlePageChange(pageNumber)}
                        className={`px-3 py-1 rounded ${pagination.currentPage === pageNumber
                          ? "bg-blue-600 text-white"
                          : "bg-white text-gray-700 hover:bg-gray-50 border"
                          }`}
                      >
                        {pageNumber}
                      </button>
                    );
                  }
                )}

                <button
                  onClick={() => handlePageChange(pagination.currentPage + 1)}
                  disabled={pagination.currentPage === pagination.totalPages}
                  className={`px-3 py-1 rounded ${pagination.currentPage === pagination.totalPages
                    ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                    : "bg-white text-gray-700 hover:bg-gray-50 border"
                    }`}
                >
                  <ChevronRight size={16} />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Detail Modal */}
      {selectedGroup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg max-w-4xl w-full p-6 max-h-[90vh] overflow-y-auto">
            <div className="flex justify-between items-start mb-4">
              <h2 className="text-2xl font-bold">
                {selectedGroup.group_name}
              </h2>
              <button
                onClick={closeModal}
                className="text-gray-500 hover:text-gray-700"
              >
                ×
              </button>
            </div>

            {loadingDetails ? (
              <div className="flex justify-center p-8">
                <Loader className="animate-spin h-8 w-8 text-blue-500" />
                <span className="ml-2 text-gray-600">Loading details...</span>
              </div>
            ) : selectedGroupDetails?.error ? (
              <div className="text-red-600 p-4 bg-red-50 rounded mb-4">
                {selectedGroupDetails.error}
              </div>
            ) : selectedGroupDetails ? (
              <div className="space-y-6">
                {/* Group photos if available */}
                {selectedGroupDetails.photos?.length > 0 && (
                  <div className="mb-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      {selectedGroupDetails.photos.map((photo) => (
                        <div
                          key={photo.PhotoID}
                          className="rounded overflow-hidden"
                        >
                          <img
                            src={`/images/groups/${selectedGroupDetails.groupID}/${photo.imageName}`}
                            alt={
                              photo.caption || selectedGroupDetails.group_name
                            }
                            className="w-full h-48 object-cover"
                          />
                          {photo.caption && (
                            <p className="text-sm text-gray-700 p-2 bg-gray-50">
                              {photo.caption}
                            </p>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {/* Group description */}
                <div>
                  <h3 className="text-lg font-semibold mb-2">About</h3>
                  <p className="text-gray-700 whitespace-pre-line">
                    {selectedGroupDetails.description}
                  </p>
                </div>

                {/* Activities (if available) */}
                {selectedGroupDetails.activities && (
                  <div>
                    <h3 className="text-lg font-semibold mb-2">Activities</h3>
                    <p className="text-gray-700 whitespace-pre-line">
                      {selectedGroupDetails.activities}
                    </p>
                  </div>
                )}

                {/* Contact info */}
                <div>
                  <h3 className="text-lg font-semibold mb-2">
                    Contact Information
                  </h3>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <p className="text-gray-700">
                        <span className="font-medium">Contact:</span>{" "}
                        {selectedGroupDetails.contact_name}
                      </p>
                      {selectedGroupDetails.email && (
                        <p className="flex items-center gap-2 text-gray-700">
                          <Mail className="w-4 h-4" />
                          <a
                            href={`mailto:${selectedGroupDetails.email}`}
                            className="text-blue-600 hover:underline"
                          >
                            {selectedGroupDetails.email}
                          </a>
                        </p>
                      )}
                      {selectedGroupDetails.contact_phone && (
                        <p className="flex items-center gap-2 text-gray-700">
                          <Phone className="w-4 h-4" />
                          <a
                            href={`tel:${selectedGroupDetails.contact_phone.replace(
                              /\D/g,
                              ""
                            )}`}
                            className="hover:underline"
                          >
                            {formatPhone(selectedGroupDetails.contact_phone)}
                          </a>
                        </p>
                      )}
                    </div>
                    <div>
                      {selectedGroupDetails.group_website && (
                        <p className="flex items-center gap-2 text-gray-700">
                          <Globe className="w-4 h-4" />
                          <a
                            href={selectedGroupDetails.group_website}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-600 hover:underline"
                          >
                            Website
                          </a>
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                {/* Space Requirements */}
                {selectedGroupDetails.camp_req === "Yes" && (
                  <div>
                    <h3 className="text-lg font-semibold mb-2">
                      Space Requirements
                    </h3>
                    <div className="bg-blue-50 p-4 rounded flex items-center gap-4">
                      <MapPin className="w-6 h-6 text-blue-600" />
                      <div>
                        <p className="text-gray-700">
                          <span className="font-medium">Frontage:</span>{" "}
                          {selectedGroupDetails.frontage} feet
                        </p>
                        <p className="text-gray-700">
                          <span className="font-medium">Depth:</span>{" "}
                          {selectedGroupDetails.depth} feet
                        </p>
                      </div>
                    </div>
                  </div>
                )}

                {/* Vendor-specific information */}
                {type === "vendor" && (
                  <div>
                    <h3 className="text-lg font-semibold mb-2">
                      Vendor Requirements
                    </h3>
                    <div className="grid grid-cols-2 gap-4">
                      <div className="flex items-center gap-2">
                        <span className="font-medium">Needs Water:</span>
                        {selectedGroupDetails.v_needWater ? (
                          <CheckCircle className="w-5 h-5 text-green-500" />
                        ) : (
                          <XCircle className="w-5 h-5 text-red-500" />
                        )}
                      </div>
                      <div className="flex items-center gap-2">
                        <span className="font-medium">Needs Power:</span>
                        {selectedGroupDetails.v_needPower ? (
                          <CheckCircle className="w-5 h-5 text-green-500" />
                        ) : (
                          <XCircle className="w-5 h-5 text-red-500" />
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {/* Stage act specific information */}
                {type === "act" &&
                  selectedGroupDetails.stage_req === "Yes" && (
                    <div>
                      <h3 className="text-lg font-semibold mb-2">
                        Performance Information
                      </h3>
                      <div className="bg-purple-50 p-4 rounded">
                        <p className="text-gray-700">
                          <span className="font-medium">Performers:</span>{" "}
                          {selectedGroupDetails.howmany || "Not specified"}
                        </p>
                        {selectedGroupDetails.stage_time && (
                          <p className="text-gray-700">
                            <span className="font-medium">
                              Requested stage time:
                            </span>{" "}
                            {selectedGroupDetails.stage_time} minutes
                          </p>
                        )}
                        {selectedGroupDetails.stage_notes && (
                          <p className="text-gray-700">
                            <span className="font-medium">Notes:</span>{" "}
                            {selectedGroupDetails.stage_notes}
                          </p>
                        )}
                      </div>
                    </div>
                  )}

                {/* Footer with close button */}
                <div className="mt-8 pt-4 border-t border-gray-200 flex justify-end">
                  <button
                    onClick={closeModal}
                    className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors"
                  >
                    Close
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      )}
    </MainLayout>
  );
};

export default Groups;
