import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { format } from "date-fns";
import MainLayout from "../components/MainLayout";
import api from "../utils/api";
import { useGroup } from "../contexts/GroupContext";
import {
  Calendar,
  Clock,
  MapPin,
  Globe,
  Mail,
  Phone,
  User,
  ArrowLeft,
  FileText,
  CheckCircle,
  XCircle,
  AlertTriangle,
} from "lucide-react";

const EventView = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState("details");
  const { activeGroup } = useGroup();

  // Load event data
  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const response = await api.get(`/api/events/${eventId}`);
        setEvent(response.data);
        setError(null);
      } catch (err) {
        console.error("Error fetching event:", err);
        setError("Failed to load event data");
      } finally {
        setLoading(false);
      }
    };

    fetchEvent();
  }, [eventId]);

  // Check if the current group can apply for the event
  const canApplyForEvent = () => {
    if (!activeGroup || !event) return false;

    const today = new Date();
    const cutoffDate = new Date(
      activeGroup.class === "vendor" ? event.vendor_cutoff : event.app_cutoff
    );

    // Check if event is in the future and cutoff date hasn't passed
    const eventDate = new Date(event.event_startdate);

    // Handle different application types based on group class
    if (activeGroup.class === "vendor" && event.vendorAppType === "closed") {
      return false;
    }
    if (activeGroup.class === "guild" && event.appType === "closed") {
      return false;
    }
    if (activeGroup.class === "act" && event.actAppType === "closed") {
      return false;
    }

    return eventDate > today && today <= cutoffDate;
  };

  const handleApplyForEvent = () => {
    if (!activeGroup) {
      alert("Please select a group before applying for an event");
      return;
    }
    navigate(`/applications/create/${eventId}`);
  };

  // Format date function
  const formatDate = (dateString) => {
    try {
      return format(new Date(dateString), "MMMM d, yyyy");
    } catch (e) {
      return "Invalid date";
    }
  };

  // Format time function
  const formatTime = (dateString) => {
    try {
      return format(new Date(dateString), "h:mm a");
    } catch (e) {
      return "";
    }
  };

  if (loading) {
    return (
      <main className="flex-1 p-4 sm:p-6 md:p-8">
        <div className="max-w-6xl mx-auto">
          <div className="max-w-4xl mx-auto flex justify-center items-center h-full">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
          </div>
        </div>
        {/* Footer */}
        <footer className="mt-8 text-center text-gray-500 text-sm">
          Copyright by Renaissance Productions 2006-{new Date().getFullYear()}
        </footer>
      </main>
    );
  }

  if (error) {
    return (
      <main className="flex-1 p-4 sm:p-6 md:p-8">
        <div className="max-w-6xl mx-auto">
          <div className="bg-red-50 border-l-4 border-red-500 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <XCircle className="h-5 w-5 text-red-400" />
              </div>
              <div className="ml-3">
                <p className="text-sm text-red-700">{error}</p>
              </div>
            </div>
          </div>
          <button
            onClick={() => navigate("/events")}
            className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
          >
            <ArrowLeft className="w-5 h-5 mr-2" />
            Back to Events
          </button>
        </div>
        {/* Footer */}
        <footer className="mt-8 text-center text-gray-500 text-sm">
          Copyright by Renaissance Productions 2006-{new Date().getFullYear()}
        </footer>
      </main>
    );
  }

  if (!event) {
    return (
      <main className="flex-1 p-4 sm:p-6 md:p-8">
        <div className="max-w-6xl mx-auto">
          <div className="bg-yellow-50 border-l-4 border-yellow-500 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <AlertTriangle className="h-5 w-5 text-yellow-400" />
              </div>
              <div className="ml-3">
                <p className="text-sm text-yellow-700">Event not found</p>
              </div>
            </div>
          </div>
          <button
            onClick={() => navigate("/events")}
            className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
          >
            <ArrowLeft className="w-5 h-5 mr-2" />
            Back to Events
          </button>
        </div>
        {/* Footer */}
        <footer className="mt-8 text-center text-gray-500 text-sm">
          Copyright by Renaissance Productions 2006-{new Date().getFullYear()}
        </footer>
      </main>
    );
  }

  return (
    <main className="flex-1 p-4 sm:p-6 md:p-8">
      <div className="max-w-6xl mx-auto">
        {/* Header */}
        <div className="bg-white shadow rounded-lg mb-6">
          <div className="px-6 py-5">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <button
                  onClick={() => navigate("/events")}
                  className="mr-4 p-2 rounded-full hover:bg-gray-100"
                >
                  <ArrowLeft className="w-5 h-5" />
                </button>
                <div>
                  <h1 className="text-2xl font-bold text-gray-900">
                    {event.eventName}
                  </h1>
                  <p className="text-sm text-gray-500">
                    {formatDate(event.event_startdate)}
                  </p>
                </div>
              </div>

              {/* Application Button */}
              {activeGroup && canApplyForEvent() && (
                <button
                  onClick={handleApplyForEvent}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700"
                >
                  <FileText className="w-4 h-4 mr-2" />
                  Apply for Event
                </button>
              )}
            </div>

            {/* Status Badge */}
            <div className="mt-2 flex items-center space-x-2">
              <span
                className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${event.isCancelled === 1
                    ? "bg-red-100 text-red-800"
                    : "bg-green-100 text-green-800"
                  }`}
              >
                {event.isCancelled === 1 ? "Cancelled" : "Active"}
              </span>

              {activeGroup && (
                <span
                  className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${canApplyForEvent()
                      ? "bg-blue-100 text-blue-800"
                      : "bg-gray-100 text-gray-800"
                    }`}
                >
                  Applications: {canApplyForEvent() ? "Open" : "Closed"}
                </span>
              )}
            </div>
          </div>

          {/* Tabs */}
          <div className="border-t border-gray-200">
            <nav className="flex -mb-px">
              <button
                onClick={() => setActiveTab("details")}
                className={`${activeTab === "details"
                    ? "border-blue-500 text-blue-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                  } whitespace-nowrap py-4 px-6 border-b-2 font-medium text-sm`}
              >
                Details
              </button>
              <button
                onClick={() => setActiveTab("rules")}
                className={`${activeTab === "rules"
                    ? "border-blue-500 text-blue-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                  } whitespace-nowrap py-4 px-6 border-b-2 font-medium text-sm`}
              >
                Rules
              </button>
              <button
                onClick={() => setActiveTab("vendor-info")}
                className={`${activeTab === "vendor-info"
                    ? "border-blue-500 text-blue-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                  } whitespace-nowrap py-4 px-6 border-b-2 font-medium text-sm`}
              >
                Vendor Info
              </button>
              {activeGroup && (
                <button
                  onClick={() => setActiveTab("apply")}
                  className={`${activeTab === "apply"
                      ? "border-blue-500 text-blue-600"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                    } whitespace-nowrap py-4 px-6 border-b-2 font-medium text-sm`}
                >
                  Apply
                </button>
              )}
            </nav>
          </div>
        </div>

        {/* Content based on active tab */}
        {activeTab === "details" && (
          <div className="bg-white shadow rounded-lg overflow-hidden">
            <div className="px-6 py-5 border-b border-gray-200">
              <h2 className="text-lg font-medium text-gray-900">
                Event Details
              </h2>
            </div>

            <div className="px-6 py-5">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {/* Event Type & Location */}
                <div>
                  <h3 className="text-sm font-medium text-gray-500">
                    Event Type
                  </h3>
                  <p className="mt-1 text-sm text-gray-900">
                    {event.eType === "Other" ? event.eTypeOther : event.eType}
                  </p>
                </div>

                <div>
                  <h3 className="text-sm font-medium text-gray-500">
                    Location
                  </h3>
                  <div className="mt-1 flex items-center text-sm text-gray-900">
                    <MapPin className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" />
                    {event.eventLocation || "Not specified"}
                    {event.zip && ` - ${event.zip}`}
                  </div>
                </div>

                {/* Dates */}
                <div>
                  <h3 className="text-sm font-medium text-gray-500">
                    Event Date
                  </h3>
                  <div className="mt-1 flex items-center text-sm text-gray-900">
                    <Calendar className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" />
                    {event.event_dates || formatDate(event.event_startdate)}
                  </div>
                </div>

                <div>
                  <h3 className="text-sm font-medium text-gray-500">
                    Start Time
                  </h3>
                  <div className="mt-1 flex items-center text-sm text-gray-900">
                    <Clock className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" />
                    {formatTime(event.event_startdate) || "Not specified"}
                  </div>
                </div>

                {/* Application Dates */}
                {activeGroup && (
                  <>
                    <div>
                      <h3 className="text-sm font-medium text-gray-500">
                        {activeGroup.class === "vendor"
                          ? "Vendor"
                          : "Guild/Act"}{" "}
                        Application Deadline
                      </h3>
                      <div className="mt-1 flex items-center text-sm text-gray-900">
                        <Calendar className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" />
                        {formatDate(
                          activeGroup.class === "vendor"
                            ? event.vendor_cutoff
                            : event.app_cutoff
                        )}
                      </div>
                    </div>

                    <div>
                      <h3 className="text-sm font-medium text-gray-500">
                        Application Status
                      </h3>
                      <div className="mt-1 flex items-center text-sm">
                        {canApplyForEvent() ? (
                          <>
                            <CheckCircle className="flex-shrink-0 mr-1.5 h-4 w-4 text-green-500" />
                            <span className="text-green-700">
                              Open for applications
                            </span>
                          </>
                        ) : (
                          <>
                            <XCircle className="flex-shrink-0 mr-1.5 h-4 w-4 text-red-500" />
                            <span className="text-red-700">
                              Closed for applications
                            </span>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}

                {/* Online Presence */}
                <div>
                  <h3 className="text-sm font-medium text-gray-500">Website</h3>
                  <div className="mt-1 flex items-center text-sm text-gray-900">
                    <Globe className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" />
                    {event.url ? (
                      <a
                        href={event.url}
                        target="_blank"
                        rel="noreferrer"
                        className="text-blue-600 hover:underline"
                      >
                        {event.url}
                      </a>
                    ) : (
                      "Not specified"
                    )}
                  </div>
                </div>

                {event.myspace && (
                  <div>
                    <h3 className="text-sm font-medium text-gray-500">
                      Social Media
                    </h3>
                    <div className="mt-1 flex items-center text-sm text-gray-900">
                      <Globe className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" />
                      <a
                        href={event.myspace}
                        target="_blank"
                        rel="noreferrer"
                        className="text-blue-600 hover:underline"
                      >
                        {event.myspace}
                      </a>
                    </div>
                  </div>
                )}
              </div>

              {/* Contact Information */}
              <div className="mt-8">
                <h3 className="text-base font-medium text-gray-900">
                  Contact Information
                </h3>
                <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <h4 className="text-sm font-medium text-gray-500">
                      Contact Name
                    </h4>
                    <div className="mt-1 flex items-center text-sm text-gray-900">
                      <User className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" />
                      {event.pri_contact || "Not specified"}
                    </div>
                  </div>

                  <div>
                    <h4 className="text-sm font-medium text-gray-500">Email</h4>
                    <div className="mt-1 flex items-center text-sm text-gray-900">
                      <Mail className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" />
                      {event.pri_email ? (
                        <a
                          href={`mailto:${event.pri_email}`}
                          className="text-blue-600 hover:underline"
                        >
                          {event.pri_email}
                        </a>
                      ) : (
                        "Not specified"
                      )}
                    </div>
                  </div>

                  {event.pri_phone && (
                    <div>
                      <h4 className="text-sm font-medium text-gray-500">
                        Phone
                      </h4>
                      <div className="mt-1 flex items-center text-sm text-gray-900">
                        <Phone className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400" />
                        <a
                          href={`tel:${event.pri_phone.replace(/\D/g, "")}`}
                          className="text-blue-600 hover:underline"
                        >
                          {event.pri_phone}
                        </a>
                      </div>
                    </div>
                  )}
                </div>

                {event.pri_address && (
                  <div className="mt-4">
                    <h4 className="text-sm font-medium text-gray-500">
                      Address
                    </h4>
                    <div className="mt-1 text-sm text-gray-900">
                      <pre className="whitespace-pre-wrap font-sans">
                        {event.pri_address}
                      </pre>
                    </div>
                  </div>
                )}
              </div>

              {/* Description */}
              {event.notes && (
                <div className="mt-8">
                  <h3 className="text-base font-medium text-gray-900">
                    Event Description
                  </h3>
                  <div className="mt-2 prose prose-blue max-w-none">
                    <div dangerouslySetInnerHTML={{ __html: event.notes }} />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {activeTab === "rules" && (
          <div className="bg-white shadow rounded-lg overflow-hidden">
            <div className="px-6 py-5 border-b border-gray-200">
              <h2 className="text-lg font-medium text-gray-900">
                Rules and Guidelines
              </h2>
            </div>

            <div className="px-6 py-5">
              {event.rules ? (
                <div className="prose prose-blue max-w-none">
                  <div dangerouslySetInnerHTML={{ __html: event.rules }} />
                </div>
              ) : (
                <p className="text-gray-500 italic">
                  No rules or guidelines specified.
                </p>
              )}
            </div>
          </div>
        )}

        {activeTab === "vendor-info" && (
          <div className="bg-white shadow rounded-lg overflow-hidden">
            <div className="px-6 py-5 border-b border-gray-200">
              <h2 className="text-lg font-medium text-gray-900">
                Vendor Information
              </h2>
            </div>

            <div className="px-6 py-5">
              {event.v_notes ? (
                <div className="prose prose-blue max-w-none">
                  <div dangerouslySetInnerHTML={{ __html: event.v_notes }} />
                </div>
              ) : (
                <p className="text-gray-500 italic">
                  No vendor information specified.
                </p>
              )}
            </div>
          </div>
        )}

        {activeTab === "apply" && (
          <div className="bg-white shadow rounded-lg overflow-hidden">
            <div className="px-6 py-5 border-b border-gray-200">
              <h2 className="text-lg font-medium text-gray-900">
                Apply for this Event
              </h2>
            </div>

            <div className="px-6 py-5">
              {!activeGroup ? (
                <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <AlertTriangle className="h-5 w-5 text-yellow-400" />
                    </div>
                    <div className="ml-3">
                      <p className="text-sm text-yellow-700">
                        Please select a group before applying to this event.
                      </p>
                    </div>
                  </div>
                </div>
              ) : !canApplyForEvent() ? (
                <div className="bg-red-50 border-l-4 border-red-400 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <XCircle className="h-5 w-5 text-red-400" />
                    </div>
                    <div className="ml-3">
                      <p className="text-sm text-red-700">
                        Applications are currently closed for this event. The
                        application deadline has passed or the event is not
                        accepting applications for {activeGroup.class}s.
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="mb-6">
                    <div className="bg-blue-50 border-l-4 border-blue-400 p-4">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <CheckCircle className="h-5 w-5 text-blue-400" />
                        </div>
                        <div className="ml-3">
                          <p className="text-sm text-blue-700">
                            You are eligible to apply for this event as{" "}
                            <strong>{activeGroup.group_name}</strong> (
                            {activeGroup.class}).
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="space-y-4">
                    <p className="text-gray-700">
                      Ready to apply for this event? Click the button below to
                      start your application.
                    </p>

                    <div className="flex items-center text-sm text-gray-600">
                      <Calendar className="flex-shrink-0 mr-1.5 h-4 w-4" />
                      <span>
                        Application deadline:{" "}
                        {formatDate(
                          activeGroup.class === "vendor"
                            ? event.vendor_cutoff
                            : event.app_cutoff
                        )}
                      </span>
                    </div>

                    <button
                      onClick={handleApplyForEvent}
                      className="mt-4 w-full sm:w-auto flex justify-center items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      <FileText className="mr-2 h-5 w-5" />
                      Start Application
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {/* Footer */}
      <footer className="mt-8 text-center text-gray-500 text-sm">
        Copyright by Renaissance Productions 2006-{new Date().getFullYear()}
      </footer>
    </main>
  );
};

export default EventView;
