import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MainLayout from "../MainLayout";
import api from "../../utils/api";
import { useAuth } from "../../contexts/AuthContext";
import { format } from "date-fns";
import {
  Calendar,
  Edit,
  Trash,
  Eye,
  Filter,
  ChevronDown,
  Copy,
} from "lucide-react";
import { useGroup } from "../../contexts/GroupContext";

const MyEvents = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showPastEvents, setShowPastEvents] = useState(false);
  const { user } = useAuth();
  const { activeGroup } = useGroup();
  const navigate = useNavigate();

  // Fetch events for the active group
  useEffect(() => {
    if (!activeGroup) return;

    const fetchEvents = async () => {
      setLoading(true);
      try {
        const response = await api.get(
          `/api/events/group/${activeGroup.groupID}`
        );
        setEvents(response.data);
        setError(null);
      } catch (err) {
        console.error("Error fetching events:", err);
        setError("Failed to load events");
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, [activeGroup, showPastEvents]);

  const handleCreateEvent = () => {
    navigate("/my-events/create");
  };

  const handleEditEvent = (eventId) => {
    navigate(`/my-events/edit/${eventId}`);
  };

  const handleViewEvent = (eventId) => {
    navigate(`/my-events/view/${eventId}`);
  };

  const handleDuplicateEvent = (eventId) => {
    if (window.confirm("Do you want to create a duplicate of this event?")) {
      navigate(`/my-events/duplicate/${eventId}`);
    }
  };

  const handleDeleteEvent = async (eventId) => {
    if (!window.confirm("Are you sure you want to delete this event?")) {
      return;
    }

    try {
      await api.delete(`/api/events/${eventId}`);
      setEvents(events.filter((event) => event.eventID !== eventId));
    } catch (err) {
      console.error("Error deleting event:", err);
      setError("Failed to delete event");
    }
  };

  // Filter events based on the showPastEvents toggle
  const filteredEvents = events.filter((event) => {
    const eventDate = new Date(event.event_startdate);
    const today = new Date();
    return showPastEvents ? eventDate < today : eventDate >= today;
  });

  // Format date function
  const formatDate = (dateString) => {
    return format(new Date(dateString), "MMM d, yyyy");
  };

  return (
    <main className="flex-1 p-4 sm:p-6 md:p-8">
      <div className="max-w-6xl mx-auto">
        <div className="flex justify-between items-center mb-6">
          <div className="flex items-center">
            <h1 className="text-2xl font-bold text-gray-900 mr-4">My Events</h1>

            {/* Group Selector */}
            {activeGroup && (
              <div className="px-3 py-2 bg-gray-100 border rounded-lg text-sm">
                <span className="text-gray-600 mr-2">Acting as:</span>
                <span className="font-medium">{activeGroup.group_name}</span>
              </div>
            )}
          </div>

          <div className="flex space-x-4">
            <button
              onClick={() => setShowPastEvents(!showPastEvents)}
              className="flex items-center px-4 py-2 bg-white border rounded-lg shadow-sm hover:bg-gray-50"
            >
              <Filter className="w-4 h-4 mr-2" />
              {showPastEvents ? "Show Upcoming Events" : "Show Past Events"}
            </button>

            <button
              onClick={handleCreateEvent}
              className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
            >
              <Calendar className="w-5 h-5 mr-2" />
              Create Event
            </button>
          </div>
        </div>

        {loading ? (
          <div className="flex justify-center p-8">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
          </div>
        ) : error ? (
          <div className="bg-red-50 text-red-600 p-4 rounded-lg">{error}</div>
        ) : filteredEvents.length === 0 ? (
          <div className="bg-white rounded-lg p-8 text-center">
            <h3 className="text-lg font-medium text-gray-900 mb-2">
              {showPastEvents ? "No Past Events" : "No Upcoming Events"}
            </h3>
            <p className="text-gray-600 mb-4">
              {showPastEvents
                ? "You have no past events to display."
                : "You have no upcoming events scheduled. Create your first event to get started."}
            </p>
            {!showPastEvents && (
              <button
                onClick={handleCreateEvent}
                className="inline-flex items-center px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
              >
                <Calendar className="w-5 h-5 mr-2" />
                Create Event
              </button>
            )}
          </div>
        ) : (
          <div className="overflow-x-auto">
            <table className="w-full bg-white rounded-lg shadow-sm">
              <thead>
                <tr className="bg-gray-50 border-b">
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Event Name
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Date
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Location
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {filteredEvents.map((event) => (
                  <tr key={event.eventID} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {event.eventName || event.event}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">
                        {formatDate(event.event_startdate)}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">
                        {event.eventLocation || "N/A"}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span
                        className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          event.isCancelled === 1
                            ? "bg-red-100 text-red-800"
                            : "bg-green-100 text-green-800"
                        }`}
                      >
                        {event.isCancelled === 1 ? "Cancelled" : "Active"}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <div className="flex justify-end space-x-2">
                        <button
                          onClick={() => handleViewEvent(event.eventID)}
                          className="text-blue-600 hover:text-blue-900"
                          title="View Details"
                        >
                          <Eye className="w-5 h-5" />
                        </button>
                        <button
                          onClick={() => handleDuplicateEvent(event.eventID)}
                          className="text-green-600 hover:text-green-900"
                          title="Duplicate Event"
                        >
                          <Copy className="w-5 h-5" />
                        </button>
                        <button
                          onClick={() => handleEditEvent(event.eventID)}
                          className="text-indigo-600 hover:text-indigo-900"
                          title="Edit Event"
                        >
                          <Edit className="w-5 h-5" />
                        </button>
                        <button
                          onClick={() => handleDeleteEvent(event.eventID)}
                          className="text-red-600 hover:text-red-900"
                          title="Delete Event"
                        >
                          <Trash className="w-5 h-5" />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      {/* Footer */}
      <footer className="mt-8 text-center text-gray-500 text-sm">
        Copyright by Renaissance Productions 2006-{new Date().getFullYear()}
      </footer>
    </main>
  );
};

export default MyEvents;
