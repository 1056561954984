// src/components/LoadingSkeleton.js
import React from "react";

// Generic skeleton loader component
export const Skeleton = ({ className = "", ...props }) => (
  <div
    className={`animate-pulse bg-gray-200 rounded ${className}`}
    {...props}
  />
);

// Application card skeleton
export const ApplicationCardSkeleton = () => (
  <div className="bg-white rounded-lg shadow-sm p-4 animate-pulse">
    <div className="flex justify-between items-start mb-3">
      <div>
        <div className="h-5 bg-gray-200 rounded w-48 mb-2"></div>
        <div className="h-3 bg-gray-200 rounded w-32"></div>
      </div>
      <div className="h-5 w-20 bg-gray-200 rounded-full"></div>
    </div>

    <div className="grid grid-cols-2 gap-2 mb-3">
      <div>
        <div className="h-3 bg-gray-200 rounded w-16 mb-1"></div>
        <div className="h-4 bg-gray-200 rounded w-32"></div>
      </div>
      <div>
        <div className="h-3 bg-gray-200 rounded w-14 mb-1"></div>
        <div className="h-4 bg-gray-200 rounded w-28"></div>
      </div>
    </div>

    <div className="h-8 bg-gray-200 rounded-md w-full mt-3"></div>
  </div>
);

// Table skeleton
export const TableSkeleton = ({ rows = 3 }) => (
  <div className="bg-white rounded-lg shadow overflow-hidden animate-pulse">
    <div className="border-b border-gray-200">
      <div className="px-6 py-3 bg-gray-50 flex space-x-4">
        <div className="h-4 bg-gray-200 rounded w-1/4"></div>
        <div className="h-4 bg-gray-200 rounded w-1/5"></div>
        <div className="h-4 bg-gray-200 rounded w-1/6"></div>
        <div className="h-4 bg-gray-200 rounded w-1/6"></div>
      </div>
    </div>
    <div>
      {[...Array(rows)].map((_, i) => (
        <div
          key={i}
          className="px-6 py-4 border-b border-gray-200 flex items-center space-x-4"
        >
          <div className="h-4 bg-gray-200 rounded w-1/4"></div>
          <div className="h-4 bg-gray-200 rounded w-1/5"></div>
          <div className="h-4 bg-gray-200 rounded w-1/6"></div>
          <div className="h-4 bg-gray-200 rounded w-1/6"></div>
        </div>
      ))}
    </div>
  </div>
);

// Application detail skeleton
export const ApplicationDetailSkeleton = () => (
  <div className="space-y-6 animate-pulse">
    {/* Header */}
    <div className="bg-white shadow rounded-lg">
      <div className="px-6 py-5">
        <div className="flex justify-between">
          <div className="flex items-center">
            <div className="mr-4 h-8 w-8 bg-gray-200 rounded-full"></div>
            <div>
              <div className="h-6 bg-gray-200 rounded w-48 mb-2"></div>
              <div className="h-4 bg-gray-200 rounded w-32"></div>
            </div>
          </div>
          <div className="flex space-x-2">
            <div className="h-9 bg-gray-200 rounded w-24"></div>
            <div className="h-9 bg-gray-200 rounded w-16"></div>
          </div>
        </div>
      </div>

      <div className="border-t border-gray-200">
        <div className="flex px-6 py-4 space-x-6">
          <div className="h-4 bg-gray-200 rounded w-20"></div>
          <div className="h-4 bg-gray-200 rounded w-24"></div>
          <div className="h-4 bg-gray-200 rounded w-28"></div>
        </div>
      </div>
    </div>

    {/* Content panels */}
    <div className="bg-white shadow rounded-lg">
      <div className="px-6 py-5 border-b border-gray-200">
        <div className="h-5 bg-gray-200 rounded w-32"></div>
      </div>
      <div className="px-6 py-5">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {[...Array(4)].map((_, i) => (
            <div key={i}>
              <div className="h-3 bg-gray-200 rounded w-20 mb-2"></div>
              <div className="h-4 bg-gray-200 rounded w-full"></div>
            </div>
          ))}
        </div>
      </div>
    </div>

    {/* Another panel */}
    <div className="bg-white shadow rounded-lg">
      <div className="px-6 py-5 border-b border-gray-200">
        <div className="h-5 bg-gray-200 rounded w-40"></div>
      </div>
      <div className="px-6 py-5">
        <div className="space-y-4">
          <div className="h-4 bg-gray-200 rounded w-full"></div>
          <div className="h-4 bg-gray-200 rounded w-full"></div>
          <div className="h-4 bg-gray-200 rounded w-2/3"></div>
        </div>
      </div>
    </div>
  </div>
);

// Default export for a generic loading state
const LoadingSkeleton = ({ type = "default", count = 3 }) => {
  switch (type) {
    case "application-card":
      return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {[...Array(count)].map((_, i) => (
            <ApplicationCardSkeleton key={i} />
          ))}
        </div>
      );
    case "table":
      return <TableSkeleton rows={count} />;
    case "application-detail":
      return <ApplicationDetailSkeleton />;
    default:
      return (
        <div className="flex justify-center items-center h-full py-12">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
        </div>
      );
  }
};

export default LoadingSkeleton;
